import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Divider,
  CircularProgress,
} from "@mui/material";
import CommonHeader from "../../CommonComponent/CommonHeader";
import { useNavigate, useParams } from "react-router-dom";
import CommonTabs from "../../CommonComponent/CommonTabs";
import VisitorAction from "./VisitorAction";
import { fetchConsentData } from "../../api/consentLog";
import CustomBreadcrumb from "../../CommonComponent/CommonBreadcrumb";
import GrantedConsent from "./GrantedConsent";
import Loader from "../../CommonComponent/Loader";
import dayjs from "dayjs";

const ConsentDetails = () => {
  const { id } = useParams();
  const [value, setValue] = useState("1");
  const [loading, setLoading] = useState(false);
  const [consentDetails, setConsentDetails] = useState(null);
  const navigate = useNavigate();

  const tabs = [
    {
      label: "Visitor Action",
      value: "1",
      content: <VisitorAction consentDetails={consentDetails} />,
    },
    {
      label: "Granted Consent",
      value: "2",
      content: (
        <GrantedConsent
          acceptedServices={consentDetails?.user_preferences?.acceptedServices}
        />
      ),
    },
  ];

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const getConsentDetails = async () => {
    setLoading(true);
    try {
      const res = await fetchConsentData(id);
      setConsentDetails(res?.data || {});
    } catch (err) {
      console.error("Error fetching consent logs:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getConsentDetails();
    // eslint-disable-next-line
  }, [id]);

  if (loading) {
    return <Loader />;
  }

  if (!consentDetails) {
    return (
      <CircularProgress
        size={20}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      />
    );
  }

  const consentInfo = {
    consentId: consentDetails.consent_hashid || "Not Available",
    ipAddress: consentDetails.ip_address || "Not Available",
    ipCountry: consentDetails.ip_country || "Not Available",
    ipRegion: consentDetails.ip_region || "Not Available",
    userId: consentDetails.account_hashid
      ? consentDetails.account_hashid
      : "---",
    acceptType: consentDetails.user_preferences?.acceptType || "Not Available",
    gpcPreference: consentDetails.gcp_preference || "Not Available",
    acceptedCategories:
      consentDetails.user_preferences?.acceptedCategories.join(", ") ||
      "Not Available",
    rejectedCategories:
      consentDetails.user_preferences?.rejectedCategories.join(", ") ||
      "Not Available",
    acceptedAt: consentDetails.accepted_at
      ? dayjs(consentDetails.accepted_at).format("YYYY-MM-DD HH:mm")
      : "Not Available",
  };

  const breadcrumbItems = [
    {
      text: "Consent Log",
      href: "/consent-log",
    },
    {
      text: "Consent Details",
      href: "/vendor-management/active-services",
    },
  ];

  return (
    <Box>
      <Box sx={{ display: "flex", mb: 3 }}>
        <CommonHeader
          title="Consent Details"
          subTitle="You can review and audit the consent log and consent details on this page."
        />
      </Box>

      <Box sx={{ mb: 4 }}>
        <CustomBreadcrumb
          items={breadcrumbItems}
          onBackClick={() => navigate("/consent-log")}
        />
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 3, backgroundColor: "#F9FAFB", height: "100%" }}>
            <Typography
              variant="h6"
              gutterBottom
              color="#525455"
              fontWeight="bold"
            >
              Consent Information
            </Typography>
            <Divider />
            {Object.entries(consentInfo).map(([key, value], index, array) => (
              <Box key={key} sx={{ mb: 1, mt: 2 }}>
                <Typography
                  variant="body"
                  color="text.secondary"
                  sx={{ textTransform: "capitalize", display: "block" }}
                >
                  {key.replace(/([A-Z])/g, " $1").toLowerCase()}
                </Typography>
                <Typography variant="body2">{value}</Typography>
                {!(index + 1 === array.length) && <Divider sx={{ mt: 2 }} />}
              </Box>
            ))}
          </Paper>
        </Grid>

        <Grid item xs={12} md={8}>
          <CommonTabs value={value} handleChange={handleChange} tabs={tabs} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConsentDetails;
