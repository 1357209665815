import React, { useEffect } from "react";
import { Box, TextField, Typography, Paper } from "@mui/material";
import CommonSubHeader from "../../CommonComponent/CommonSubHeader";
import { getWebsiteById } from "../../api/websiteService";
import Loader from "../../CommonComponent/Loader";

const GeneralSettings = () => {
  const [websiteDetails, setWebsiteDetails] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const fetchWebsiteById = async () => {
    try {
      setLoading(true);
      const websiteId = localStorage.getItem("website_id");
      const data = await getWebsiteById(websiteId);
      if (data?.status_code === 200) {
        setLoading(false);
        setWebsiteDetails(data?.data);
      }
    } catch (error) {
      console.error("Error fetching websites:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWebsiteById();
  }, []);

  return (
    <Box>
      <CommonSubHeader
        mediumTitle="Domain Settings"
        subTitle="Configure your website settings and specify where Data Privacy Cloud
        should operate."
      />

      {loading ? (
        <Loader />
      ) : (
        <Paper
          elevation={1}
          sx={{ p: 3, mb: 4, background: "#F9FAFB", borderRadius: "8px" }}
          variant="outlined"
        >
          {/* Account ID */}
          <Box sx={{ mb: 3 }}>
            <Typography
              variant="body2"
              color="#525455"
              sx={{ fontWeight: "bold", mb: 1 }}
            >
              Website HashID
            </Typography>
            <TextField
              fullWidth
              margin="none"
              size="small"
              value={websiteDetails?.account_hashid}
              variant="outlined"
              sx={{ mb: 1 }}
              disabled
            />
            <Typography variant="caption" color="text.secondary">
              Your account's unique identifier which is used in your widget
              script and across various features to recognise and manage your
              account.
            </Typography>
          </Box>

          <Box sx={{ mb: 3 }}>
            <Typography
              variant="body2"
              color="#525455"
              sx={{ fontWeight: "bold", mb: 1 }}
            >
              Website Domain
            </Typography>
            <TextField
              fullWidth
              size="small"
              margin="none"
              value={websiteDetails?.domain_name}
              variant="outlined"
              sx={{ mb: 1 }}
              disabled
            />
            <Typography variant="caption" color="text.secondary">
              This will be the name of your account and will be displayed in the
              Data Privacy Cloud dashboard.
            </Typography>
          </Box>

          {/* <Box sx={{ mb: 3 }}>
          <Typography
            variant="body2"
            color="#525455"
            sx={{ fontWeight: "bold", mb: 1 }}
          >
            Additional Domains
          </Typography>
          <TextField
            fullWidth
            margin="none"
            size="small"
            defaultValue="prakash.ai"
            variant="outlined"
            sx={{ mb: 1 }}
          />
          <Typography variant="caption" color="text.secondary">
            Additional domains that are allowed, separated by commas. All
            subdomain of the domain will be automatically allowed.
          </Typography>
        </Box> */}
        </Paper>
      )}

      {/* Billing Section */}
      {/* <Box sx={{ mb: 4 }}>
        <Box display="flex" justifyContent="space-between">
          <CommonSubHeader
            mediumTitle="Billing"
            subTitle="Receive unlimited credits when you pay yearly, and save on your plan."
          />

          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 3 }}>
            <ToggleButtonGroup
              size="small"
              value={billingCycle}
              exclusive
              onChange={handleBillingChange}
              sx={{ bgcolor: "#f5f5f5", borderRadius: "8px" }}
              fullWidth
            >
              <ToggleButton
                value="annually"
                sx={{
                  textTransform: "none",
                  "&.Mui-selected": {
                    bgcolor: "#fff",
                    boxShadow: 1,
                    "&:hover": {
                      bgcolor: "#fff",
                    },
                  },
                }}
              >
                Annually
              </ToggleButton>
              <ToggleButton
                value="monthly"
                sx={{
                  textTransform: "none",
                  "&.Mui-selected": {
                    bgcolor: "#fff",
                    boxShadow: 1,
                    "&:hover": {
                      bgcolor: "#fff",
                    },
                  },
                }}
              >
                Monthly
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
        <Stack direction="row" spacing={3}>
          {plans.map((plan, index) => (
            <Card
              key={index}
              sx={{ flex: 1, borderRadius: "8px", background: "#F9FAFB" }}
              variant="outlined"
            >
              <CardContent>
                <Typography variant="h6" color="#525455" sx={{ mb: 2 }}>
                  {plan.title}
                </Typography>
                <Typography
                  variant="h4"
                  color="#525455"
                  fontWeight="bold"
                  sx={{ mb: 1 }}
                >
                  {plan.price}
                </Typography>
                <Typography variant="body2" color="#898989" sx={{ mb: 3 }}>
                  Per site per month
                </Typography>

                <List>
                  {plan.features.map((feature, idx) => (
                    <ListItem key={idx} sx={{ px: 0, py: 0.5 }}>
                      <ListItemIcon sx={{ minWidth: 30 }}>
                        <CheckCircleOutlineIcon
                          color="primary"
                          sx={{ fontSize: 20 }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={feature}
                        primaryTypographyProps={{
                          variant: "body2",
                          color: "#898989",
                        }}
                      />
                    </ListItem>
                  ))}
                </List>

                <ButtonCommon sx={{ width: "100%" }}>Select</ButtonCommon>
              </CardContent>
            </Card>
          ))}
        </Stack>
      </Box> */}
    </Box>
  );
};

export default GeneralSettings;
