import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Button,
  Chip,
  Grid,
  Paper,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
} from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CommonHeader from "../../CommonComponent/CommonHeader";
import {
  AddCircleOutline,
  CheckCircleOutline,
  CloseOutlined,
} from "@mui/icons-material";
import {
  AlarmBlackIcon,
  BriefcaseAddServiceIcon,
  StatusUpBlackBlackIcon,
  UserSquareBlackIcon,
} from "../../assets/svg";
import CustomBreadcrumb from "../../CommonComponent/CommonBreadcrumb";
import Loader from "../../CommonComponent/Loader";
import { getAllMasterServices } from "../../api/chooseService";
import { addService } from "../../api/servies";
import { showErrorToast, showSuccessToast } from "../../toastUtils";

const categories = [
  { icon: AlarmBlackIcon, title: "Essential" },
  { icon: BriefcaseAddServiceIcon, title: "Marketing" },
  { icon: StatusUpBlackBlackIcon, title: "Analytics" },
  { icon: UserSquareBlackIcon, title: "Personalization" },
];

const AddService = () => {
  const navigate = useNavigate();
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [selectedServices, setSelectedServices] = useState([]);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);

  console.log(selectedServices, "====selectedservices");

  const getCategoryServiceCount = (categoryName) => {
    return (Array.isArray(services?.services) ? services?.services : []).filter(
      (service) => service.category_name === categoryName.toLowerCase()
    ).length;
  };

  const breadcrumbItems = [
    {
      text: "Vendor Management",
      href: "/vendor-management",
    },
    {
      text: "Choose Services",
    },
  ];

  const filteredServices =
    selectedFilter === "all"
      ? services?.services
      : (Array.isArray(services?.services) ? services?.services : []).filter(
          (service) => service.category_name === selectedFilter
        );

  const handleServiceToggle = (service) => {
    setSelectedServices((prev) => {
      const isSelected = prev.some(
        (s) => s.service_name === service.service_name
      );
      if (isSelected) {
        return prev.filter((s) => s.service_name !== service.service_name);
      } else {
        return [...prev, service];
      }
    });
  };

  const fetchAllServices = async () => {
    try {
      setLoading(true);
      const res = await getAllMasterServices();
      if (res.status_code === 200) {
        setServices(res?.data);
        setLoading(false);
      }
    } catch (err) {
      console.log(err, "err");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllServices();
  }, []);

  const SelectedServicesBar = () => {
    if (selectedServices.length === 0) return null;

    const handleAddServiceClick = async () => {
      const data = {
        service_list: selectedServices,
      };
      try {
        setLoading(true);
        const res = await addService(data);
        if (res?.status_code === 200) {
          setLoading(false);
          showSuccessToast("Services added successfully!");
          navigate("/vendor-management");
        } else if (res?.status_code === 404 || res?.status_code === 400) {
          setLoading(false);
          showErrorToast(res?.message);
        }
      } catch (err) {
        showErrorToast(err);
        setLoading(false);
      }
    };

    return (
      <AppBar
        position="fixed"
        color="default"
        sx={{
          top: "auto",
          bottom: 0,
          bgcolor: "#00B894",
          boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.1)",
          p: 1,
        }}
      >
        <Toolbar>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              px: 2,
              paddingLeft: "20%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography variant="subtitle1" color="white">
                Selected Services
              </Typography>
              <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                {selectedServices.map((service) => (
                  <Chip
                    variant="outlined"
                    key={service.service_name}
                    label={service.service_name}
                    onDelete={() => handleServiceToggle(service)}
                    deleteIcon={
                      <CloseOutlined
                        fontSize="8px"
                        sx={{ color: "white !important" }}
                      />
                    }
                    sx={{
                      color: "white",
                      border: "1px solid #FFFFFF",
                      "& .MuiChip-deleteIcon": {
                        color: "white",
                        "&:hover": {
                          color: "rgba(255, 255, 255, 0.8)",
                        },
                      },
                    }}
                  />
                ))}
              </Box>
            </Box>
            <Button
              variant="outlined"
              sx={{
                color: "white",
                border: "2px solid #FFFFFF",
                textTransform: "capitalize",
              }}
              onClick={handleAddServiceClick}
            >
              Add Service
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    );
  };

  const [dropdownState, setDropdownState] = useState({});

  const handleDetailsClick = (event, service) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [service.service_name]: {
        anchorEl: event.currentTarget,
        showDropdown: true,
      },
    }));
  };

  const handleCloseDropdown = (service) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [service.service_name]: {
        ...prevState[service.service_name],
        showDropdown: false,
      },
    }));
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <CommonHeader
            title="Choose Services"
            subTitle="You can select/deselect the services from our default set of services on this page. We have already selected the services we found on your website or the ones you selected previously."
          />
          {/* <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              placeholder="Search service"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton edge="start">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box> */}
        </Box>
        <Box sx={{ p: 1 }}>
          {/* Breadcrumb Navigation */}
          <Box sx={{ mb: 4 }}>
            <CustomBreadcrumb
              items={breadcrumbItems}
              onBackClick={() => navigate("/vendor-management")}
            />
          </Box>

          {/* Featured Categories */}
          <Box sx={{ mb: 3 }}>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
            >
              <Typography variant="h6" color="#525455" fontWeight="bold">
                Featured Categories
              </Typography>
              <Box>
                <IconButton size="small">
                  <NavigateBeforeIcon />
                </IconButton>
                <IconButton size="small">
                  <NavigateNextIcon />
                </IconButton>
              </Box>
            </Box>
            <Grid container spacing={2}>
              {categories.map((category) => (
                <Grid item xs={12} sm={6} md={3} key={category.title}>
                  <Paper
                    sx={{
                      p: 3,
                      background:
                        "radial-gradient(circle at top right, rgba(220, 241, 239, 1) 0%, rgba(236, 246, 245, 0.6) 35%, rgba(247, 253, 252, 0) 70%), #F9FAFB",
                      borderRadius: "8px",
                      border: "none",
                    }}
                    variant="outlined"
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", mb: 1 }}
                    >
                      <category.icon sx={{ mr: 1 }} />
                      <Typography
                        variant="subtitle1"
                        color="#525455"
                        fontWeight="bold"
                        mt={1}
                      >
                        {category.title}
                      </Typography>
                    </Box>
                    <Typography variant="body2" color="#989898">
                      {getCategoryServiceCount(category.title)} Services
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Most Popular Services Section */}
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" color="#525455" fontWeight="bold" mb={2}>
              Most Popular Services
            </Typography>
            <Grid container spacing={2}>
              {(Array.isArray(services.popular_services)
                ? services.popular_services
                : []
              ).map((service) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={service.service_name + "-popular"}
                >
                  <Card
                    variant="outlined"
                    borderColor="#E6E7EB"
                    borderRadius="12px"
                    sx={{ p: 1, height: "100%" }}
                  >
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", flexDirection: "column", mb: 2 }}
                      >
                        <Box
                          sx={{
                            width: 40,
                            height: 40,
                            borderRadius: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            ml: 1,
                          }}
                        >
                          <img
                            src={`/images/${service.service_icon}`}
                            alt={`${service.service_name} Icon`}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          gap="10px"
                          mt={2}
                        >
                          <Typography variant="subtitle1">
                            {service.service_name}
                          </Typography>
                          <Chip
                            label={service.category_name}
                            size="small"
                            sx={{
                              bgcolor: "#E6E7EB",
                              height: 20,
                              borderRadius: "4px",
                            }}
                          />
                        </Box>
                      </Box>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          mb: 2,
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 3,
                          textOverflow: "ellipsis",
                        }}
                      >
                        {service.description}
                      </Typography>
                      <Box sx={{ display: "flex", gap: 1, mt: "auto" }}>
                        <Button
                          variant="outlined"
                          color="inherit"
                          fullWidth
                          sx={{ textTransform: "capitalize" }}
                          onClick={(event) =>
                            handleDetailsClick(event, service)
                          }
                        >
                          Details
                        </Button>
                        <Menu
                          anchorEl={
                            dropdownState[service.service_name]?.anchorEl
                          }
                          open={
                            dropdownState[service.service_name]?.showDropdown
                          }
                          onClose={() => handleCloseDropdown(service)}
                        >
                          {service?.storage_item?.length === 0 ? (
                            <MenuItem>No cookies found</MenuItem>
                          ) : (
                            (Array.isArray(service?.storage_item)
                              ? service?.storage_item
                              : []
                            ).map((cookie, index) => (
                              <MenuItem key={index}>{cookie.name}</MenuItem>
                            ))
                          )}
                        </Menu>
                        <Button
                          variant="outlined"
                          color={
                            selectedServices.some(
                              (s) => s.service_name === service.service_name
                            )
                              ? "primary"
                              : "inherit"
                          }
                          sx={{
                            textTransform: "capitalize",
                            borderRadius: "8px",
                          }}
                          startIcon={
                            selectedServices.some(
                              (s) => s.service_name === service.service_name
                            ) ? (
                              <CheckCircleOutline />
                            ) : (
                              <AddCircleOutline />
                            )
                          }
                          fullWidth
                          onClick={() => handleServiceToggle(service)} // Handle toggle on button click
                        >
                          {selectedServices.some(
                            (s) => s.service_name === service.service_name
                          )
                            ? "Selected"
                            : "Add"}
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Filter Section */}
          <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6" fontWeight="bold" color="#525455">
              All Services
            </Typography>
            <Box sx={{ display: "flex", gap: 1 }}>
              {[
                "All",
                "Essential",
                "Marketing",
                "Analytics",
                "Personalization",
              ].map((filter) => (
                <Chip
                  key={filter}
                  label={filter}
                  onClick={() => setSelectedFilter(filter?.toLocaleLowerCase())}
                  color={
                    filter?.toLocaleLowerCase() === selectedFilter
                      ? "primary"
                      : "default"
                  }
                  variant="outlined"
                  sx={{
                    "&.MuiChip-root": {
                      borderRadius: 1,
                      height: 32,
                    },
                  }}
                />
              ))}
            </Box>
          </Box>

          {/* All Services List */}
          <Grid container spacing={2}>
            {(Array.isArray(filteredServices) ? filteredServices : []).map(
              (service) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={service.service_name + "-all"}
                >
                  <Card
                    variant="outlined"
                    borderColor="#E6E7EB"
                    borderRadius="12px"
                    sx={{ p: 1, height: "100%" }}
                  >
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", flexDirection: "column", mb: 2 }}
                      >
                        <Box
                          sx={{
                            width: 40,
                            height: 40,
                            borderRadius: 1,
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            mr: 2,
                          }}
                        >
                          <img
                            src={`/images/${service.service_icon}`}
                            alt={`${service.service_name} Icon`}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          gap="10px"
                          mt={2}
                        >
                          <Typography variant="subtitle1">
                            {service.service_name}
                          </Typography>
                          <Chip
                            label={service.category_name}
                            size="small"
                            sx={{
                              bgcolor: "#E6E7EB",
                              height: 20,
                              borderRadius: "4px",
                            }}
                          />
                        </Box>
                      </Box>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          mb: 2,
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 3,
                          textOverflow: "ellipsis",
                        }}
                      >
                        {service.description}
                      </Typography>
                      <Box sx={{ display: "flex", gap: 1, mt: "auto" }}>
                        <Button
                          variant="outlined"
                          color="inherit"
                          fullWidth
                          sx={{ textTransform: "capitalize" }}
                          onClick={(event) =>
                            handleDetailsClick(event, service)
                          }
                        >
                          Details
                        </Button>
                        <Menu
                          anchorEl={
                            dropdownState[service.service_name]?.anchorEl
                          }
                          open={
                            dropdownState[service.service_name]?.showDropdown
                          }
                          onClose={() => handleCloseDropdown(service)}
                        >
                          {service?.storage_item?.length === 0 ? (
                            <MenuItem>No cookies found</MenuItem>
                          ) : (
                            (Array.isArray(service?.storage_item)
                              ? service?.storage_item
                              : []
                            ).map((cookie, index) => (
                              <MenuItem key={index}>{cookie.name}</MenuItem>
                            ))
                          )}
                        </Menu>
                        <Button
                          variant="outlined"
                          color={
                            selectedServices.some(
                              (s) => s.service_name === service.service_name
                            )
                              ? "primary"
                              : "inherit"
                          }
                          sx={{
                            textTransform: "capitalize",
                            borderRadius: "8px",
                          }}
                          startIcon={
                            selectedServices.some(
                              (s) => s.service_name === service.service_name
                            ) ? (
                              <CheckCircleOutline />
                            ) : (
                              <AddCircleOutline />
                            )
                          }
                          fullWidth
                          onClick={() => handleServiceToggle(service)} // Handle toggle on button click
                        >
                          {selectedServices.some(
                            (s) => s.service_name === service.service_name
                          )
                            ? "Selected"
                            : "Add"}
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              )
            )}
          </Grid>
        </Box>
      </Box>
      <SelectedServicesBar />
    </>
  );
};

export default AddService;
