// src/api/apiMasterClient.js
import axios from "axios";

const apiMasterClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

apiMasterClient.interceptors.request.use(
  (config) => {
    const userId = localStorage.getItem("user_id");
    const accessToken = localStorage.getItem(
      `CognitoIdentityServiceProvider.${process.env.REACT_APP_USER_POOL_CLIENT_ID}.${userId}.accessToken`
    );
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    config.headers["organization_id"] = 1;
    config.headers["website_id"] = 1;
    
    return config;
  },
  (error) => Promise.reject(error)
);

export default apiMasterClient;
