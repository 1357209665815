import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CommonHeader from "../CommonComponent/CommonHeader";
import CommonSubHeader from "../CommonComponent/CommonSubHeader";
import { HTML, Shopify, Webflow, Wordpress } from "../assets/svg";
import { getWebsiteById } from "../api/websiteService";
import Loader from "../CommonComponent/Loader";

const platforms = [
  {
    id: "html",
    name: "HTML",
    icon: HTML,
    iconColor: "#E44D26",
    instructions: {
      steps: [
        {
          title: "Copy the Script",
          isCode: true,
        },
        {
          title: "Insert the Script",
          content:
            "Place the copied script in the <code>&lt;head&gt;</code> section of your website's HTML.",
          isCode: false,
        },
        {
          title: "Test the Widget",
          content:
            "Refresh your website and ensure the cookie consent widget appears as expected.",
          isCode: false,
        },
      ],
      troubleshooting: [
        "If the widget doesn't display, ensure there are no conflicts with other scripts or theme customizations.",
        "Clear your browser cache and cookies, then reload your store.",
      ],
    },
  },
  {
    id: "shopify",
    name: "Shopify",
    icon: Shopify,
    iconColor: "#96BF47",
    instructions: {
      steps: [
        {
          title: "Copy the Script",
          isCode: true,
        },
        {
          title: "Insert the Script",
          content: [
            "Log into your Shopify admin dashboard.",
            "Navigate to Online Store > Themes.",
            "Find the theme you want to edit and click on the Actions dropdown, then select Edit code.",
            "In the left sidebar, under the Layout folder, click on the `theme.liquid` file.",
            "Paste the copied script within the `<head>` section of the `theme.liquid` file.",
            "Save the changes.",
          ],
          isBulletList: true,
        },
        {
          title: "Test the Widget",
          content:
            "Visit your Shopify store to ensure the widget displays correctly.",
          isCode: false,
        },
      ],
      troubleshooting: [
        "If the widget doesn't display, ensure there are no conflicts with other scripts or styles on your website.",
        "Clear your browser cache and cookies, then reload the website.",
      ],
    },
  },
  {
    id: "webflow",
    name: "Webflow",
    icon: Webflow,
    iconColor: "#96BF47",
    instructions: {
      steps: [
        {
          title: "Copy the Script",
          isCode: true,
        },
        {
          title: "Insert the Script",
          content: [
            "Log into your Webflow dashboard.",
            "Select your project to open the Designer.",
            "Navigate to the Project Settings (gear icon at the bottom-left).",
            "Choose the Custom Code tab at the top.",
            "In the Head Code section, paste the copied script.",
            "Save the changes and then click Publish at the top right of the Designer.",
          ],
          isBulletList: true,
        },
        {
          title: "Test the Widget",
          content:
            "Open your live Webflow website to ensure the widget displays correctly.",
          isCode: false,
        },
      ],
      troubleshooting: [
        "If the widget does not appear, ensure there are no conflicts with other scripts or interactions in your Webflow project.",
        "Clear your browser cache and cookies, then reload the website.",
      ],
    },
  },
  {
    id: "wordpress",
    name: "Wordpress",
    icon: Wordpress,
    iconColor: "#96BF47",
    instructions: {
      steps: [
        {
          title: "Copy the Script",
          isCode: true,
        },
        {
          title: "Insert the Script",
          content: [
            "Log into your WordPress dashboard.",
            "Navigate to Appearance > Theme Editor.",
            "Locate the `header.php` file on the right-hand side panel.",
            "Paste the copied script within the <head> section of the `header.php` file.",
            "Save the changes",
          ],
          isBulletList: true,
        },
        {
          title: "Test the Widget",
          content:
            "Visit your website to ensure the widget displays correctly.",
          isCode: false,
        },
      ],
      troubleshooting: [
        "If the widget doesn't display, ensure there are no conflicts with other scripts or styles on your website.",
        "Clear your browser cache and cookies, then reload the website.",
      ],
    },
  },
];

const InstallationGuide = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [websiteDetails, setWebsiteDetails] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const fetchWebsiteById = async () => {
    try {
      setLoading(true);
      const websiteId = localStorage.getItem("website_id");
      const data = await getWebsiteById(websiteId);
      if (data?.status_code === 200) {
        setLoading(false);
        setWebsiteDetails(data?.data);
      }
    } catch (error) {
      console.error("Error fetching websites:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWebsiteById();
  }, []);

  const renderConfigurationSteps = (platform) => (
    <Box>
      <Typography variant="h6" color="#525455" fontWeight="bold" sx={{ mb: 1 }}>
        Configure in {platform.name}
      </Typography>

      <Typography variant="body1" color="#989898" sx={{ mb: 2 }}>
        Follow these steps to seamlessly integrate our widget into your website.
      </Typography>

      <Typography variant="h6" color="#525455" fontWeight="600" sx={{ mb: 3 }}>
        Instructions
      </Typography>

      <Stack spacing={4}>
        {platform.instructions.steps.map((step, index) => (
          <Box key={index} sx={{ mb: 3 }}>
            <Typography
              variant="body1"
              color="#525455"
              fontWeight="bold"
              sx={{ mb: 1 }}
            >
              {`${index + 1}. ${step.title}`}
            </Typography>
            {step.isCode ? (
              <Paper
                sx={{
                  bgcolor: "grey.900",
                  p: 3,
                  borderRadius: 1,
                  color: "#FF4757",
                  mt: 2,
                }}
              >
                <code>
                  {'<script src="https://dpc.thedataprivacy.cloud/' +
                    websiteDetails?.account_hashid +
                    '.js"></script>'}
                </code>
              </Paper>
            ) : step.isBulletList ? (
              <List sx={{ pl: 2, mt: 1 }}>
                {step.content.map((item, idx) => (
                  <ListItem
                    key={idx}
                    sx={{
                      display: "list-item",
                      listStyleType: "disc",
                      px: 0,
                      pl: 2,
                      py: 0.5,
                    }}
                  >
                    <Typography
                      variant="body1"
                      color="#989898"
                      sx={{
                        "& code": {
                          fontWeight: "bold",
                          color: "#FF6348",
                          fontFamily: item.includes("`")
                            ? "monospace"
                            : "inherit",
                        },
                      }}
                      dangerouslySetInnerHTML={{
                        __html: item
                          .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
                          .replace(/`([^`]+)`/g, "<code>$1</code>"),
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography
                variant="body1"
                color="#989898"
                sx={{ mt: 1 }}
                dangerouslySetInnerHTML={{ __html: step.content }}
              />
            )}
          </Box>
        ))}

        <Typography variant="h6" color="#525455" fontWeight="600">
          Troubleshooting
        </Typography>
        <List sx={{ pl: 2 }}>
          {platform.instructions.troubleshooting.map((step, index) => (
            <ListItem
              key={index}
              sx={{
                display: "list-item",
                listStyleType: "disc",
                px: 0,
                pl: 2,
                py: 0.5,
              }}
            >
              <Typography variant="body1" color="#989898">
                {step}
              </Typography>
            </ListItem>
          ))}
        </List>

        <Box sx={{ mt: 4 }}>
          <Typography
            variant="h6"
            color="#525455"
            fontWeight="600"
            sx={{ mb: 2 }}
          >
            Support
          </Typography>
          <Typography variant="body1" color="#989898">
            If you encounter any issues or require further assistance, please
            reach out to us at{" "}
            <Link href="mailto:support@thedataprivacy.cloud" color="primary">
              support@thedataprivacy.cloud
            </Link>
          </Typography>
        </Box>
      </Stack>
    </Box>
  );

  return (
    <Box sx={{ maxWidth: "100%", overflow: "hidden" }}>
      <CommonHeader
        title="Install Plugin"
        subTitle="Please follow the steps to install our script on your website for us to display cookie banner to your visitors and store their consent"
      />
      <CommonSubHeader
        mediumTitle="Add to your website"
        subTitle="Get started by choosing the language or platform powering your application."
      />

      {loading ? (
        <Loader />
      ) : (
        <Stack spacing={3} sx={{ mt: 4 }}>
          {platforms.map((platform) => (
            <Accordion
              key={platform.id}
              expanded={expanded === platform.id}
              onChange={handleChange(platform.id)}
              sx={{
                "&:before": { display: "none" },
                boxShadow: "none",
                border: "1px solid",
                borderColor: "divider",
                borderRadius: "8px",
                background: "#F9FAFB",
              }}
              square={false}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ px: 3, py: 2 }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <platform.icon sx={{ color: platform.iconColor, mr: 2 }} />
                  <Box ml={2}>
                    <Typography
                      variant="subtitle1"
                      color="#525455"
                      sx={{ fontWeight: 700 }}
                    >
                      {platform.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Click to see install guide
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>
              <Divider />
              <AccordionDetails sx={{ px: 3, pt: 3, pb: 4 }}>
                {renderConfigurationSteps(platform)}
              </AccordionDetails>
            </Accordion>
          ))}
        </Stack>
      )}
    </Box>
  );
};

export default InstallationGuide;
