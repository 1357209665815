import { Box } from "@mui/material";
import CommonHeader from "../../CommonComponent/CommonHeader";
import Sidebar from "../../CommonComponent/Sidebar";
import { useState } from "react";
import GeneralSettings from "./GeneralSettings";
import Team from "./Team";
// import ConsentFeatures from "./ConsentFeatures";

const Settings = () => {
  const sidebarTabs = ["General", "Team"];

  const renderContent = () => {
    switch (activeTab) {
      case "General":
        return <GeneralSettings />;
      case "Team":
        return <Team />;
      // case "Consent Features":
      //   return <ConsentFeatures />;
      // case "Plans":
      //   return <div>seeying</div>;
      default:
        return null;
    }
  };

  const [activeTab, setActiveTab] = useState("General");

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <Box>
          <CommonHeader
            title="Settings"
            subTitle="Set your account level preferences here"
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          height: "calc(100% - 64px)",
        }}
      >
        <Box
          sx={{
            width: "250px",
            borderRight: "1px solid #e0e0e0",
            bgcolor: "white",
            flexShrink: 0,
            overflow: "hidden",
          }}
        >
          <Sidebar
            tabs={sidebarTabs}
            activeTab={activeTab}
            onTabChange={setActiveTab}
          />
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            p: 3,
            overflowY: "auto",
          }}
        >
          {renderContent()}
        </Box>
      </Box>
    </>
  );
};

export default Settings;
