import { createSlice } from "@reduxjs/toolkit";

const scanSlice = createSlice({
  name: "scan",
  initialState: {
    scanData: null, // Initial state
  },
  reducers: {
    setScanData: (state, action) => {
      state.scanData = action.payload; // Update scan data
    },
    clearScanData: (state) => {
      state.scanData = null; // Clear scan data
    },
  },
});

export const { setScanData, clearScanData } = scanSlice.actions;
export default scanSlice.reducer;
