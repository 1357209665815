import apiMasterClient from "./apiMasterClient";

export const getAllMasterServices = async () => {
  try {
    const response = await apiMasterClient.get("/services?page=1&per_page=100");
    return response.data;
  } catch (error) {
    console.error("Error fetching dashboard stats:", error);
    throw error;
  }
};
