// components/Loader.js
import React from "react";
import { CircularProgress, Box } from "@mui/material";

// A reusable loader component
const Loader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        top: "auto",
        left: "auto",
        width: "100%",
        height: "100vh",
        backgroundColor: "transparent",
        zIndex: "auto",
      }}
    >
      <CircularProgress size={40} color="primary" />
    </Box>
  );
};

export default Loader;
