import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Link,
  Paper,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import myIcon from "../assets/svg/logo.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { useNavigate } from "react-router-dom";
import { signIn, getCurrentUser, fetchUserAttributes } from "@aws-amplify/auth";
import { getAllWebsites } from "../api/websiteService";
import axios from "axios";
import { showErrorToast, showSuccessToast } from "../toastUtils";

const LoginPage = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = React.useState({});
  const [touched, setTouched] = React.useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkUser = async () => {
      try {
        const currentUser = await getCurrentUser(); 
        if (currentUser) {
          setIsAuthenticated(true); 
        } else {
          setIsAuthenticated(false); 
        }
      } catch (error) {
        setIsAuthenticated(false); 
      }
    };

    checkUser();
  }, []);

  useEffect(() => {
    if (isAuthenticated === true) {
      navigate("/dashboard");
    }
  }, [isAuthenticated, navigate]);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const validateField = (name, value) => {
    switch (name) {
      case "email":
        if (!value) {
          return "Email is required";
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
          return "Invalid email address";
        }
        return "";

      case "password":
        if (!value) {
          return "Password is required";
        }
        return "";

      default:
        return "";
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const fieldValue = type === "checkbox" ? checked : value;

    setFormData((prev) => ({
      ...prev,
      [name]: fieldValue,
    }));

    if (touched[name]) {
      const fieldError = validateField(name, fieldValue);
      setErrors((prev) => ({
        ...prev,
        [name]: fieldError,
      }));
    }
  };

  const handleBlur = (event) => {
    const { name, value, type, checked } = event.target;
    const fieldValue = type === "checkbox" ? checked : value;

    setTouched((prev) => ({
      ...prev,
      [name]: true,
    }));

    const fieldError = validateField(name, fieldValue);
    setErrors((prev) => ({
      ...prev,
      [name]: fieldError,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    console.log("call /function");

    try {
      const { email, password } = formData;

      if (!email || !password) {
        setError("Please fill in all required fields.");
        return;
      }

      const signInResponse = await signIn({ username: email, password });

      console.log(signInResponse,"signin");
      

      if (
        signInResponse.nextStep.signInStep ===
        "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
      ) {
        navigate("/change-password", { state: { email } });
        return;
      }

      const data = await getCurrentUser();
      const currentUser = await fetchUserAttributes();

      const organizationId = currentUser?.profile;
      console.log("Raw organizationId:", organizationId);

      if (organizationId !== undefined && organizationId !== null) {
        const numericId = Number(organizationId);

        if (!isNaN(numericId)) {
          localStorage.setItem("organization_id_str", String(numericId));
        }
      }

      if (data?.userId) {
        localStorage.setItem("user_id", data.userId);
      }

      // Wait for organization_id to be stored in local storage
      await waitForOrganizationId();

      console.log("Calling API after delay...");
      const response = await new Promise((resolve) =>
        setTimeout(async () => {
          const apiResponse = await getAllWebsites();
          resolve(apiResponse);
        }, 1000)
      );

      console.log(response, "response");

      // Navigate based on response
      if (response?.status_code === 404) {
        navigate("/add-website");
      } else {
        showSuccessToast("Logged in successfully!");
        navigate("/dashboard");
      }
    } catch (error) {
      handleSignInError(error);
      showErrorToast(error);
    } finally {
      setLoading(false);
    }
  };

  // Helper function to wait for organization_id
  const waitForOrganizationId = async () => {
    return new Promise((resolve) => {
      const checkStorage = () => {
        const storedOrgId = localStorage.getItem("organization_id_str");
        if (storedOrgId && !isNaN(Number(storedOrgId))) {
          console.log(storedOrgId, "====orgid");

          resolve();
        } else {
          setTimeout(checkStorage, 100); // Retry after 100ms
        }
      };
      checkStorage();
    });
  };

  // Error handler function
  const handleSignInError = (error) => {
    console.error("Error signing in:", error);

    if (error.name === "NotAuthorizedException") {
      setError("Incorrect username or password.");
    } else if (error.name === "UserNotConfirmedException") {
      setError("Please verify your email before logging in.");
    } else if (error.name === "UserNotFoundException") {
      setError("User does not exist.");
    } else if (axios.isCancel(error)) {
      setError("Request canceled. Please try again.");
    } else {
      setError("An error occurred during sign in. Please try again.");
    }
  };

  return (
    <Container
      component="main"
      maxWidth={false}
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#F8F8F8",
        padding: 3,
      }}
    >
      {/* Logo at the top */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 4,
        }}
      >
        <img src={myIcon} alt="logo" width="250" height="50" />
      </Box>

      {/* Form Section */}
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: 450,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* Text above the form */}
          <Typography
            variant="h5"
            sx={{
              mb: 4,
              color: "#5F5F5F",
              fontWeight: 600,
              fontFamily: "DM Sans",
            }}
          >
            Sign in to your account
          </Typography>

          <Paper
            elevation={2}
            sx={{
              width: "90%",
              p: 4,
              borderRadius: "12px",
              backgroundColor: "white",
              border: "none",
            }}
            variant="outlined"
          >
            <Box component="form" onSubmit={handleSubmit}>
              <InputLabel shrink htmlFor="email" required>
                User name or Email
              </InputLabel>
              <TextField
                margin="none"
                size="small"
                required
                fullWidth
                name="email"
                type="email"
                autoComplete="email"
                value={formData.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ mb: 2 }}
              />
              <InputLabel shrink htmlFor="password" required>
                Password
              </InputLabel>
              <TextField
                margin="none"
                size="small"
                required
                fullWidth
                name="password"
                type={showPassword ? "text" : "password"}
                autoComplete="current-password"
                value={formData.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword} edge="end">
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ mb: 2 }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end", // Align to the right
                }}
              >
                <Link
                  href="/forgot-password"
                  sx={{
                    color: "#00B894",
                    textDecoration: "none",
                    fontWeight: 500,
                    fontSize: "16px",
                  }}
                >
                  Forgot password?
                </Link>
              </Box>

              <Button
                type="submit"
                size="small"
                fullWidth
                variant="outlined"
                sx={{
                  mt: 2,
                  mb: 2,
                  "&:hover": {
                    backgroundColor: "#009B7D",
                    color: "white",
                  },
                  borderRadius: 1.5,
                  border: "2px solid #009B7D",
                  textTransform: "none",
                  fontSize: "1rem",
                  color: "#525455",
                  fontWeight: 700,
                }}
                disabled={loading}
              >
                {!loading ? "Login" : <CircularProgress size={20} />}
              </Button>
              <Typography color="error">{error}</Typography>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginPage;
