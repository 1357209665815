// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: `'DM Sans', sans-serif`, // Set DM Sans as the default font
    },
    palette: {
        // Define your theme colors here if necessary
        primary: {
            main: '#00B894', // Example color
        },
    },
});

export default theme;
