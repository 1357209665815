import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Chip,
  Switch,
  Divider,
  Typography,
  TableRow,
  TableBody,
  TableContainer,
  Table,
  TableHead,
  IconButton,
  TableCell,
  tableCellClasses,
  InputLabel,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
  Menu,
  ListItemIcon,
  FormHelperText,
} from "@mui/material";
import CommonSubHeader from "../../CommonComponent/CommonSubHeader";
import ButtonCommon from "../../CommonComponent/ButtonCommon";
import {
  CheckCircleOutline,
  CancelOutlined,
  DeleteOutline,
  LaunchOutlined,
  Add,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { Eye, StorageItemIcon } from "../../assets/svg";
import CustomBreadcrumb from "../../CommonComponent/CommonBreadcrumb";
import { useNavigate } from "react-router-dom";
import CommonDrawer from "../../CommonComponent/CommonDrawer";
import {
  addStorageItem,
  createService,
  deleteService,
  deleteStorageItem,
  getAllServices,
  getServiceById,
  updateService,
  updateStorageItem,
} from "../../api/servies";
import { showErrorToast, showSuccessToast } from "../../toastUtils";
import { getAllCategory } from "../../api/category";
import Loader from "../../CommonComponent/Loader";

const DM_SANS = styled("span")({
  fontFamily: "DM Sans",
});

const ServiceList = ({
  title,
  subtitle,
  services,
  onToggle,
  onSelectService,
  setServices,
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [serviceName, setServiceName] = useState("");
  const [serviceDescription, setServiceDescription] = useState("");
  const [activeService, setActiveService] = useState(true);
  const [open, setOpen] = useState(false);
  const [storageItems, setStorageItems] = useState([
    {
      name: "",
      description: "",
      duration: "",
      durationValue: "",
    },
  ]);
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    serviceName: "",
    selectedCategory: "",
    serviceDescription: "",
  });

  const handleAddStorageItem = () => {
    setStorageItems([
      ...storageItems,
      {
        name: "",
        description: "",
        duration: "Years",
        durationValue: "",
      },
    ]);
  };

  const [selectedCategory, setSelectedCategory] = useState("");

  const categoryOptions = (Array.isArray(category) ? category : []).map(
    (category) => ({
      id: category.category_id,
      name: category.category_name,
    })
  );

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  // Handle input changes for storage items
  const handleStorageItemChange = (index, field, value) => {
    const updatedItems = [...storageItems];
    updatedItems[index][field] = value;
    setStorageItems(updatedItems);
  };

  const [durationOptions, setDurationOptions] = useState([]);

  // Handle removing a storage item
  const handleRemoveStorageItem = (index) => {
    const updatedStorageItems = storageItems.filter((_, i) => i !== index);
    setStorageItems(updatedStorageItems);
  };

  const handleDurationSelect = (e, index) => {
    const selectedDuration = e.target.value;
    const options = getDurationOptions(selectedDuration);
    setDurationOptions(options);
    handleStorageItemChange(index, "duration", selectedDuration);
  };

  const getDurationOptions = (type) => {
    switch (type) {
      case "year":
        return Array.from({ length: 10 }, (_, i) => i + 1); // Years 1-10
      case "month":
        return Array.from({ length: 12 }, (_, i) => i + 1); // Months 1-12
      case "day":
        return Array.from({ length: 31 }, (_, i) => i + 1); // Days 1-31
      case "hour":
        return Array.from({ length: 24 }, (_, i) => i + 1); // Hours 1-24
      default:
        return [];
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [activeStorageItems, setActiveStorageItems] = useState(true);

  const validateForm = () => {
    let isValid = true;
    let formErrors = {
      serviceName: "",
      selectedCategory: "",
      serviceDescription: "",
      storageItems: "",
    };

    // Validate Service Name
    if (!serviceName.trim()) {
      formErrors.serviceName = "Service Name is required.";
      isValid = false;
    }

    // Validate Selected Category
    if (!selectedCategory) {
      formErrors.selectedCategory = "Category selection is required.";
      isValid = false;
    }

    // Validate Service Description
    if (!serviceDescription.trim()) {
      formErrors.serviceDescription = "Service Description is required.";
      isValid = false;
    }

    // Validate Storage Items only if the switch is on
    if (activeStorageItems) {
      storageItems.forEach((item, index) => {
        // Validate Storage Item Name
        if (!item.name.trim()) {
          formErrors[`storageItemName${index}`] =
            "Storage Item name is required.";
          isValid = false;
        }

        // Validate Storage Item Description
        if (!item.description.trim()) {
          formErrors[`storageItemDescription${index}`] =
            "Storage Item description is required.";
          isValid = false;
        }

        // Validate Duration and Duration Number
        if (!item.duration) {
          formErrors[`storageItemDuration${index}`] =
            "Storage Item Duration is Required";
          isValid = false;
        }

        if (!item.durationNumber) {
          formErrors[`storageItemDurationNumber${index}`] =
            "Storage Item Duration Number is Required.";
          isValid = false;
        } else if (item.durationNumber <= 0) {
          formErrors[`storageItemDurationNumber${index}`] =
            "Duration number must be greater than zero.";
          isValid = false;
        }
      });
    }
    setErrors(formErrors);
    return isValid;
  };

  const formatStorageItemsData = () => {
    return storageItems.map((item) => ({
      name: item.name,
      description: item.description,
      duration: `${item.durationNumber} ${item.duration}`,
    }));
  };

  const handleSubmit = async (e) => {
    if (!validateForm()) {
      return;
    }

    e.preventDefault();

    console.log("createddddd");

    // Prepare form data
    const formData = {
      service_name: serviceName,
      description: serviceDescription,
      category_id: selectedCategory,
      storage_item: formatStorageItemsData(),
      active: activeService,
      // translations: translations,
    };

    try {
      // Call your API (replace URL with your actual API endpoint)
      setLoading(true);
      const response = await createService(formData);
      if (response.status_code === 200) {
        setTimeout(async () => {
          const res = await getAllServices();
          if (res?.status_code === 200 || res?.status_code === 404) {
            setServices(res?.data?.services);
            setLoading(false);
          }
          showSuccessToast("Service created successfully!");
          setOpen(false);
          setLoading(false);
        }, 200);
      } else {
        showErrorToast("Error in creating service");
        setOpen(false);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      showErrorToast("There was an error submitting the form.");
      setOpen(false);
      setLoading(false);
    }
  };

  const fetchAllCategories = async () => {
    try {
      setLoading(true);
      const res = await getAllCategory();
      if (res?.status_code === 200 || res?.status_code === 404) {
        setCategory(res?.data);
        console.log(res?.data, "==res");
        setLoading(false);
      }
    } catch (err) {
      console.log(err, "error");
      showErrorToast(err);
      setLoading(false);
    }
  };

  const handleStorageSwitchChange = (e) => {
    setActiveStorageItems(e.target.checked);
    if (!e.target.checked) {
      setStorageItems([]); // Clear all storage items when turned off
    }
  };

  const handleServiceSwitchChange = (e) => {
    setActiveService(e.target.checked);
    if (!e.target.checked) {
      setActiveStorageItems(false); // Disable storage items if the service is turned off
      setStorageItems([]); // Clear storage items when service is inactive
    }
  };

  useEffect(() => {
    fetchAllCategories();
    // eslint-disable-next-line
  }, []);

  const renderDrawer = () => (
    <CommonDrawer
      open={open}
      onClose={() => setOpen(false)}
      title="Create Service"
      subTitle="Sort cookies with specific jobs, helping users personalize their cookie choices."
      primaryButton={{
        label: "Create",
        onClick: handleSubmit,
        sx: { px: 2 },
      }}
      cancelButtonLabel="Cancel"
      drawerWidth={400}
    >
      <Box component="form" onSubmit={handleSubmit}>
        {/* Default Settings Section */}
        <Typography
          variant="subtitle2"
          sx={{ fontWeight: 600, color: "#525455", mb: 2 }}
        >
          Default Settings
        </Typography>

        {/* Service Name Field */}
        <Box sx={{ mb: 3 }}>
          <InputLabel
            required
            sx={{ color: "#525455", mb: 1, fontSize: "0.875rem" }}
          >
            Name
          </InputLabel>
          <TextField
            fullWidth
            size="small"
            placeholder="Google Analytics"
            value={serviceName}
            onChange={(e) => setServiceName(e.target.value)}
            sx={{ mb: 0.5 }}
            error={!!errors.serviceName}
            helperText={errors.serviceName}
          />
          <Typography variant="caption" color="text.secondary">
            You can add * to pattern match & capture all items that follow this
            pattern.
          </Typography>
        </Box>

        {/* Category Selection */}
        <Box sx={{ mb: 3 }}>
          <InputLabel
            required
            sx={{ color: "#525455", mb: 1, fontSize: "0.875rem" }}
          >
            Select Category
          </InputLabel>
          <Select
            value={selectedCategory}
            onChange={handleCategoryChange}
            fullWidth
            size="small"
            error={!!errors.selectedCategory}
          >
            {categoryOptions.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error>{errors.selectedCategory}</FormHelperText>
        </Box>

        {/* Service Description */}
        <Box sx={{ mb: 4 }}>
          <InputLabel
            required
            sx={{ color: "#525455", mb: 1, fontSize: "0.875rem" }}
          >
            Description of Service
          </InputLabel>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={serviceDescription}
            onChange={(e) => setServiceDescription(e.target.value)}
            placeholder="Description"
            error={!!errors.serviceDescription}
            helperText={errors.serviceDescription}
          />
        </Box>

        {/* Storage Items Section */}
        <Box sx={{ mb: 4, bgcolor: "#F8F8F8", p: 2, borderRadius: "12px" }}>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Box display="flex" flexDirection="column">
              <Typography color="#5F5F5F" fontWeight="600">
                Storage Items
              </Typography>
              <Typography variant="body2" color="#5F5F5F">
                Enable the service by default.
              </Typography>
            </Box>
            <Switch
              color="primary"
              checked={activeStorageItems}
              onChange={handleStorageSwitchChange}
              disabled={!activeService} // Disable the storage switch if the service is inactive
            />
          </Box>

          {/* Only show and enable storage items if service is active */}
          {activeStorageItems && activeService ? (
            <>
              {storageItems.map((item, index) => (
                <Box
                  key={index}
                  sx={{ mb: 2, p: 2, bgcolor: "#FFF", borderRadius: 2 }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Typography variant="subtitle2" sx={{ color: "#525455" }}>
                      Storage Item Name
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={() => handleRemoveStorageItem(index)}
                    >
                      <CancelOutlined size={12} />
                    </IconButton>
                  </Box>

                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Storage Item"
                    value={item.name}
                    onChange={(e) =>
                      handleStorageItemChange(index, "name", e.target.value)
                    }
                    sx={{ mb: 2 }}
                    error={!!errors[`storageItemName${index}`]} // Error condition
                    helperText={errors[`storageItemName${index}`]} // Error message
                  />
                  {/* Description */}
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    value={item.description}
                    onChange={(e) =>
                      handleStorageItemChange(
                        index,
                        "description",
                        e.target.value
                      )
                    }
                    placeholder="Description here"
                    sx={{ mb: 2 }}
                    error={!!errors[`storageItemDescription${index}`]} // Error condition
                    helperText={errors[`storageItemDescription${index}`]} // Error message
                  />

                  {/* Duration */}
                  <Box
                    sx={{ display: "flex", gap: 2, flexDirection: "column" }}
                  >
                    <Select
                      size="small"
                      value={item.duration}
                      onChange={(e) => handleDurationSelect(e, index)}
                      sx={{ flex: 1 }}
                      error={!!errors[`storageItemDuration${index}`]} // Error condition
                    >
                      <MenuItem value="year">Year</MenuItem>
                      <MenuItem value="month">Month</MenuItem>
                      <MenuItem value="day">Day</MenuItem>
                      <MenuItem value="hour">Hour</MenuItem>
                    </Select>
                    {errors[`storageItemDuration${index}`] && (
                      <FormHelperText error>
                        {errors[`storageItemDuration${index}`]}
                      </FormHelperText>
                    )}
                    <Select
                      size="small"
                      value={item.durationNumber}
                      onChange={(e) =>
                        handleStorageItemChange(
                          index,
                          "durationNumber",
                          e.target.value
                        )
                      }
                      sx={{ flex: 1 }}
                      error={!!errors[`storageItemDurationNumber${index}`]} // Error condition
                    >
                      {durationOptions.length > 0 ? (
                        durationOptions.map((num) => (
                          <MenuItem key={num} value={num}>
                            {num}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="">Select Duration Number</MenuItem>
                      )}
                    </Select>
                    {errors[`storageItemDurationNumber${index}`] && (
                      <FormHelperText error>
                        {errors[`storageItemDurationNumber${index}`]}
                      </FormHelperText>
                    )}
                  </Box>
                </Box>
              ))}
            </>
          ) : (
            <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
              Storage items are disabled because the service is not active.
            </Typography>
          )}

          {activeStorageItems && (
            <Button
              startIcon={<Add size={16} />}
              onClick={handleAddStorageItem}
              sx={{ color: "#525455", textTransform: "none", fontWeight: 700 }}
            >
              Add More
            </Button>
          )}
          <Divider sx={{ mt: 2, mb: 2 }} />

          {/* Service Active */}
          <Box sx={{ mb: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 1,
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: 600, color: "#525455" }}
              >
                Service Active
              </Typography>
              <Switch
                color="primary"
                checked={activeService}
                onChange={handleServiceSwitchChange}
              />
            </Box>
            <Typography variant="body2" color="text.secondary">
              If disabled, this service will not be shown to any visitors.
            </Typography>
          </Box>
        </Box>
      </Box>
    </CommonDrawer>
  );

  if (loading) {
    <Loader />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "auto",
        background: "#F9FAFB",
        borderRadius: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          p: 2,
        }}
      >
        <CommonSubHeader mediumTitle={title} subTitle={subtitle} />
        {title === "Active Services" && (
          <>
            <Button
              variant="outlined"
              onClick={handleClick}
              startIcon={<Add />}
              size="small"
              sx={{
                height: "48px",
                border: "2px solid #00B894",
                color: "#525455",
                fontWeight: "bold",
                textTransform: "capitalize",
                borderRadius: "8px",
              }}
            >
              Add a Service
            </Button>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => navigate("/add-service")}>
                <ListItemIcon>
                  <CheckCircleOutline />
                </ListItemIcon>
                <Typography>Choose Service</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOpen(true);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <Add />
                </ListItemIcon>
                <Typography>Create Service</Typography>
              </MenuItem>
            </Menu>
          </>
        )}
      </Box>

      <Box sx={{ p: "1.2rem" }}>
        {(Array.isArray(services) ? services : []).map((service) => (
          <Box
            key={service.id}
            sx={{
              background: "#FFFFFF",
              borderRadius: "12px",
              p: 2,
              mb: 2, // Margin between each Box
            }}
          >
            <Paper
              elevation={0}
              sx={{ p: 2, borderRadius: 2, cursor: "pointer" }}
            >
              {/* Header Section */}
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between", // This ensures items are spaced apart
                    width: "100%", // Ensure it takes the full width of its container
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        width: 40,
                        height: 40,
                        borderRadius: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mr: 1,
                      }}
                    >
                      <img
                        src={
                          service?.service_icon !== null
                            ? `/images/${service?.service_icon}`
                            : "/images/default.svg"
                        }
                        alt={`${service?.service_name} Icon`}
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </Box>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: 600 }}
                      color="#525455"
                    >
                      {service.service_name}
                    </Typography>
                    <Box sx={{ display: "flex", gap: 1, ml: 1 }}>
                      {service.category_type && (
                        <Chip
                          label={service.category_type}
                          size="small"
                          sx={{
                            bgcolor:
                              service?.category_type === "optional"
                                ? "#FAB1A0"
                                : "#F1A5E7",
                            color: "#000400",
                            fontWeight: 500,
                            borderRadius: "41px",
                          }}
                        />
                      )}
                      {service.category_name && (
                        <Chip
                          label={service.category_name}
                          size="small"
                          sx={{
                            bgcolor: "#E6E7EB",
                            color: "#595959",
                            fontWeight: 500,
                            borderRadius: 1,
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                  <Box>
                    <LaunchOutlined onClick={() => onSelectService(service)} />
                  </Box>
                </Box>
              </Box>

              {/* Storage and Toggle */}
              <Box sx={{ display: "flex", alignItems: "center", mt: 1, mb: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                  <StorageItemIcon sx={{ fontSize: 12 }} />
                  <Typography variant="body2">
                    {service.storage_item?.length > 0
                      ? service.storage_item?.length
                      : 0}{" "}
                    Storage Items
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", ml: 2 }}>
                  {service.is_active ? (
                    <CheckCircleOutline
                      sx={{
                        mr: 1,
                        color: "#00B894",
                      }}
                    />
                  ) : (
                    <CancelOutlined
                      sx={{
                        mr: 1,
                        color: "#FF4757",
                      }}
                    />
                  )}
                  <Typography variant="body2" color="#525455" sx={{ mr: 1 }}>
                    {service.is_active ? "Active" : "Inactive"}
                  </Typography>
                  <Switch
                    checked={service.is_active}
                    onChange={() => onToggle(service)}
                    disabled={loading} // Disable the switch while the API call is in progress
                  />
                </Box>
              </Box>
              <Divider />
              {/* Description */}
              <Box sx={{ mt: 1 }}>
                <Typography variant="body2" color="text.secondary">
                  {service.description}
                </Typography>
              </Box>
            </Paper>
          </Box>
        ))}
      </Box>

      {/* Footer */}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          pb: "15px",
        }}
      >
        <Typography
          sx={{ fontSize: "14px", lineHeight: "26px" }}
          color="#989898"
          textTransform="uppercase"
        >
          <DM_SANS>No More Results</DM_SANS>
        </Typography>
      </Box>
      {renderDrawer()}
    </Box>
  );
};

export const ServicesCards = () => {
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null); // State for selected service
  const [open, setOpen] = useState(false);
  const [serviceModalOpen, setServiceModalOpen] = useState(false);
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  console.log(selectedService, "==selected service");

  const breadcrumbItems = [
    {
      text: "Vendor Management",
      href: "/vendor-management",
      onClick: () => navigate("/vendor-management"),
    },
    {
      text: selectedService?.is_active ? "Active Service" : "Inactive Service",
    },
    {
      text: selectedService?.service_name,
    },
  ];

  // Toggle Service State
  const toggleService = async (service) => {
    const payload = {
      service_name: service.service_name, // Use the service's name
      description: service.description, // Use the service's description
      is_active: !service.is_active, // Toggle the active status
    };
    try {
      setLoading(true);
      const res = await updateService(service.service_id, payload);
      if (res?.status_code === 200) {
        const res = await getAllServices();
        setServices(res?.data?.services);
        showSuccessToast("Updated Successfully");
        setLoading(false);
      } else {
        // Handle error response from API if needed
        showErrorToast("Failed to update service");
        setLoading(false);
      }
    } catch (err) {
      showErrorToast(err.message || "An error occurred");
      setLoading(false);
    }
  };

  // Select Service and Show Details
  const selectService = (service) => {
    if (selectedService && selectedService.id === service.id) {
      setSelectedService(null); // If clicked on the same service, toggle off
    } else {
      setSelectedService(service); // Set the selected service to display details
    }
  };

  // Filter Active and Inactive Services
  const activeServices = (Array.isArray(services) ? services : []).filter(
    (service) => service?.is_active
  );
  const inactiveServices = (Array.isArray(services) ? services : []).filter(
    (service) => !service.is_active
  );

  // Styled components for table
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F9FAFB",
      color: "#000000",
      fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const IconContainer = styled(Box)({
    display: "flex",
    gap: "4px",
    "& button": {
      padding: "6px",
    },
  });

  const [selectedOption, setSelectedOption] = useState("");

  const [subOptions, setSubOptions] = useState([]);

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);

    // Update the second select options based on the selected option
    switch (value) {
      case "year":
        setSubOptions(Array.from({ length: 10 }, (_, i) => i + 1)); // Years 1-10
        break;
      case "month":
        setSubOptions(Array.from({ length: 12 }, (_, i) => i + 1)); // Months 1-12
        break;
      case "day":
        setSubOptions(Array.from({ length: 31 }, (_, i) => i + 1)); // Days 1-31
        break;
      case "hour":
        setSubOptions(Array.from({ length: 24 }, (_, i) => i + 1)); // Hours 1-24
        break;
      default:
        setSubOptions([]);
        break;
    }
  };

  const [selectedSubOption, setSelectedSubOption] = useState("");
  const [category, setCategory] = useState([]);

  const fetchServices = async () => {
    setLoading(true);
    try {
      const res = await getAllServices();
      if (res?.status_code === 200 || res?.status_code === 404) {
        setServices(res?.data?.services);
        setLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      showErrorToast(err);
      setLoading(false);
    }
  };

  const fetchAllCategories = async () => {
    try {
      setLoading(true);
      const res = await getAllCategory();
      if (res.status_code === 200 || res?.status_code === 404) {
        setCategory(res?.data);
        console.log(res?.data, "==res");
        setLoading(false);
      }
    } catch (err) {
      console.log(err, "error");
      showErrorToast(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchServices();
    fetchAllCategories();
  }, []);

  const categoryOptions = (Array.isArray(category) ? category : []).map(
    (category) => ({
      id: category.category_id,
      name: category.category_name,
    })
  );

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const [selectedStorageItem, setSelectedStorageItem] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  console.log(selectedStorageItem, "===storage item");
  const [serviceName, setServiceName] = useState("");
  const [serviceDescription, setServiceDescription] = useState("");
  const [activeService, setActiveService] = useState(true);

  console.log(selectedStorageItem, "====selectedStorageitem");

  const [errors, setErrors] = useState({
    name: "",
    description: "",
    selectedOption: "",
    selectedSubOption: "",
  });

  const validateForm = () => {
    const newErrors = {};

    const nameRegex = /^[^\s]+$/;
    if (!name.trim()) {
      newErrors.name = "Name is required";
    } else if (!nameRegex.test(name.trim())) {
      newErrors.name = "Input cannot contain spaces.";
    }

    if (!description.trim()) {
      newErrors.description = "Description is required";
    }

    if (!selectedOption) {
      newErrors.selectedOption = "Duration option is required";
    }

    if (!selectedSubOption) {
      newErrors.selectedSubOption = "Duration value is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  const handleSubmit = async (event) => {
    if (!validateForm()) {
      return;
    }
    event.preventDefault();
    const data = {
      storage_item: [
        {
          name,
          description,
          duration: `${selectedSubOption} ${selectedOption}`,
        },
      ],
    };

    const updatepayload = {
      storage_item: [
        {
          id: selectedStorageItem?.id,
          name,
          description,
          duration: `${selectedSubOption} ${selectedOption}`, // Format duration as "X Option"
        },
      ],
    };

    try {
      if (isEditMode) {
        const response = await updateStorageItem(
          updatepayload,
          selectedService?.service_id
        );
        if (response.status_code === 200) {
          const res = await getAllServices();
          setServices(res?.data?.services);
          setOpen(false);
          setIsEditMode(false);
          // You can show success messages or reset the form here
        } else if (response?.status_code === 400) {
          setOpen(false);
          showErrorToast(response?.message);
          setLoading(false);
        } else {
          setOpen(false);
          console.error("API Request Failed:", response.statusText);
          // You can show error messages to the user
        }
      } else {
        setLoading(true);
        const response = await addStorageItem(
          data,
          selectedService?.service_id
        );
        if (response.status_code === 200 || response.status_code === 404) {
          setName("");
          setDescription("");
          setSelectedOption("");
          setSubOptions([]);
          setOpen(false);
          setTimeout(async () => {
            setSelectedService((prevState) => ({
              ...prevState,
              storage_item: response.data.storage_items, // Update storage items
            }));
            setLoading(false);
          }, 600);
        } else if (response?.status_code === 400) {
          setOpen(false);
          showErrorToast(response?.message);
          setLoading(false);
        } else {
          setOpen(false);
          console.error("API Request Failed:", response.statusText);
        }
      }
    } catch (error) {
      setOpen(false);
      console.error("Error during API request:", error);
      // Handle any network errors
    }
  };

  const handleAddStorageItem = async (item) => {
    const data = {
      storage_item: [item],
    };
    try {
      setLoading(true);
      const response = await addStorageItem(data, selectedService?.service_id);
      if (response.status_code === 200 || response.status_code === 404) {
        setName("");
        setDescription("");
        setSelectedOption("");
        setSubOptions([]);
        setOpen(false);
        setTimeout(async () => {
          const res = await getAllServices();
          setServices(res?.data?.services);
          setSelectedService(res?.data?.services?.[0]);
          setLoading(false);
        }, 600);
      } else if (response?.status_code === 400) {
        setOpen(false);
        showErrorToast(response?.message);
        setLoading(false);
      } else {
        setOpen(false);
        console.error("API Request Failed:", response.statusText);
      }
    } catch (error) {
      setOpen(false);
      console.error("Error during API request:", error);
      // Handle any network errors
    }
  };

  const handleDeleteClick = async (item) => {
    setSelectedStorageItem(item);
    if (selectedStorageItem !== null) {
      handleDelete();
    }
  };

  const handleDelete = async () => {
    setLoading(false);
    try {
      setLoading(true);
      const res = await deleteStorageItem(
        selectedStorageItem?.name,
        selectedService?.service_id
      );
      if (res?.status_code === 200) {
        setLoading(false);
        showSuccessToast("Deleted Successfully!");
        const res = await getAllServices();
        setServices(res?.data?.services);
      } else if (res?.status_code === 400) {
        showErrorToast(res?.message);
        setLoading(false);
      } else {
        showErrorToast("Failed to delete storage item");
        setLoading(false);
      }
    } catch (err) {
      showErrorToast(err);
      setLoading(false);
    }
  };

  const [isEditMode, setIsEditMode] = useState(false);

  const openEditDrawer = (storageItem) => {
    console.log(storageItem.duration, "storageItem.duration");
    setIsEditMode(true);
    setName(storageItem.name);
    setDescription(storageItem.description);
    const [numericDuration, unitDuration] = storageItem.duration.split(" ");
    console.log(
      numericDuration,
      "numericDuration",
      unitDuration,
      "unitDuration"
    );
    setSelectedOption(unitDuration);
    setSelectedSubOption(numericDuration);
    setOpen(true);
  };

  useEffect(() => {
    if (selectedService?.service_id) {
      const fetchServiceData = async () => {
        try {
          const response = await getServiceById(selectedService?.service_id);
          const data = await response.data;
          setServiceName(data.service_name);
          const selectedCategoryObj = categoryOptions.find(
            (category) => category.id === data.category_id
          );
          if (selectedCategoryObj) {
            setSelectedCategory(selectedCategoryObj.id);
          }
          setServiceDescription(data.description);
          setActiveService(data.is_active);
        } catch (error) {
          console.error("Error fetching service data:", error);
        }
      };

      fetchServiceData();
    }
    // eslint-disable-next-line
  }, [selectedService?.service_id]);

  const handleServiceUpdate = async () => {
    const payload = {
      service_name: serviceName,
      category_id: selectedCategory, // Assuming `selectedCategory` holds the category ID
      description: serviceDescription,
      is_active: activeService,
    };
    try {
      setLoading(true);
      const res = await updateService(selectedService?.service_id, payload);
      if (res?.status_code === 200) {
        showSuccessToast("Service updated successfully");
        const response = await getAllServices();
        setSelectedService(res?.data?.services);
        setServices(response?.data?.services);
        setServiceModalOpen(false);
      } else {
        showErrorToast("Failed to update service");
      }
    } catch (err) {
      showErrorToast(
        err?.message || "An error occurred while updating the service"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleServiceDelete = async () => {
    try {
      setLoading(true);
      const res = await deleteService(selectedService?.service_id);
      if (res?.status_code === 200) {
        setServices((prevServices) =>
          prevServices.filter(
            (service) => service.service_id !== res.data.service_id
          )
        );
        setLoading(false);
        showSuccessToast("Deleted Successfully");
        setServiceModalOpen(false);
        setSelectedService(null);
      }
    } catch (err) {
      showErrorToast(err);
      setServiceModalOpen(false);
      setLoading(false);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 0 }}>
      {loading ? (
        <Loader /> // Replace with your actual Loader component
      ) : (
        <>
          {!selectedService && (
            <ServiceList
              title="Active Services"
              subtitle="Data Processing Services (DPS) are services that handle, manage, and process data on behalf of an organisation."
              services={activeServices}
              onToggle={toggleService}
              onSelectService={selectService}
              setServices={setServices}
              setSelectedService={setSelectedService}
            />
          )}
          {!selectedService && (
            <ServiceList
              title="Inactive Services"
              subtitle="Data Processing Services (DPS) are services that handle, manage, and process data on behalf of an organisation."
              services={inactiveServices}
              onToggle={toggleService}
              onSelectService={selectService}
            />
          )}

          {selectedService && (
            <>
              <Box sx={{ mb: 2 }}>
                <CustomBreadcrumb
                  items={breadcrumbItems}
                  onBackClick={async () => {
                    setSelectedService(null);
                    const res = await getAllServices();
                    setServices(res?.data?.services);
                  }}
                />
              </Box>

              <Box>
                {/* Header Section */}
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
                    <Box
                      sx={{
                        width: 40,
                        height: 40,
                        borderRadius: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mr: 1,
                      }}
                    >
                      <img
                        src={
                          selectedService?.service_icon !== null
                            ? `/images/${selectedService?.service_icon}`
                            : "/images/default.svg"
                        }
                        alt={`${selectedService?.service_name} Icon`}
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </Box>
                    <Typography variant="h6" fontWeight="bold" color="#525455">
                      {selectedService?.service_name}
                    </Typography>
                    <Chip
                      variant="filled"
                      label={selectedService?.category_name}
                      sx={{
                        fontSize: "15px",
                        fontWeight: 400,
                        background: "#E6E7EB",
                        borderRadius: "4px",
                        mr: 2,
                        ml: 2,
                      }}
                    />
                    <Typography
                      component="span"
                      sx={{
                        fontSize: "14px",
                        color: "#5f6368",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {selectedService.storage_item?.length > 0
                        ? selectedService.storage_item?.length
                        : 0}{" "}
                      Storage Items
                    </Typography>
                  </Box>
                  <ButtonCommon onClick={() => setServiceModalOpen(true)}>
                    Edit
                  </ButtonCommon>
                </Box>
                {/* Description */}
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#5f6368",
                    mb: 2,
                    maxWidth: "800px",
                  }}
                >
                  {selectedService?.description}
                </Typography>
                <Divider />
                {/* Service Storage Items Section */}
                <Box sx={{ mb: 4, mt: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: 700,
                        color: "#525455",
                      }}
                    >
                      Service Storage Items
                    </Typography>
                    <ButtonCommon
                      onClick={() => {
                        setIsEditMode(false);
                        setName("");
                        setDescription("");
                        setSelectedOption("");
                        setSelectedSubOption("");
                        setOpen(true);
                      }}
                    >
                      Add
                    </ButtonCommon>
                  </Box>

                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#989898",
                      mb: 2,
                    }}
                  >
                    Storage items that belong to this service.
                  </Typography>

                  <TableContainer
                    component={Paper}
                    elevation={0}
                    sx={{
                      border: "1px solid #dadce0",
                      borderRadius: "8px",
                      mb: 4,
                    }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell className="header" align="center">
                            Name
                          </StyledTableCell>
                          <StyledTableCell className="header" align="center">
                            Description
                          </StyledTableCell>
                          <StyledTableCell className="header" align="center">
                            Duration
                          </StyledTableCell>
                          <StyledTableCell className="header" align="center">
                            Action
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedService?.storage_item?.length > 0 ? (
                          (Array.isArray(selectedService?.storage_item)
                            ? selectedService?.storage_item
                            : []
                          )?.map((item, index) => (
                            <TableRow key={index}>
                              <StyledTableCell align="center">
                                {item?.name}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {item?.description ? item?.description : "-"}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {item?.duration ? item?.duration : "-"}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <IconContainer justifyContent="center">
                                  <IconButton
                                    size="small"
                                    sx={{
                                      color: "#5f6368",
                                      "&:hover": {
                                        backgroundColor: "#f8f9fa",
                                      },
                                    }}
                                  >
                                    <Eye
                                      size={16}
                                      onClick={() => {
                                        openEditDrawer(item);
                                        setSelectedStorageItem(item);
                                      }}
                                    />
                                  </IconButton>
                                  <IconButton
                                    size="small"
                                    sx={{
                                      color: "#5f6368",
                                      "&:hover": {
                                        backgroundColor: "#f8f9fa",
                                      },
                                    }}
                                  >
                                    <DeleteOutline
                                      size={16}
                                      onClick={() => handleDeleteClick(item)}
                                    />
                                  </IconButton>
                                </IconContainer>
                              </StyledTableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <StyledTableCell colSpan={4} align="center">
                              <Typography variant="body2" color="textSecondary">
                                No storage items available.
                              </Typography>
                            </StyledTableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  {/* Uncategorised Section */}
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 700,
                        color: "#3c4043",
                        mb: 1,
                      }}
                    >
                      Uncategorised
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#989898",
                      }}
                    >
                      Storage items not assigned to any category or service.
                    </Typography>
                    <TableContainer
                      component={Paper}
                      elevation={0}
                      sx={{
                        border: "1px solid #dadce0",
                        borderRadius: "8px",
                        mb: 4,
                        mt: 2,
                      }}
                    >
                      <Table>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell className="header" align="center">
                              Name
                            </StyledTableCell>
                            <StyledTableCell className="header" align="center">
                              Description
                            </StyledTableCell>
                            <StyledTableCell className="header" align="center">
                              Duration
                            </StyledTableCell>
                            <StyledTableCell className="header" align="center">
                              Action
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedService?.uncategorized?.length > 0 ? (
                            (Array.isArray(selectedService?.uncategorized)
                              ? selectedService?.uncategorized
                              : []
                            )?.map((item, index) => (
                              <TableRow key={index}>
                                <StyledTableCell align="center">
                                  {item?.name}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {item?.description ? item?.description : "-"}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {item?.duration ? item?.duration : "-"}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <IconContainer justifyContent="center">
                                    <IconButton
                                      size="small"
                                      sx={{
                                        color: "#5f6368",
                                        "&:hover": {
                                          backgroundColor: "#f8f9fa",
                                        },
                                      }}
                                    >
                                      <Add
                                        size={16}
                                        onClick={() => {
                                          handleAddStorageItem(item);
                                        }}
                                      />
                                    </IconButton>
                                    <IconButton
                                      size="small"
                                      sx={{
                                        color: "#5f6368",
                                        "&:hover": {
                                          backgroundColor: "#f8f9fa",
                                        },
                                      }}
                                    >
                                      <DeleteOutline
                                        size={16}
                                        onClick={() => handleDeleteClick(item)}
                                      />
                                    </IconButton>
                                  </IconContainer>
                                </StyledTableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <StyledTableCell colSpan={4} align="center">
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  No storage items available.
                                </Typography>
                              </StyledTableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </Box>
            </>
          )}
          <CommonDrawer
            open={open}
            onClose={() => setOpen(false)}
            title={isEditMode ? "Edit Storage Item" : "Add Storage Item"} // Change title based on mode
            subTitle={
              isEditMode
                ? "Edit this storage item. Once you save changes, they will be shown to your website users if they opt for managing cookie preferences."
                : "Add new storage item to your service. This will be shown to your website users if they opt for managing cookie preferences."
            }
            primaryButton={{
              label: isEditMode ? "Update" : "Create", // Change button text based on mode
              onClick: handleSubmit,
              sx: { px: 2 },
            }}
            cancelButtonLabel="Cancel"
            drawerWidth={400}
          >
            <Box component="form" onSubmit={handleSubmit}>
              <Typography variant="body1" fontWeight="600" color="#525455">
                Default Settings
              </Typography>

              {/* Category Name Field */}
              <InputLabel shrink htmlFor="name" required sx={{ mt: 2 }}>
                Name
              </InputLabel>
              <TextField
                fullWidth
                id="name"
                placeholder="Name"
                variant="outlined"
                size="small"
                sx={{ mb: 2 }}
                value={name}
                onChange={(e) => setName(e.target.value)} // Handle change of name
                error={Boolean(errors.name)}
                helperText={errors.name}
              />

              {/* Category Description Field */}
              <InputLabel shrink htmlFor="description" required>
                Description of Service
              </InputLabel>
              <TextField
                fullWidth
                id="description"
                placeholder="Description"
                multiline
                rows={3}
                variant="outlined"
                size="small"
                sx={{ mb: 2 }}
                value={description}
                onChange={(e) => setDescription(e.target.value)} // Handle change of description
                error={Boolean(errors.description)}
                helperText={errors.description}
              />

              {/* Duration Fields */}
              <InputLabel shrink htmlFor="duration" required>
                Duration
              </InputLabel>
              <Box
                sx={{ display: "flex", gap: 2, alignItems: "center", mt: 2 }}
              >
                {/* First Select Dropdown */}
                <FormControl fullWidth>
                  <InputLabel id="select-option-label">
                    Select Option
                  </InputLabel>
                  <Select
                    labelId="select-option-label"
                    value={selectedOption}
                    onChange={handleSelectChange}
                    label="Select Option"
                  >
                    <MenuItem value="year">Year</MenuItem>
                    <MenuItem value="month">Month</MenuItem>
                    <MenuItem value="day">Day</MenuItem>
                    <MenuItem value="hour">Hour</MenuItem>
                  </Select>
                </FormControl>
                {errors.selectedOption && (
                  <Typography variant="caption" color="error" sx={{ mt: 0.5 }}>
                    {errors.selectedOption}
                  </Typography>
                )}

                {/* Second Select Dropdown */}
                {selectedOption && (
                  <FormControl fullWidth>
                    <InputLabel id="select-sub-option-label">
                      Select {selectedOption}
                    </InputLabel>
                    <Select
                      labelId="select-sub-option-label"
                      value={selectedSubOption}
                      onChange={(e) => setSelectedSubOption(e.target.value)}
                      label={`Select ${selectedOption}`}
                    >
                      {subOptions.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.selectedSubOption && (
                      <Typography
                        variant="caption"
                        color="error"
                        sx={{ mt: 0.5 }}
                      >
                        {errors.selectedSubOption}
                      </Typography>
                    )}
                  </FormControl>
                )}
              </Box>
            </Box>
          </CommonDrawer>

          <CommonDrawer
            open={serviceModalOpen}
            onClose={() => setServiceModalOpen(false)}
            title="Edit Service"
            subTitle={
              "Sort cookies into services, helping users personalize their cookie choices."
            }
            primaryButton={{
              label: "Update",
              onClick: handleServiceUpdate,
              sx: { px: 2 },
            }}
            cancelButtonLabel="Delete"
            drawerWidth={400}
            onDelete={handleServiceDelete}
          >
            <Box component="form" onSubmit={handleSubmit}>
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: 600, color: "#525455", mb: 2 }}
              >
                Default Settings
              </Typography>

              <Box sx={{ mb: 3 }}>
                <InputLabel
                  required
                  sx={{ color: "#525455", mb: 1, fontSize: "0.875rem" }}
                >
                  Name
                </InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Google Analytics"
                  value={serviceName}
                  onChange={(e) => setServiceName(e.target.value)}
                  sx={{ mb: 0.5 }}
                />
                <Typography variant="caption" color="text.secondary">
                  You can add * to pattern match & capture all items that follow
                  this pattern
                </Typography>
              </Box>

              <Box sx={{ mb: 3 }}>
                <InputLabel
                  required
                  sx={{ color: "#525455", mb: 1, fontSize: "0.875rem" }}
                >
                  Select Category
                </InputLabel>
                <Select
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  fullWidth
                  size="small"
                >
                  {categoryOptions.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>

              <Box sx={{ mb: 4 }}>
                <InputLabel
                  required
                  sx={{ color: "#525455", mb: 1, fontSize: "0.875rem" }}
                >
                  Description of Service
                </InputLabel>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  value={serviceDescription}
                  onChange={(e) => setServiceDescription(e.target.value)}
                  placeholder="We use Google Analytics to collect data..."
                />
              </Box>

              <Box sx={{ mb: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 1,
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: 600, color: "#525455" }}
                  >
                    Service Active
                  </Typography>
                  <Switch
                    color="primary"
                    checked={activeService}
                    onChange={(e) => setActiveService(e.target.checked)}
                  />
                </Box>
                <Typography variant="body2" color="text.secondary">
                  If disabled, this service will not be shown to any visitors.
                </Typography>
              </Box>

              {/* <Box>
            <Typography
              variant="subtitle2"
              sx={{ fontWeight: 600, color: "#525455", mb: 2 }}
            >
              Translations
            </Typography>
            <InputLabel sx={{ color: "#525455", mb: 1, fontSize: "0.875rem" }}>
              Select Language
            </InputLabel>
            <Select
              value={translations}
              onChange={(e) => setTranslations(e.target.value)}
              fullWidth
              size="small"
            >
              <MenuItem value="English">English</MenuItem>
            </Select>
          </Box> */}
            </Box>
          </CommonDrawer>
        </>
      )}
    </Box>
  );
};
