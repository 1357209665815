// App.js
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/layout/MainLayout";
import Dashboard from "./pages/Dashboard/dashboard";
import WebsiteScan from "./pages/WebsiteScan";
import LoginPage from "./pages/LogIn";
import SignupPage from "./pages/SignUp";
import ForgotPassword from "./pages/ForgotPassword";
import ProtectedRoute from "./ProtectedRoute";
import VendorManagement from "./pages/VendorManagement/VendorManagement";
import BannerDesigner from "./pages/Banner/BannerDesigner";
import BannerPage from "./pages/Banner/BannerPage";
import ConsentLog from "./pages/ConsentLog";
import Settings from "./pages/Settings/Settings";
import HelpSupport from "./pages/HelpSupport";
import AddService from "./pages/VendorManagement/AddService";
import ChangePassword from "./pages/ChangePassword";
import AddWebAndScanPage from "./pages/AddWebAndScanPage";
import Profile from "./pages/Profile";
import ConsentDetails from "./pages/ConsentDetails/ConsentDetails";
import InstallPlugin from "./pages/InstallPlugin";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
            <Layout />
            </ProtectedRoute>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/website-scan" element={<WebsiteScan />} />
          <Route path="/vendor-management" element={<VendorManagement />} />
          <Route path="/banner" element={<BannerDesigner />} />
          <Route path="/banner/create" element={<BannerPage />} />
          <Route path="/banner/:bannerId" element={<BannerPage />} />
          <Route path="/add-service" element={<AddService />} />
          <Route path="/consent-log" element={<ConsentLog />} />
          <Route path="/consent-details/:id" element={<ConsentDetails />} />
          <Route path="/install-plugin" element={<InstallPlugin />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/help" element={<HelpSupport />} />
          <Route path="/profile" element={<Profile />} />
        </Route>
        <Route path="*" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/add-website" element={<AddWebAndScanPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
