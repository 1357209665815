import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

// Styled component for the ribbon container
const RibbonContainer = styled(Box)(({ theme }) => ({
  position: "fixed",
  bottom: "0",
  right: "0",
  width: "clamp(120px, 20vw, 200px)", 
  height: "clamp(120px, 20vw, 200px)", 
  overflow: "hidden",
  zIndex: theme.zIndex.drawer + 1,
  pointerEvents: "none",
  [theme.breakpoints.down("sm")]: {
    width: "120px",
    height: "120px",
  },
}));

// Styled component for the ribbon itself
const Ribbon = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "68%",
  right: "-30%",
  transform: "rotate(-38deg)",
  backgroundColor: "#00B894",
  padding: "clamp(6px, 1.5vw, 8px) clamp(15px, 3vw, 21px)", 
  width: "120%", 
  textAlign: "center",
  boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
  [theme.breakpoints.down("sm")]: {
    top: "25%",
    right: "-35%",
    padding: "6px 15px",
  },
}));

const PublicBetaRibbon = () => {
  return (
    <RibbonContainer>
      <Ribbon>
        <Typography
          variant="subtitle2"
          sx={{
            color: "white",
            fontWeight: "bold",
            letterSpacing: "1px",
            fontSize: "clamp(0.75rem, 1.5vw, 0.875rem)", // Responsive font size
            whiteSpace: "nowrap",
          }}
        >
          PUBLIC BETA
        </Typography>
      </Ribbon>
    </RibbonContainer>
  );
};

export default PublicBetaRibbon;
