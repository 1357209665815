import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Chip,
  Divider,
  Grid,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Switch,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Add,
  AddCircleOutline,
  LaunchOutlined,
  RefreshOutlined,
} from "@mui/icons-material";
import CommonSubHeader from "../../CommonComponent/CommonSubHeader";
import ButtonCommon from "../../CommonComponent/ButtonCommon";
import { BriefcaseBlackIcon, StorageItemIcon } from "../../assets/svg";
import CommonDrawer from "../../CommonComponent/CommonDrawer";
import CustomBreadcrumb from "../../CommonComponent/CommonBreadcrumb";
import { useNavigate } from "react-router-dom";
import {
  createCategory,
  deleteCategory,
  getAllCategory,
  updateCategory,
} from "../../api/category";
import { showErrorToast, showSuccessToast } from "../../toastUtils";
import { updateService } from "../../api/servies";

// Custom Font Style
const DM_SANS = styled("span")({
  fontFamily: "DM Sans",
});

const CategoryCards = () => {
  const [open, setOpen] = useState(false);
  const [categoryType, setCategoryType] = useState();
  const [language, setLanguage] = useState("English");
  const [showDetails, setShowDetails] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryData, setCategoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });

  // Error state
  const [errors, setErrors] = useState({
    name: "",
    description: "",
    categoryType: "",
  });

  const validateForm = () => {
    const newErrors = {
      name: "",
      description: "",
      categoryType: "",
    };

    let isValid = true;

    // Validate name
    if (!formData.name.trim()) {
      newErrors.name = "Category name is required";
      isValid = false;
    }

    // Validate description
    if (!formData.description.trim()) {
      newErrors.description = "Description is required";
      isValid = false;
    }

    // Validate category type
    if (!categoryType) {
      newErrors.categoryType = "Please select a category type";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();

    if (validateForm()) {
      const payload = {
        category_name: formData.name,
        description: formData.description,
        category_type: categoryType,
      };

      try {
        setLoading(true);
        if (selectedCategory?.category_id) {
          const res = await updateCategory(
            selectedCategory?.category_id,
            payload
          );
          if (res?.status_code === 200) {
            setTimeout(() => {
              setSelectedCategory(res?.data);
              setCategoryData((prevCategories) => {
                // Find and replace the updated category
                return prevCategories.map((category) =>
                  category.category_id === selectedCategory?.category_id
                    ? { ...category, ...res?.data } // Replace the old category with the updated data
                    : category
                );
              });
              showSuccessToast("Category updated successfully");
              setFormData({});
            }, 200);
            setLoading(false);
          } else if (res?.status_code === 400) {
            showErrorToast(res?.message);
            setLoading(true);
          }
        } else {
          setLoading(true);
          const res = await createCategory(payload);
          if (res?.status_code === 201) {
            setTimeout(async () => {
              const res = await getAllCategory();
              setCategoryData(res?.data);
              showSuccessToast("Category created successfully");
              setFormData({});
            }, 500);
            setLoading(false);
          } else if (res?.status_code === 400) {
            showErrorToast(res?.message);
            setLoading(true);
          }
        }
      } catch (error) {
        setLoading(false);
        showErrorToast("Error processing category:", error);
      } finally {
        setFormData({
          name: "",
          description: "",
        });
        setSelectedCategory(null);
        setLoading(false);
        setOpen(false);
      }
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const res = await deleteCategory(selectedCategory.category_id);
      if (res?.status_code === 200) {
        setShowDetails(false);
        await getAllCategory();
        const updatedCategoryData = categoryData?.filter(
          (item) => item?.category_id !== selectedCategory?.category_id
        );
        setCategoryData(updatedCategoryData);
        setOpen(false);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error deleting category:", error);
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
    if (errors[id]) {
      setErrors((prev) => ({
        ...prev,
        [id]: "",
      }));
    }
  };

  const fetchAllCategories = async () => {
    try {
      setLoading(true);
      const res = await getAllCategory();
      if (res?.status_code === 200 || res?.status_code === 404) {
        setCategoryData(res?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchAllCategories();
  }, []);

  console.log(categoryData, "===> category data");

  const categories = categoryData || [];

  const activeCategories = (
    Array.isArray(categories) ? categories : []
  )?.filter((cat) => cat?.services && cat?.services.length > 0);

  const inactiveCategories = (
    Array.isArray(categories) ? categories : []
  )?.filter(
    (cat) => !cat?.services || Object?.keys(cat.services || {}).length === 0
  );

  const breadcrumbItems = [
    {
      text: "Vendor Management",
      href: "/vendor-management",
      onClick: () => navigate("/vendor-management"),
    },
    {
      text: `${
        selectedCategory?.services?.length >= 1
          ? "Active Category"
          : "Inactive Category"
      }`,
    },
    {
      text: selectedCategory?.category_name || "",
    },
  ];

  const handleBackClick = async (e) => {
    e.preventDefault();
    setShowDetails(false);
    setSelectedCategory(null);
    const res = await getAllCategory();
    setCategoryData(res?.data);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setFormData({
      name: category.category_name || "",
      description: category.description || "",
    });
    setCategoryType(category.category_type);
    setShowDetails(true);
  };

  console.log(selectedCategory, "==== selectorycategory");

  const handleChange = async (service) => {
    // Create the payload with the necessary fields
    const payload = {
      service_name: service.service_name, // Use the service's name
      description: service.description, // Use the service's description
      is_active: !service.is_active, // Toggle the active status
    };

    try {
      setLoading(true);
      const res = await updateService(service.service_id, payload);
      if (res?.status_code === 200) {
        showSuccessToast("Updated Successfully");
        setSelectedCategory((prevState) => {
          const updatedServices = prevState.services.map((s) =>
            s.service_id === service.service_id
              ? { ...s, is_active: res?.data?.is_active }
              : s
          );
          return {
            ...prevState,
            services: updatedServices,
          };
        });
        await getAllCategory();
        setLoading(false);
      } else {
        showErrorToast("Failed to update service");
        setLoading(false);
      }
    } catch (err) {
      showErrorToast(err.message || "An error occurred");
      setLoading(false);
    }
  };

  const renderCategoryCard = (category) => (
    <Box
      key={category.category_id}
      sx={{
        background: "#FFFFFF",
        borderRadius: "12px",
        mb: "1rem",
        p: 2,
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "0.5rem",
        }}
      >
        <Box display="flex" alignItems="center" gap="0.5rem">
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 600 }}
            color="#525455"
          >
            {category.category_name}
          </Typography>
          <Chip
            label={category.category_type}
            sx={{
              backgroundColor:
                category.category_type === "Required" ? "#F1A5E7" : "#FAB1A0",
              color: "#000400",
              fontSize: "12px",
              height: "20px",
            }}
            icon={
              category.category_type === "Required" ? (
                <AddCircleOutline sx={{ fontSize: "14px" }} />
              ) : (
                <RefreshOutlined sx={{ fontSize: "14px" }} />
              )
            }
          />
          <Chip
            label={category.category_name}
            size="small"
            sx={{
              bgcolor: "#E6E7EB",
              color: "#000400",
              fontWeight: 500,
              borderRadius: 1,
            }}
          />
        </Box>
        <LaunchOutlined onClick={() => handleCategoryClick(category)} />
      </Box>

      <Box display="flex" flexDirection="row" gap="5px">
        <BriefcaseBlackIcon />
        <Typography
          variant="body2"
          color="#6b7280"
          sx={{ mb: "0.5rem", fontSize: "14px" }}
        >
          {category.services?.length > 0 ? category.services.length : 0}{" "}
          {category.services?.length === 1 ? "Service" : "Services"}
        </Typography>
      </Box>
      <Divider />
      <Typography
        variant="body2"
        color="#6b7280"
        sx={{ fontSize: "14px", mt: 2 }}
      >
        {category.description}
      </Typography>
    </Box>
  );

  const renderCategoryDetails = () => (
    <Box>
      <Box sx={{ mb: 4 }}>
        <CustomBreadcrumb
          items={breadcrumbItems}
          onBackClick={handleBackClick}
        />
      </Box>

      <Box
        sx={{
          mb: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography variant="h6" color="#525455" fontWeight="700">
            {selectedCategory?.category_name}
          </Typography>
          <Chip
            label={selectedCategory?.category_type}
            size="small"
            sx={{ backgroundColor: "#F1A5E7" }}
            icon={<AddCircleOutline />}
          />
          <Chip
            label={selectedCategory?.category_name}
            variant="filled"
            size="small"
            sx={{ backgroundColor: "#E6E7EB", borderRadius: "4px" }}
          />
        </Box>
        <ButtonCommon onClick={() => setOpen(true)}>Edit</ButtonCommon>
      </Box>

      <Box display="flex" flexDirection="row" gap="5px">
        <BriefcaseBlackIcon />
        <Typography variant="body2" color="#525455" sx={{ mb: 2 }}>
          {selectedCategory?.services?.length || 0} Service
          {selectedCategory?.services?.length !== 1 ? "s" : ""}
        </Typography>
      </Box>

      <Typography variant="body2" color="#989898" sx={{ mb: 3 }}>
        {selectedCategory?.description}
      </Typography>

      <Divider sx={{ mb: 3 }} />

      <Box sx={{ mb: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography variant="h6" color="#525455" fontWeight="700">
            Services
          </Typography>
          <ButtonCommon icon={<Add />} onClick={() => navigate("/add-service")}>
            Add a Service
          </ButtonCommon>
        </Box>

        <Typography
          variant="subtitle1"
          sx={{ mb: 1 }}
          color="#525455"
          fontWeight="700"
        >
          Active Services
        </Typography>
        <Typography variant="body2" color="#989898" sx={{ mb: 3 }}>
          Data Processing Services (DPS) are services that handle, manage, and
          process data on behalf of an organisation.
        </Typography>

        {selectedCategory?.services && selectedCategory.services.length > 0 ? (
          (Array.isArray(selectedCategory.services)
            ? selectedCategory.services
            : []
          ).map((service) => (
            <Paper
              key={service.service_id}
              sx={{
                p: 3,
                background: "#F9FAFB",
                border: "1px solid #D9D9D9",
                borderRadius: "12px",
                mb: 2,
              }}
              variant="outlined"
            >
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}
              >
                <Box
                  sx={{
                    width: 40,
                    height: 40,
                    borderRadius: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mr: 1,
                  }}
                >
                  <img
                    src={
                      service?.service_icon !== null
                        ? `/images/${service?.service_icon}`
                        : "/images/default.svg"
                    }
                    alt={`${service?.service_name} Icon`}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
                <Typography variant="subtitle1">
                  {service.service_name}
                </Typography>
                <Chip
                  label={selectedCategory.category_type}
                  size="small"
                  sx={{ backgroundColor: "#F1A5E7" }}
                  icon={<AddCircleOutline />}
                />
                <Chip
                  label={selectedCategory.category_name}
                  variant="filled"
                  size="small"
                  sx={{ backgroundColor: "#E6E7EB", borderRadius: "4px" }}
                />
              </Box>

              <Box
                sx={{ display: "flex", alignItems: "center", gap: 2, mb: 1 }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                  <StorageItemIcon />
                  <Typography variant="body2" color="#525455">
                    {service.storage_item?.length || 0} Storage Items
                  </Typography>
                </Box>
                <Typography variant="body2" color="#525455">
                  {service.is_active ? "Active" : "Inactive"}
                </Typography>
                <Switch
                  checked={service.is_active} // Dynamically bind to the service's active status
                  onChange={() => handleChange(service)} // Trigger the API call when toggled
                />{" "}
              </Box>
              <Divider sx={{ mb: 2 }} />
              <Typography variant="body2" color="text.secondary">
                {service.description}
              </Typography>
            </Paper>
          ))
        ) : (
          <Typography textAlign="center" mt={2} fontWeight="bold">
            No services have been added yet.
          </Typography>
        )}
      </Box>
    </Box>
  );

  const renderDrawer = () => (
    <CommonDrawer
      open={open}
      onClose={() => setOpen(false)}
      title={selectedCategory ? "Edit Category" : "New Category"}
      subTitle="Sort cookies with specific jobs, helping users personalize their cookie choices."
      primaryButton={{
        label: selectedCategory ? "Update" : "Create",
        sx: { px: 4 },
        onClick: handleSubmit,
      }}
      cancelButtonLabel={selectedCategory ? "Delete" : "Cancel"}
      drawerWidth={400}
      onDelete={handleDelete}
      onSubmit={handleSubmit} // Pass handleSubmit as the onSubmit prop
    >
      <Box component="form">
        <Typography variant="body" fontWeight="600" color="#525455">
          Default Settings
        </Typography>

        {/* Category Name Field */}
        <InputLabel shrink htmlFor="name" required sx={{ mt: 2 }}>
          Category Name
        </InputLabel>
        <TextField
          fullWidth
          id="name"
          placeholder="Name"
          variant="outlined"
          size="small"
          value={formData.name}
          onChange={handleInputChange}
          error={!!errors.name}
          helperText={errors.name}
          sx={{ mb: 2 }}
        />

        {/* Category Description Field */}
        <InputLabel shrink htmlFor="description" required>
          Description
        </InputLabel>
        <TextField
          fullWidth
          id="description"
          placeholder="Description"
          multiline
          rows={3}
          variant="outlined"
          size="small"
          value={formData.description}
          onChange={handleInputChange}
          error={!!errors.description}
          helperText={errors.description}
          sx={{ mb: 2 }}
        />

        {/* Category Type Radio Buttons */}
        <InputLabel shrink htmlFor="category-type" required>
          Category Type
        </InputLabel>
        <RadioGroup
          value={categoryType}
          onChange={(e) => {
            setCategoryType(e.target.value);
            setErrors((prev) => ({ ...prev, categoryType: "" }));
          }}
        >
          <Box
            sx={{
              border: "1px solid",
              borderColor:
                categoryType === "Required" ? "primary.main" : "grey.300",
              borderRadius: 1,
              p: 1.5,
              mb: 1,
            }}
          >
            <FormControlLabel
              value="Required"
              control={<Radio />}
              label={
                <Box>
                  <Typography fontWeight="bold">Required</Typography>
                  <Typography variant="caption" color="textSecondary">
                    Users must accept cookies in this category.
                  </Typography>
                </Box>
              }
            />
          </Box>
          <Box
            sx={{
              border: "1px solid",
              borderColor:
                categoryType === "Optional" ? "primary.main" : "grey.300",
              borderRadius: 1,
              p: 1.5,
            }}
          >
            <FormControlLabel
              value="Optional"
              control={<Radio />}
              label={
                <Box>
                  <Typography fontWeight="bold">Optional</Typography>
                  <Typography variant="caption" color="textSecondary">
                    Users can choose to reject cookies in this category.
                  </Typography>
                </Box>
              }
            />
          </Box>
        </RadioGroup>
        {errors.categoryType && (
          <Typography color="error" className="mb-2">
            {errors.categoryType}
          </Typography>
        )}

        {/* Translations Section */}
        <Typography
          variant="subtitle1"
          mt={2}
          sx={{ mb: 2 }}
          fontWeight="600"
          color="#525455"
        >
          Translations
        </Typography>
        <Typography variant="caption" sx={{ mb: 1 }}>
          Select Language
        </Typography>
        <FormControl fullWidth variant="outlined" size="small">
          <Select
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
          >
            <MenuItem value="English">English</MenuItem>
            <MenuItem value="Spanish">Spanish</MenuItem>
            <MenuItem value="French">French</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </CommonDrawer>
  );

  return (
    <Box>
      {!showDetails ? (
        loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 0 }}>
            {/* Active Categories Section */}
            <Grid
              container
              sx={{
                background: "#F9FAFB",
                borderRadius: "12px",
                p: 0,
                mt: "4px",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    p: 2,
                  }}
                >
                  <CommonSubHeader
                    mediumTitle="Active Categories"
                    subTitle="Categories with active services"
                  />
                  <Box>
                    <ButtonCommon
                      size="small"
                      icon={<Add />}
                      onClick={() => setOpen(true)}
                    >
                      New Category
                    </ButtonCommon>
                  </Box>
                </Box>
                <Box sx={{ p: "1.2rem" }}>
                  {(activeCategories || []).map(renderCategoryCard)}
                  {activeCategories?.length === 0 && (
                    <Box sx={{ textAlign: "center", py: 2 }}>
                      <Typography sx={{ color: "#989898" }}>
                        <DM_SANS>No Active Categories</DM_SANS>
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>

            {/* Inactive Categories Section */}
            <Grid
              container
              sx={{ background: "#F9FAFB", borderRadius: "12px", p: 0 }}
            >
              <Box sx={{ width: "100%", p: 2 }}>
                <CommonSubHeader
                  mediumTitle="Inactive Categories"
                  subTitle="Categories without any services"
                />
                <Box>
                  {(inactiveCategories || [])?.map(renderCategoryCard)}
                  {inactiveCategories.length === 0 && (
                    <Box sx={{ textAlign: "center", py: 2 }}>
                      <Typography sx={{ color: "#989898" }}>
                        <DM_SANS>No Inactive Categories</DM_SANS>
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Box>
        )
      ) : (
        renderCategoryDetails()
      )}
      {renderDrawer()}
    </Box>
  );
};

export default CategoryCards;
