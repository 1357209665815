import React, { useState } from "react";
import { Box } from "@mui/material";
import CommonTabs from "../../CommonComponent/CommonTabs";
import { ServicesCards } from "./ServicesCards";
import CategoryCards from "./CategoryCards";
import CommonHeader from "../../CommonComponent/CommonHeader";

const VendorManagement = () => {
  const [value, setValue] = useState("1"); // Default to "1"

  const tabs = [
    {
      label: "Data Processing Services",
      value: "1",
      content: <ServicesCards />,
    },
    {
      label: "Categories",
      value: "2",
      content: <CategoryCards />,
    },
  ];

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <CommonHeader
        title="Vendor Management"
        subTitle="Review and manage the service categories and third-party vendor services on your website here. Add / update / delete a new category or service, make the services active or inactive."
      />
      <CommonTabs value={value} handleChange={handleChange} tabs={tabs} />
    </Box>
  );
};

export default VendorManagement;
