import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  Snackbar,
} from "@mui/material";
import myIcon from "../assets/svg/logo.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { useNavigate } from "react-router-dom";
import { confirmSignIn, fetchUserAttributes } from "@aws-amplify/auth";
import { showSuccessToast } from "../toastUtils";

const ChangePassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const location = useLocation();
  const { email } = location.state || {}; // Fallback to empty object

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const validateField = (name, value) => {
    switch (name) {
      case "newPassword":
        if (!value) {
          return "New password is required";
        }
        if (value.length < 8) {
          return "Password must be at least 8 characters long";
        }
        if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/.test(value)) {
          return "Password must include lowercase, uppercase, number, and special character";
        }
        return "";

      case "confirmPassword":
        if (!value) {
          return "Confirm password is required";
        }
        if (value !== formData.newPassword) {
          return "Passwords do not match";
        }
        return "";

      default:
        return "";
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (touched[name]) {
      const fieldError = validateField(name, value);
      setErrors((prev) => ({
        ...prev,
        [name]: fieldError,
      }));
    }
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;

    setTouched((prev) => ({
      ...prev,
      [name]: true,
    }));

    const fieldError = validateField(name, value);
    setErrors((prev) => ({
      ...prev,
      [name]: fieldError,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const fieldErrors = {
      newPassword: validateField("newPassword", formData.newPassword),
      confirmPassword: validateField(
        "confirmPassword",
        formData.confirmPassword
      ),
    };

    setErrors(fieldErrors);

    if (Object.values(fieldErrors).some((error) => error)) {
      return;
    }

    try {
      const username = email.split("@")[0];
      const attributes = {
        given_name: username,
      };
      const result = await confirmSignIn({
        challengeResponse: formData.newPassword,
        options: {
          userAttributes: attributes,
        },
      });

      showSuccessToast("Password changed successfully");
      setSnackbarOpen(true);

      // Navigate to another page if needed
      if (result) {
        const currentUser = await fetchUserAttributes();
        const organizationId = currentUser?.profile;
        if (organizationId !== undefined && organizationId !== null) {
          const numericId = Number(organizationId);
          if (!isNaN(numericId)) {
            localStorage.setItem("organization_id_str", String(numericId));
          }
        }
        setTimeout(() => {
          navigate("/add-website");
        }, 500);
      }
    } catch (err) {
      setError("An error occurred while changing the password");
      console.error(err);
    }
  };

  return (
    <Container
      component="main"
      maxWidth={false}
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#F8F8F8",
        padding: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 4,
        }}
      >
        <img src={myIcon} alt="logo" width="250" height="50" />
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: 450,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              mb: 4,
              color: "#5F5F5F",
              fontWeight: 600,
              fontFamily: "DM Sans",
            }}
          >
            Change Your Password
          </Typography>

          <Paper
            elevation={2}
            sx={{
              width: "90%",
              p: 4,
              borderRadius: "12px",
              backgroundColor: "white",
              border: "none",
            }}
            variant="outlined"
          >
            <Box component="form" onSubmit={handleSubmit}>
              <TextField
                margin="normal"
                size="small"
                required
                fullWidth
                name="newPassword"
                label="New Password"
                type={showPassword ? "text" : "password"}
                value={formData.newPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.newPassword && !!errors.newPassword}
                helperText={touched.newPassword && errors.newPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword} edge="end">
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ mb: 2 }}
              />

              <TextField
                margin="normal"
                size="small"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type={showPassword ? "text" : "password"}
                value={formData.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.confirmPassword && !!errors.confirmPassword}
                helperText={touched.confirmPassword && errors.confirmPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword} edge="end">
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ mb: 2 }}
              />

              <Button
                type="submit"
                size="small"
                fullWidth
                variant="outlined"
                sx={{
                  mt: 2,
                  "&:hover": {
                    backgroundColor: "#009B7D",
                    color: "white",
                  },
                  borderRadius: 1.5,
                  border: "2px solid #009B7D",
                  textTransform: "none",
                  fontSize: "1rem",
                  color: "#525455",
                  fontWeight: 700,
                }}
              >
                Change Password
              </Button>

              <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                message={"Password changed successfully!"}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
              />
              {error && <Typography color="error">{error}</Typography>}
            </Box>
          </Paper>
        </Box>
      </Box>
    </Container>
  );
};

export default ChangePassword;
