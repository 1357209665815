import React from "react";
import { Box, Typography } from "@mui/material";

const SidebarTab = ({
  label,
  isActive,
  onClick,
  activeColor = "#00B894",
  inactiveColor = "#637381",
}) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        position: "relative",
        cursor: "pointer",
        mb: 2,
        pl: 2,
        py: 1,
        display: "flex",
        alignItems: "center",
        color: isActive ? activeColor : inactiveColor,
        "&:hover": {
          color: activeColor,
        },
        "&::after": isActive
          ? {
              content: '""',
              position: "absolute",
              right: 0,
              top: 0,
              width: 3,
              height: "100%",
              backgroundColor: activeColor,
            }
          : {},
      }}
    >
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: isActive ? 600 : 400,
          whiteSpace: "nowrap",
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};

export default SidebarTab;
