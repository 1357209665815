import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  TextField,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  styled,
  tableCellClasses,
  Pagination,
  CircularProgress,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"; // Use dayjs adapter
import dayjs from "dayjs"; // Import dayjs
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"; // Import the plugin
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"; // Import the plugin
import CommonHeader from "../CommonComponent/CommonHeader";
import ButtonCommon from "../CommonComponent/ButtonCommon";
import { useNavigate } from "react-router-dom";
import { Eye, FilterIcon, InstallationIcon } from "../assets/svg";
import { getConsentDetails } from "../api/consentLog";

// Extend dayjs with the necessary plugins
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

// Styled components for table
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F9FAFB",
    color: "#000000",
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const ConsentLog = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [consentData, setConsentData] = useState([]);
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showDateFilter, setShowDateFilter] = useState(false); // State to toggle date filter visibility

  // Filter data by consent ID and date range
  const filteredData =
    Array.isArray(consentData) &&
    consentData.filter((row) => {
      const consentDate = dayjs(row.accepted_at); // Use dayjs instead of new Date()
      const matchesSearch = row.consent_hashid
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      const matchesDateRange =
        (!startDate || consentDate.isSameOrAfter(startDate, "day")) &&
        (!endDate || consentDate.isSameOrBefore(endDate, "day"));

      return matchesSearch && matchesDateRange;
    });

  // Pagination logic
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = Math.min(page * rowsPerPage, filteredData.length);
  const paginatedData = (Array?.isArray(filteredData)? filteredData : []).slice(startIndex, endIndex);

  const handleRowClick = (id) => {
    navigate(`/consent-details/${id}`);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const getConsentLogs = async () => {
    setLoading(true);
    try {
      const res = await getConsentDetails();
      setConsentData(res?.data || []);
    } catch (err) {
      console.error("Error fetching consent logs:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getConsentLogs();
  }, []);

  // Function to convert the data to CSV and trigger download
  const downloadCSV = (data) => {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((row) => Object.values(row).join(","));
    const csvContent = [header, ...rows].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "consent_log_report.csv";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Function to clear date filters
  const clearDateFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setShowDateFilter(false); // Optionally, hide the date filter after clearing
  };

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <CommonHeader
          title="Consent Log"
          subTitle="You can review and audit the consent log and consent details on this page."
        />
      </Box>
      <Stack
        direction="row"
        spacing={2}
        sx={{ mb: 3 }}
        justifyContent="space-between"
        alignItems="center"
      >
        <TextField
          placeholder="Search by Consent ID"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            setPage(1); // Reset page when the search query changes
          }}
          size="small"
          sx={{ width: "300px" }}
          InputProps={{
            startAdornment: <Search color="action" sx={{ mr: 1 }} />,
          }}
        />
        <Stack direction="row" spacing={2}>
          <ButtonCommon
            icon={<FilterIcon />}
            onClick={() => setShowDateFilter((prev) => !prev)} // Toggle date filter visibility
          >
            Filter by date
          </ButtonCommon>
          {startDate || endDate ? (
            <ButtonCommon onClick={clearDateFilters}>
              Clear Filters
            </ButtonCommon> // Add "Clear Filters" button
          ) : null}
          <ButtonCommon
            icon={<InstallationIcon />}
            onClick={() => downloadCSV(filteredData)} // Trigger CSV download on button click
          >
            Download report
          </ButtonCommon>
        </Stack>
      </Stack>

      {/* Show the date range filter if showDateFilter is true */}
      {showDateFilter && (
        <Box sx={{ mb: 3 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack direction="row" spacing={2}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newDate) => setStartDate(newDate)}
                renderInput={(props) => <TextField {...props} />}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newDate) => setEndDate(newDate)}
                renderInput={(props) => <TextField {...props} />}
              />
            </Stack>
          </LocalizationProvider>
        </Box>
      )}

      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{ borderRadius: "12px" }}
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Consent ID</StyledTableCell>
              <StyledTableCell>IP Country</StyledTableCell>
              <StyledTableCell>Device</StyledTableCell>
              <StyledTableCell>Accept Type</StyledTableCell>
              <StyledTableCell>Accepted At</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : paginatedData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No consent logs found.
                </TableCell>
              </TableRow>
            ) : (
              paginatedData.map((row) => (
                <TableRow
                  key={row.consent_id}
                  hover
                  onClick={() => handleRowClick(row.consent_id)}
                  sx={{ cursor: "pointer" }}
                >
                  <StyledTableCell>{row.consent_hashid}</StyledTableCell>
                  <StyledTableCell>{row.ip_country}</StyledTableCell>
                  <StyledTableCell>{row.device_name}</StyledTableCell>
                  <StyledTableCell>
                    {row.user_preferences?.acceptType}
                  </StyledTableCell>
                  <StyledTableCell>
                    {dayjs(row.accepted_at).format("YYYY-MM-DD HH:mm")}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <IconButton size="small">
                      <Eye />
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="center" mt={3}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
          disabled={loading || totalPages <= 1}
        />
      </Box>
    </Box>
  );
};

export default ConsentLog;
