import React from "react";
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  Button,
  Divider,
} from "@mui/material";
import { CancelOutlined } from "@mui/icons-material";

const CommonDrawer = ({
  open,
  onClose,
  title,
  subTitle,
  children,
  primaryButton,
  drawerWidth = 800,
  borderRadius = 8,
  cancelButtonLabel = "Cancel",
  onDelete,
  onSubmit,
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    if (onSubmit) {
      onSubmit();
    }
  };

  const handleButtonClick = () => {
    if (cancelButtonLabel === "Cancel") {
      onClose();
    } else if (cancelButtonLabel === "Delete" && onDelete) {
      onDelete();
    } else {
      onSubmit();
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: {
            xs: "100%",
            sm: `${drawerWidth}px`,
          },
          borderTopLeftRadius: borderRadius,
          borderBottomLeftRadius: borderRadius,
          boxSizing: "border-box",
        },
      }}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        component="form"
        onSubmit={handleSubmit} // Use the local handleSubmit
      >
        <Box sx={{ p: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              gap: "20px",
            }}
          >
            <Box>
              <Typography
                variant="h6"
                component="h2"
                fontWeight="bold"
                color="#525455"
              >
                {title}
              </Typography>
              {subTitle && (
                <Typography variant="body2" color="#989898" sx={{ mt: 1 }}>
                  {subTitle}
                </Typography>
              )}
            </Box>
            <IconButton onClick={onClose} size="small" sx={{ mt: -0.5 }}>
              <CancelOutlined />
            </IconButton>
          </Box>
        </Box>

        <Divider />

        <Box
          sx={{
            flex: 1,
            p: 3,
            overflowY: "auto",
          }}
        >
          {children}
        </Box>

        <Box
          sx={{
            p: 3,
            borderTop: "1px solid",
            borderColor: "divider",
            bgcolor: "background.paper",
          }}
        >
          <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-start" }}>
            {primaryButton && (
              <Button
                variant="outlined"
                {...primaryButton}
                sx={{
                  border: "2px solid #00B894",
                  textTransform: "capitalize",
                  fontWeight: "bold",
                  color: "#525455",
                }}
                type="submit"
              >
                {primaryButton.label}
              </Button>
            )}
            <Button
              variant="outlined"
              sx={{
                border: "2px solid #CECECE",
                textTransform: "capitalize",
                fontWeight: "bold",
                color: "#525455",
              }}
              onClick={() => handleButtonClick(cancelButtonLabel)} // Handle cancel button click
            >
              {cancelButtonLabel}
            </Button>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default CommonDrawer;
