import {
  Box,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  styled,
  tableCellClasses,
  IconButton,
  InputLabel,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Add, DeleteOutline } from "@mui/icons-material";
import ButtonCommon from "../../CommonComponent/ButtonCommon";
import CommonSubHeader from "../../CommonComponent/CommonSubHeader";
import { useEffect, useState } from "react";
import { createUser, deleteUser, getUserData } from "../../api/user";
import CommonDrawer from "../../CommonComponent/CommonDrawer";
import Loader from "../../CommonComponent/Loader";
import { showErrorToast } from "../../toastUtils";

const Team = () => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
  });

  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F9FAFB",
      color: "#000000",
      fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const fetchUserData = async () => {
    try {
      setLoading(true);
      const res = await getUserData();
      if (res?.status_code === 200) {
        setUserData(res?.data);
        setLoading(false);
        console.log(res, "===res");
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {
      name: "",
      email: "",
    };

    if (!formData.name) {
      newErrors.name = "Name is required";
    }

    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }

    // If there are validation errors, update the error state and return
    if (newErrors.name || newErrors.email) {
      setErrors(newErrors);
      return;
    }

    try {
      setLoading(true);
      const data = {
        email: formData.email,
        full_name: formData.name,
      };
      const res = await createUser(data);
      console.log(res, "===res create");

      if (res?.data?.status_code === 400) {
        showErrorToast(res?.data?.message);
      }

      setTimeout(async () => {
        await fetchUserData();
        setOpen(false);
        setLoading(false);
        setFormData({ name: "", email: "" });
        setErrors({ name: "", email: "" });
      }, 500);
    } catch (err) {
      console.log("Error in handleSubmit:", err);
      setOpen(false);
      setLoading(false);
    }
  };

  const handleDelete = async (userId) => {
    try {
      setLoading(true);
      const res = await deleteUser(userId);
      console.log(res, "===res");
      setTimeout(async () => {
        await fetchUserData();
        setLoading(false);
        setFormData({ name: "", email: "" });
        setErrors({ name: "", email: "" });
      }, 500);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setOpen(false);
    }
  };

  console.log(userData, "userData");

  if (loading) {
    <Loader />;
  }

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <Box>
          <CommonSubHeader
            mediumTitle="Manage Members"
            subTitle="Invite your team to help manage your website. You can assign roles and set permissions to control access."
          />
        </Box>
        <Box sx={{ display: "flex" }}>
          <ButtonCommon
            size="small"
            icon={<Add />}
            onClick={() => setOpen(true)}
          >
            Invite A User
          </ButtonCommon>
        </Box>
      </Box>

      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{ borderRadius: "12px" }}
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Email Address</StyledTableCell>
              <StyledTableCell>Role</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableCell
                colSpan={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </TableCell>
            ) : (
              userData?.map((user) => (
                <TableRow key={user.user_id} hover sx={{ cursor: "pointer" }}>
                  <StyledTableCell>{user.full_name}</StyledTableCell>
                  <StyledTableCell>{user.email}</StyledTableCell>
                  <StyledTableCell>
                    {user.is_admin === 1 ? "Billing Admin" : "Org User"}{" "}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <IconButton size="small" disabled={user?.is_admin === 1}>
                      <DeleteOutline
                        onClick={() => handleDelete(user?.cognito_id)}
                      />
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
        <Typography variant="caption">
          * Billing Admin can manage whole application, including Account
          Settings.
        </Typography>
        <Typography variant="caption">
          * Org User can manage the application but cannot manage Account
          Settings.
        </Typography>
      </Box>
      <CommonDrawer
        open={open}
        onClose={() => setOpen(false)}
        title="Invite a User"
        subTitle="Add information below to invite a user"
        primaryButton={{
          label: "Invite",
          onClick: handleSubmit,
          sx: { px: 4 },
        }}
        drawerWidth={400}
      >
        {/* Name Field */}
        <InputLabel shrink htmlFor="name" required>
          Name
        </InputLabel>
        <TextField
          fullWidth
          size="small"
          required
          margin="none"
          name="name"
          type="text"
          value={formData.name}
          onChange={handleInputChange}
          sx={{ mb: 3 }}
          error={Boolean(errors.name)} // Show error if validation fails
          helperText={errors.name} // Display the error message
        />

        {/* Email Field */}
        <InputLabel shrink htmlFor="email" required>
          Email
        </InputLabel>
        <TextField
          fullWidth
          required
          margin="none"
          size="small"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleInputChange}
          sx={{ mb: 3 }}
          error={Boolean(errors.email)} // Show error if validation fails
          helperText={errors.email} // Display the error message
        />
      </CommonDrawer>
    </>
  );
};

export default Team;
