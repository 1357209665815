import apiClient from "./apiClient";

export const getConsentDetails = async () => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.get("/consent/get-consent-details", {
      headers: {
        website_id: websiteId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching consent details:", error);
  }
};

export const fetchConsentData = async (consentId) => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.get(`/consent/${consentId}`, {
      headers: {
        website_id: websiteId,
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching consent data:", error);
    throw error;
  }
};
