import apiClient from "./apiClient";

export const getAllCategory = async () => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.get("/categories", {
      headers: {
        website_id: websiteId, // Correctly passing in headers
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error starting scan:", error);
    throw error;
  }
};

export const createCategory = async (payload) => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.post("/categories/", payload, {
      headers: {
        website_id: websiteId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error creating category:", error);
    throw error;
  }
};

export const updateCategory = async (categoryId, categoryData) => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.put(
      `/categories/${categoryId}`,
      categoryData,
      {
        headers: {
          website_id: websiteId,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating category:", error);
    throw error;
  }
};

export const deleteCategory = async (categoryId) => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.delete(`/categories/${categoryId}`, {
      headers: {
        website_id: websiteId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting category:", error);
    throw error;
  }
};
