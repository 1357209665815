// src/api/websiteService.js
import apiClient from "./apiClient";

export const createWebsite = async (url, userId) => {
  try {
    const payload = {
      domain_name: url,
      created_by: userId,
    };
    const response = await apiClient.post("/website/create_website", payload);
    return response.data;
  } catch (error) {
    console.error("Error creating website:", error);
    throw error;
  }
};

export const getAllWebsites = async () => {
  try {
    const response = await apiClient.get("/website/get_all_website");
    return response.data;
  } catch (error) {
    console.error("Error getting all websites:", error);
    throw error;
  }
};

export const getWebsiteById = async (websiteId) => {
  try {
    const response = await apiClient.get(`/website/${websiteId}`);
    return response.data;
  } catch (error) {
    console.error("Error getting all websites:", error);
    throw error;
  }
};

export const deleteWebsite = async (websiteId) => {
  try {
    const response = await apiClient.put(`/website/delete/${websiteId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting website:", error);
    throw error;
  }
};

export const startScan = async (websiteId, url) => {
  const payload = {
    website_id: websiteId,
    url_name: url,
  };
  try {
    const response = await apiClient.post("/scan/start", payload);
    return response.data;
  } catch (error) {
    console.error("Error starting scan:", error);
    throw error;
  }
};

export const scanWebsite = async (scanId) => {
  try {
    const response = await apiClient.get(`/scan/${scanId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting website:", error);
    throw error;
  }
};

export const lastScan = async () => {
  const websiteId = localStorage.getItem("website_id");
  try {
    const response = await apiClient.get("/last_scan", {
      headers: {
        website_id: websiteId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting website:", error);
    throw error;
  }
};
