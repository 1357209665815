import React from 'react'
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';

const DM_SANS = styled("span")({
    fontFamily: "DM Sans",
  });

const CommonSubHeader = ({ mediumTitle, subTitle}) => {
  return (
    <Box>
       <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2}}>
          <Box>
            <Typography sx={{fontSize:'16px',}} variant="h6" color="#525455" fontWeight="bold">
              <DM_SANS>{mediumTitle}</DM_SANS>
            </Typography>
            <Typography sx={{fontSize:'12px',}} variant="body" color="#989898">
              <DM_SANS>
                {subTitle} 
              </DM_SANS>
            </Typography>
          </Box>
        </Box>
    </Box>
  )
}

export default CommonSubHeader
