import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { styled } from "@mui/material/styles";

// Styled Tab Component
const DM_SANS_TAB = styled(Tab)(({ theme }) => ({
  fontFamily: "DM Sans",
  fontSize: "16px",
  fontWeight: 300,
  lineHeight: "20px",
  textTransform: "none",
  color: theme.palette.text.primary,
  "&.Mui-selected": {
    fontWeight: 600,
    color: "#00B894",
  },
}));

// Styled TabList Component
const StyledTabList = styled(TabList)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: "#00B894",
  },
}));

// CommonTabs Component
const CommonTabs = ({ value, handleChange, tabs }) => {
  return (
    <Box sx={{ width: "100%", typography: "body1", marginTop: "0.8rem" }}>
      {/* Tab Context Wrapper */}
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <StyledTabList onChange={handleChange} aria-label="customized tabs">
            {tabs.map((tab) => (
              <DM_SANS_TAB
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </StyledTabList>
        </Box>

        {/* Tab Panels */}
        {tabs.map((tab) => (
          <TabPanel key={tab.value} value={tab.value}>
            {value === tab.value ? tab.content : null}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

export default CommonTabs;
