import React, { useState, useEffect } from "react";
import CustomSwitch from "../../../CommonComponent/CustomSwitch";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Divider,
  TextField,
} from "@mui/material";

const PreferenceModal = ({ data, onUpdate }) => {
  const [formData, setFormData] = useState({
    enablePrivacyTrigger: data?.enablePrivacyTrigger ?? true,
    privacyTriggerPosition: data?.privacyTriggerPosition || "Bottom Left",
    displayCookies: data?.displayCookies ?? true,
    controlLevel: data?.controlLevel || "Category & Services",
    title: data?.title || "Cookie Usage",
    description:
      data?.description ||
      "We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose to accept cookies or opt-out whenever you want. For more details, read the full privacy policy.",
    moreInfoTitle: data?.moreInfoTitle || "More Information",
    moreInfoContent:
      data?.moreInfoContent ||
      "For any queries in relation to our policy on cookies and your choices, please contact us.",
    layout: data?.layout || "Box",
    modalAlignment: data?.modalAlignment || "Left",
    acceptAll: data?.acceptAll ?? true,
    acceptAllText: data?.acceptAllText || "Accept All",
    acceptNecessary: data?.acceptNecessary ?? true,
    acceptNecessaryText: data?.acceptNecessaryText || "Reject All",
    savePreferences: data?.savePreferences ?? true,
    savePreferencesText: data?.savePreferencesText || "Save Preference",
  });
  useEffect(() => {
    if (data) {
      setFormData({
        enablePrivacyTrigger: data.enablePrivacyTrigger ?? true,
        privacyTriggerPosition: data.privacyTriggerPosition,
        displayCookies: data.displayCookies ?? true,
        controlLevel: data.controlLevel || "Category & Services",
        title: data.title || "",
        description:data.description ||"",
        moreInfoTitle: data.moreInfoTitle || "",
        moreInfoContent: data.moreInfoContent || "",
        layout: data.layout || "Box",
        modalAlignment: data.modalAlignment || "Left",
        acceptAll: data.acceptAll ?? true,
        acceptAllText: data?.acceptAllText || "",
        acceptNecessary: data.acceptNecessary ?? true,
        acceptNecessaryText: data?.acceptNecessaryText || "",
        savePreferences: data.savePreferences ?? true,
        savePreferencesText: data?.savePreferencesText || "",
      });
    }
  }, [data]);

  const handleInputChange = (field, value) => {
    const newFormData = {
      ...formData,
      [field]: value,
    };
    setFormData(newFormData);
    onUpdate(newFormData);
  };

  return (
    <Box
      sx={{
        // width: "800px",
        // p: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box>
        <Box>
          <Typography
          variant="h6"
          sx={{ color: "#525455" }}
        >
          Privacy Trigger
        </Typography>
        <Typography variant="body2" sx={{ mb: 1.5, color: "#525455" }}>
        A floating widget for your website to quickly access the consent preferences.
        </Typography>
        </Box>
        <Card
          variant="outlined"
          sx={{
            bgcolor: "#F9FAFB",
            border: "1px solid #E5E7EB",
            borderRadius: "8px",
          }}
        >
          <CardContent sx={{ p: 3 }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "#525455",
                      fontWeight: 600,
                    }}
                  >
                    Enable Privacy Trigger Position
                  </Typography>
                  <CustomSwitch
                    checked={formData.enablePrivacyTrigger}
                    onChange={(e) =>
                      handleInputChange(
                        "enablePrivacyTrigger",
                        e.target.checked
                      )
                    }
                  />
                </Box>

                {formData.enablePrivacyTrigger && (
                  <>
                    <Divider sx={{ my: 2 }} />
                    <Box>
                      <Typography
                        variant="subtitle1"
                        mb={2}
                        sx={{
                          color: "#525455",
                          fontWeight: 600,
                        }}
                      >
                        Privacy Trigger's Position
                      </Typography>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                          gap: 2,
                          width: "100%",
                        }}
                      >
                        {["Bottom Left", "Bottom Right"].map((position) => (
                          <Button
                            key={position}
                            variant="outlined"
                            onClick={() =>
                              handleInputChange(
                                "privacyTriggerPosition",
                                position
                              )
                            }
                            sx={{
                              borderColor:
                                formData.privacyTriggerPosition === position
                                  ? "#00B894"
                                  : "#E5E7EB",
                              color:
                                formData.privacyTriggerPosition === position
                                  ? "#00B894"
                                  : "#525455",
                              backgroundColor:
                                formData.privacyTriggerPosition === position
                                  ? "#F0FDF9"
                                  : "#FFFFFF",
                              "&:hover": {
                                backgroundColor:
                                  formData.privacyTriggerPosition === position
                                    ? "#F0FDF9"
                                    : "#F9FAFB",
                                borderColor:
                                  formData.privacyTriggerPosition === position
                                    ? "#00B894"
                                    : "#B0B9C5",
                              },
                              textTransform: "none",
                              height: "70px",
                              fontSize: "16px",
                              borderRadius: "8px",
                            }}
                          >
                            {position}
                          </Button>
                        ))}
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>

      {/* Control Level */}
      <Box>
        <Typography variant="h6" sx={{ color: "#525455" }}>
          Control Level
        </Typography>
        <Typography variant="body2" sx={{ mb: 1, color: "#525455" }}>
        Manage the detail level visitors see and the options they control.
        </Typography>
        <Card variant="outlined" sx={{ bgcolor: "#F9FAFB" }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row", // Changed to row
                gap: 3,
                width: "100%",
              }}
            >
              {["Category & Services", "Category Only"].map((level) => (
                <Box
                  key={level}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    p: 2,
                    border: "1px solid",
                    borderColor:
                      formData.controlLevel === level ? "#00B894" : "#E0E0E0",
                    borderRadius: "8px",
                    backgroundColor:
                      formData.controlLevel === level ? "#F0FDF9" : "#FFFFFF",
                    cursor: "pointer",
                    transition: "all 0.3s ease",
                    flex: 1, // Added to make boxes take equal width
                    width: "50%", // Added to ensure equal width distribution
                    "&:hover": {
                      borderColor: "#00B894",
                      backgroundColor:
                        formData.controlLevel === level ? "#F0FDF9" : "#F9FAFB",
                    },
                  }}
                  onClick={() => handleInputChange("controlLevel", level)}
                >
                  <Typography
                    sx={{
                      color:
                        formData.controlLevel === level ? "#00B894" : "#525455",
                      fontWeight: 600,
                    }}
                  >
                    {level}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#6B7280",
                      fontSize: "14px",
                    }}
                  >
                    {level === "Category & Services"
                      ? "Visitors can view categories and their services, with granular control to enable or disable services in non-essential categories."
                      : "Visitors can only consent at the category level, without granular control for services within each category."}
                  </Typography>
                </Box>
              ))}
            </Box>

            {/* Show toggle only when Category Only is selected */}
            {formData.controlLevel === "Category Only" && (
              <Box
                mt={2}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: 2,
                  borderTop: "1px solid #E0E0E0",
                }}
              >
                <Typography>Display Cookies & Services</Typography>
                <CustomSwitch
                  checked={formData.displayCookies}
                  onChange={(e) =>
                    handleInputChange("displayCookies", e.target.checked)
                  }
                />
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>

      {/* Preferences Modal Content */}
      <Box>
        <Typography variant="h6" sx={{ mb: 1, color: "#525455" }}>
        Manage preferences content
        </Typography>
        <Typography variant="body2" sx={{ mb: 1, color: "#525455" }}>
        Change the content that will be displayed in the Manage Preference popup.
        </Typography>
        <Card variant="outlined" sx={{ bgcolor: "#F9FAFB" }}>
          <CardContent sx={{ p: 3 }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
              {/* Title */}
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#525455",
                    fontWeight: 600,
                    mb: 1,
                  }}
                >
                  Title
                </Typography>
                <TextField
                  fullWidth
                  placeholder="Enter Title"
                  value={formData.title}
                  onChange={(e) => handleInputChange("title", e.target.value)}
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#FFFFFF",
                      "& fieldset": {
                        borderColor: "#E9ECEF",
                        borderRadius: "8px",
                      },
                      "&:hover fieldset": {
                        borderColor: "#DEE2E6",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#00B894",
                      },
                    },
                  }}
                />
              </Box>

              {/* Description */}
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#525455",
                    fontWeight: 600,
                    mb: 1,
                  }}
                >
                  Description
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  placeholder="Enter Description"
                  value={formData.description}
                  onChange={(e) =>
                    handleInputChange("description", e.target.value)
                  }
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#FFFFFF",
                      "& fieldset": {
                        borderColor: "#E9ECEF",
                        borderRadius: "8px",
                      },
                      "&:hover fieldset": {
                        borderColor: "#DEE2E6",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#00B894",
                      },
                    },
                  }}
                />
              </Box>

              {/* More Information Title */}
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#525455",
                    fontWeight: 600,
                    mb: 1,
                  }}
                >
                  More Information Title
                </Typography>
                <TextField
                  fullWidth
                  placeholder="More Information Title"
                  value={formData.moreInfoTitle}
                  onChange={(e) =>
                    handleInputChange("moreInfoTitle", e.target.value)
                  }
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#FFFFFF",
                      "& fieldset": {
                        borderColor: "#E9ECEF",
                        borderRadius: "8px",
                      },
                      "&:hover fieldset": {
                        borderColor: "#DEE2E6",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#00B894",
                      },
                    },
                  }}
                />
              </Box>

              {/* More Information Content */}
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#525455",
                    fontWeight: 600,
                    mb: 1,
                  }}
                >
                  More Information
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  placeholder="Enter More Information"
                  value={formData.moreInfoContent}
                  onChange={(e) =>
                    handleInputChange("moreInfoContent", e.target.value)
                  }
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#FFFFFF",
                      "& fieldset": {
                        borderColor: "#E9ECEF",
                        borderRadius: "8px",
                      },
                      "&:hover fieldset": {
                        borderColor: "#DEE2E6",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#00B894",
                      },
                    },
                  }}
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>

      {/* Preferences Modal Layout */}
      {/* <Box>
        <Typography variant="h6" sx={{ mb: 1, color: "#525455" }}>
          Preferences Modal Layout
        </Typography>
        <Card variant="outlined" sx={{ bgcolor: "#F9FAFB" }}>
          <CardContent> */}
      {/* Layout */}
      {/* <Box mb={2}>
              <Typography variant="subtitle1">Layout</Typography>
              <Box sx={{ display: "flex", gap: 2 }}>
                {["Box", "Bar"].map((layout) => (
                  <Button
                    key={layout}
                    variant={
                      formData.layout === layout ? "contained" : "outlined"
                    }
                    onClick={() => handleInputChange("layout", layout)}
                  >
                    {layout}
                  </Button>
                ))}
              </Box>
            </Box> */}

      {/* Horizontal Alignment */}
      {/* <Box>
              <Typography variant="subtitle1">Modal Horizontal Alignment</Typography>
              <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
                {["Left", "Right"].map((alignment) => (
                  <Button
                    key={alignment}
                    variant={
                      formData.modalAlignment === alignment
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => handleInputChange("modalAlignment", alignment)}
                  >
                    {alignment}
                  </Button>
                ))}
              </Box>
            </Box> */}
      {/* </CardContent>
        </Card>
      </Box> */}
      <Box>
        <Typography variant="h6" sx={{ mb: 1, color: "#525455" }}>
          Preferences Modal Layout
        </Typography>
        <Typography variant="body2" sx={{ mb: 1, color: "#525455" }}>
        Change the appearance and layout of the preferences modal.
        </Typography>
        <Card variant="outlined" sx={{ bgcolor: "#F9FAFB" }}>
          <CardContent sx={{ p: 3 }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
              {/* Layout */}
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#525455",
                    fontWeight: 600,
                    mb: 2,
                  }}
                >
                  Layout
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: 3,
                    width: "100%",
                  }}
                >
                  {[{
                      type: "Box",
                      description: "Manage Preference Banner appears as a box in the center of the screen"
                    },
                    {
                      type: "Bar",
                      description: "Slideover panel that spans the full height of the screen"
                    }].map((item) => (
                    <Box
                      key={item.type}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        p: 2,
                        border: "1px solid",
                        borderColor:
                          formData.layout === item.type ? "#00B894" : "#E0E0E0",
                        borderRadius: "8px",
                        backgroundColor:
                          formData.layout === item.type ? "#F0FDF9" : "#FFFFFF",
                        cursor: "pointer",
                        transition: "all 0.3s ease",
                        flex: 1,
                        width: "50%",
                        "&:hover": {
                          borderColor: "#00B894",
                          backgroundColor:
                            formData.layout === item.type ? "#F0FDF9" : "#F9FAFB",
                        },
                      }}
                      onClick={() => handleInputChange("layout", item.type)}
                    >
                      <Typography
                        sx={{
                          color:
                            formData.layout === item.type ? "#00B894" : "#525455",
                          fontWeight: 600,
                          textAlign: "center",
                        }}
                      >
                        {item.type}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#989898",
                          textAlign: "center",
                          fontSize: "0.875rem",
                          lineHeight: "1.25rem"
                        }}
                      >
                        {item.description}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>

              {/* Vertical Alignment - Only show when Bar is selected */}
              {formData.layout === "Bar" && (
                <Box>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "#525455",
                      fontWeight: 600,
                      mb: 2,
                    }}
                  >
                    Modal Vertical Alignment
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 3,
                      width: "100%",
                    }}
                  >
                    {["Left", "Right"].map((alignment) => (
                      <Box
                        key={alignment}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                          p: 2,
                          border: "1px solid",
                          borderColor:
                            formData.modalAlignment === alignment
                              ? "#00B894"
                              : "#E0E0E0",
                          borderRadius: "8px",
                          backgroundColor:
                            formData.modalAlignment === alignment
                              ? "#F0FDF9"
                              : "#FFFFFF",
                          cursor: "pointer",
                          transition: "all 0.3s ease",
                          flex: 1,
                          width: "50%",
                          "&:hover": {
                            borderColor: "#00B894",
                            backgroundColor:
                              formData.modalAlignment === alignment
                                ? "#F0FDF9"
                                : "#F9FAFB",
                          },
                        }}
                        onClick={() =>
                          handleInputChange("modalAlignment", alignment)
                        }
                      >
                        <Typography
                          sx={{
                            color:
                              formData.modalAlignment === alignment
                                ? "#00B894"
                                : "#525455",
                            fontWeight: 600,
                            textAlign: "center",
                          }}
                        >
                          {alignment}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>

      {/* Preferences Modal Buttons */}
      {/* <Box>
        <Typography variant="h6" sx={{ mb: 1, color: "#525455" }}>
          Preferences Modal Buttons
        </Typography>
        <Card variant="outlined" sx={{ bgcolor: "#F9FAFB" }}>
          <CardContent>
            {[
              { label: "Accept All Button", field: "acceptAll" },
              { label: "Accept Necessary Button", field: "acceptNecessary" },
              { label: "Save Preferences Button", field: "savePreferences" },
            ].map((btn) => (
              <Box
                key={btn.field}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 1.5,
                }}
              >
                <Typography>{btn.label}</Typography>
                <CustomSwitch
                  checked={formData[btn.field]}
                  onChange={(e) =>
                    handleInputChange(btn.field, e.target.checked)
                  }
                />
              </Box>
            ))}
          </CardContent>
        </Card>
      </Box> */}
      <Box>
        <Typography variant="h6" sx={{ mb: 1, color: "#525455" }}>
          Preferences Modal Buttons
        </Typography>
        <Typography variant="body2" sx={{ mb: 1, color: "#525455" }}>
        Change the presence and content of buttons in the preferences modal.
        </Typography>
        <Card variant="outlined" sx={{ bgcolor: "#F9FAFB" }}>
          <CardContent sx={{ p: 3 }}>
            {/* {[
        { 
          title: "'Accept All' Button", 
          subtitle: "Consent to all categories.",
          field: "acceptAll",
          defaultText: "Accept All"
        },
        { 
          title: "'Accept Necessary' Button", 
          subtitle: "Consent to only necessary cookies.",
          field: "acceptNecessary",
          defaultText: "Accept Necessary"
        },
        { 
          title: "'Save Preferences' Button", 
          subtitle: "Show button to save the user's selected preferences.",
          field: "savePreferences",
          defaultText: "Save Preferences"
        },
      ].map((item, index) => (
        <React.Fragment key={item.field}>
          <Box>
            <Box sx={{ 
              display: "grid", 
              gridTemplateColumns: "1fr auto 1fr", 
              gap: 3,
              alignItems: "center"
            }}>
              <Box>
                <Typography sx={{ color: "#525455", fontWeight: 600, mb: 0.5 }}>
                  {item.title}
                </Typography>
                <Typography variant="body2" sx={{ color: "#6B7280" }}>
                  {item.subtitle}
                </Typography>
              </Box>
              <CustomSwitch
                checked={formData[item.field]}
                onChange={(e) => handleInputChange(item.field, e.target.checked)}
              />
              <TextField
                size="small"
                value={formData[`${item.field}Text`] || item.defaultText}
                onChange={(e) => handleInputChange(`${item.field}Text`, e.target.value)}
                disabled={!formData[item.field]}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: formData[item.field] ? '#FFFFFF' : '#F3F4F6',
                    '& fieldset': {
                      borderColor: '#E5E7EB',
                    },
                    '&:hover fieldset': {
                      borderColor: formData[item.field] ? '#B0B9C5' : '#E5E7EB',
                    },
                    '& input': {
                      color: formData[item.field] ? '#525455' : '#9CA3AF',
                    },
                  },
                }}
              />
            </Box>
          </Box>
          {index < 2 && <Divider sx={{ my: 2 }} />}
        </React.Fragment>
      ))}
       */}
            {/* Customizable Button Sections */}
            {[
              {
                title: "'Accept All' Button",
                subtitle: "Consent to all categories.",
                field: "acceptAll",
                defaultText: "Accept All",
                required: true
              },
              {
                title: "'Accept Necessary' Button",
                subtitle: "Consent to only necessary cookies.",
                field: "acceptNecessary",
                defaultText: "Accept Necessary",
                required: true
              },
              {
                title: "'Save Preferences' Button",
                subtitle:
                  "Show button to save the user's selected preferences.",
                field: "savePreferences",
                defaultText: "Save Preferences",
                required: true
              },
            ].map((item, index) => (
              <React.Fragment key={item.field}>
                <Box>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr auto 1fr",
                      gap: 3,
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{ color: "#525455", fontWeight: 600, mb: 0.5 }}
                      >
                        {item.title}
                      </Typography>
                      <Typography variant="body2" sx={{ color: "#6B7280" }}>
                        {item.subtitle}
                      </Typography>
                    </Box>
                    <CustomSwitch
                      checked={item.required? true :formData[item.field]}
                      onChange={(e) =>
                        handleInputChange(item.field, e.target.checked)
                      }
                      disabled={item.required} 
                    />
                    <TextField
                      size="small"
                      //   value={formData[`${item.field}Text`] ? (formData[`${item.field}Text`] || item.defaultText) : item.defaultText}
                      value={
                        !formData[item.field]
                          ? item.defaultText
                          : formData[`${item.field}Text`] ?? ""
                      }
                      onChange={(e) =>
                        handleInputChange(`${item.field}Text`, e.target.value)
                      }
                      disabled={!formData[item.field]}
                      placeholder={item.defaultText}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: formData[item.field]
                            ? "#FFFFFF"
                            : "#F3F4F6",
                          "& fieldset": {
                            borderColor: "#E5E7EB",
                          },
                          "&:hover fieldset": {
                            borderColor: formData[item.field]
                              ? "#B0B9C5"
                              : "#E5E7EB",
                          },
                          "& input": {
                            color: formData[item.field] ? "#525455" : "#9CA3AF",
                          },
                        },
                      }}
                    />
                  </Box>
                </Box>
                {index < 2 && <Divider sx={{ my: 2 }} />}
              </React.Fragment>
            ))}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default PreferenceModal;
