import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Box, Card, Typography, Chip, CircularProgress } from "@mui/material";
import CommonHeader from "../../CommonComponent/CommonHeader";
import {
  Add,
  // Search as SearchIcon,
  // OpenInNew as OpenInNewIcon,
  Public as PublicIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";
import ButtonCommon from "../../CommonComponent/ButtonCommon";
import Loader from "../../CommonComponent/Loader";
import { getAllBanners, getBannerById } from "../../api/bannerService";
import { NoBannerLogo } from "../../assets/svg/";
// import { DEFAULT_WEBSITE_ID, DEFAULT_ORGANIZATION_ID } from '../config/constants';

const BannerDesigner = () => {
  const navigate = useNavigate();
  const [banners, setBanners] = useState([]); // Initialize as empty array
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loaderState, setLoaderState] = useState({});

  const handleNewBanner = () => {
    navigate("/banner/create");
  };
  // const handleBannerClick = (bannerId) => {
  //   navigate(`/banner/${bannerId}`);
  // };
  // Function to check if a specific banner is the last active one
  const handleBannerClick = async (bannerId) => {
    setLoaderState(prev => ({ ...prev, [bannerId]: { Loader: true } }));
    try {
      // Fetch banner data before navigation
      const response = await getBannerById(bannerId);
      if (response.success) {
        // Get all active banners
        const activeBanners = banners.filter((banner) => banner.is_active);

        // Check if this is the last active banner
        const isLastActiveBanner =
          activeBanners.length === 1 && activeBanners[0].banner_id === bannerId;

        // Add the isLastActiveBanner flag to the data
        const enhancedData = {
          ...response.data,
          isLastActiveBanner: isLastActiveBanner,
        };
        // Store the banner data in localStorage for the edit page
        localStorage.setItem("editBannerData", JSON.stringify(enhancedData));
        // Navigate to edit page
        navigate(`/banner/${bannerId}`);
      } else {
        console.error("Failed to fetch banner data");
      }
    } catch (error) {
      console.error("Error fetching banner:", error);
    } finally{
       // Clear loading state for specific banner
       setLoaderState(prev => ({ ...prev, [bannerId]: { Loader: false } }));
    }
  };

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await getAllBanners();
        console.log("API Response:", response);

        if (Array.isArray(response)) {
          setBanners(response);
          setError(null);
        } else if (response && Array.isArray(response.data)) {
          setBanners(response.data);
          setError(null);
        } else {
          throw new Error("Invalid data format received");
        }
      } catch (error) {
        console.error("Error fetching banners:", error);
        setError("Failed to load banners");
        setBanners([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBanners();
  }, []);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Loader />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {error}
      </Box>
    );
  }

  // Ensure banners is an array before filtering
  const activeBanners = Array.isArray(banners)
    ? banners.filter((banner) => banner.is_active)
    : [];
  const inactiveBanners = Array.isArray(banners)
    ? banners.filter((banner) => !banner.is_active)
    : [];
  console.log("Active banners", activeBanners);
  console.log("Inactive banners", inactiveBanners);
  return (
    <Box sx={{ display: "flex" }}>
      <Box component="main" sx={{ flexGrow: 1 }}>
        {/* Header name of banner */}
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
          <Box>
            <CommonHeader
              title="Consent Banners"
              subTitle="Update default banner, add new banner or delete banner on this screen."
            />
          </Box>
          <Box sx={{ display: "flex" }}>
            <ButtonCommon size="small" icon={<Add />} onClick={handleNewBanner}>
              New Banner
            </ButtonCommon>
          </Box>
        </Box>

        {/* Banner List Section */}

        {banners.length === 0 ? (
          // Empty state with centered content
          <Card
            sx={{
              p: 6,
              height: "calc(100vh - 200px)",
              borderRadius: 1,
              background: "#f8f9fa",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
            variant="outlined"
          >
            <NoBannerLogo
              sx={{
                width: "200px",
                height: "auto",
                mb: 3,
              }}
            />
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
                color: "#1a1a1a",
                mb: 2,
              }}
            >
              Nothing To see Here Yet
            </Typography>
            <Typography
              sx={{
                color: "#666",
                mb: 4,
                maxWidth: "500px",
              }}
            >
              There is no banner configured yet. Please add your first banner.
            </Typography>
            <ButtonCommon
              size="large"
              icon={<Add />}
              onClick={handleNewBanner}
              sx={{
                px: 4,
                py: 1.5,
                fontSize: "1rem",
              }}
            >
              New Banner
            </ButtonCommon>
          </Card>
        ) : (
          <Box>
            {/* Active Banners Section */}
            <Card
              sx={{
                p: 3,
                height: "100%",
                borderRadius: 1,
                background: "#f8f9fa",
                cursor: "pointer",
              }}
              variant="outlined"
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: 700, color: "#525455", mb: 1 }}
              >
                Active Banners
              </Typography>
              <Typography sx={{ color: "#666", fontSize: "0.875rem", mb: 3 }}>
                If there are multiple banners target the same region, the banner
                with the highest priority (in order below) will be shown.
              </Typography>

              {activeBanners
                .filter((banner) => banner.is_active)
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) // Sort by creation date
                .map((banner, index) => (
                  <>
                  {/* {loader.Loader && <Loader />} */}
                  
                  <Box
                    key={index}
                    onClick={() => handleBannerClick(banner.banner_id)}
                    sx={{
                      py: 2,
                      px: 2,
                      mb: 2,
                      backgroundColor: "#fff",
                      borderRadius: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      {/* Left side content */}
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                          mb: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#525455",
                            fontWeight: 700,
                          }}
                        >
                          {banner.banner_name}
                        </Typography>
                        {/* <Chip
                          label={banner.type}
                          sx={{
                            backgroundColor: banner.type.includes("Explicit")
                              ? "#e3f2fd"
                              : "#f5f5f5",
                            color: banner.type.includes("Explicit")
                              ? "#0046fc"
                              : "#666",
                            fontWeight: 500,
                            fontSize: "0.75rem",
                            height: "24px",
                          }}
                        /> */}
                      </Box>
                      {/* Right side icons */}
                      {/* <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <SearchIcon
                          sx={{
                            color: "#666",
                            cursor: "pointer",
                            "&:hover": { color: "#0046fc" },
                          }}
                        />
                        <OpenInNewIcon
                          sx={{
                            color: "#666",
                            cursor: "pointer",
                            "&:hover": { color: "#0046fc" },
                          }}
                        />
                      </Box> */}
                      {loaderState[banner.banner_id]?.Loader && <CircularProgress size={30} />}
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                      >
                        <PublicIcon sx={{ fontSize: "1rem", color: "#666" }} />
                        <Typography
                          sx={{ color: "#666", fontSize: "0.875rem" }}
                        >
                          {banner.target_type}
                        </Typography>
                      </Box>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                      >
                        <CheckCircleIcon
                          sx={{ fontSize: "1rem", color: "#2e7d32" }}
                        />
                        <Chip
                          label="Active"
                          sx={{
                            backgroundColor: "#e8f5e9",
                            color: "#2e7d32",
                            fontWeight: 500,
                            fontSize: "0.75rem",
                            height: "24px",
                          }}
                        />
                      </Box>
                      {/* <Chip
                      label={`Priority #${banner.priority}`}
                      sx={{
                        backgroundColor: "#f5f5f5",
                        color: "#666",
                        fontWeight: 500,
                        fontSize: "0.75rem",
                        height: "24px",
                      }}
                    /> */}
                      <Chip
                        label={`Priority #${index + 1}`} // Priority based on sorted index
                        sx={{
                          backgroundColor: "#f5f5f5",
                          color: "#666",
                          fontWeight: 500,
                          fontSize: "0.75rem",
                          height: "24px",
                        }}
                      />
                    </Box>
                  </Box>
                  </>
                ))}
            </Card>

            {/* Inactive Banners Section */}
            <Card
              sx={{
                p: 3,
                height: "100%",
                borderRadius: 1,
                background: "#f8f9fa",
                mt: 4,
              }}
              variant="outlined"
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: 700, color: "#525455", mb: 1 }}
              >
                Inactive Banners
              </Typography>
              <Typography sx={{ color: "#666", fontSize: "0.875rem", mb: 3 }}>
                Inactive services are not displayed in the consent banner. has
                context menu
              </Typography>

              {inactiveBanners
                .filter((banner) => !banner.is_active)
                .map((banner, index) => (

                  <Box
                    key={index}
                    onClick={() => handleBannerClick(banner.banner_id)}
                    sx={{
                      py: 2,
                      px: 2,
                      mb: 2,
                      backgroundColor: "#fff",
                      borderRadius: 2,
                    }}
                  >
                     
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      {/* Left side content */}
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                          mb: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#525455",
                            fontWeight: 700,
                          }}
                        >
                          {banner.banner_name}
                        </Typography>
                        {/* <Chip
                          label={banner.type}
                          sx={{
                            backgroundColor: banner.type.includes("Explicit")
                              ? "#e3f2fd"
                              : "#f5f5f5",
                            color: banner.type.includes("Explicit")
                              ? "#0046fc"
                              : "#666",
                            fontWeight: 500,
                            fontSize: "0.75rem",
                            height: "24px",
                          }}
                        /> */}
                      </Box>
                      {/* Right side icons */}
                      {/* <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <SearchIcon
                          sx={{
                            color: "#666",
                            cursor: "pointer",
                            "&:hover": { color: "#0046fc" },
                          }}
                        />
                        <OpenInNewIcon
                          sx={{
                            color: "#666",
                            cursor: "pointer",
                            "&:hover": { color: "#0046fc" },
                          }}
                        />
                      </Box> */}
                      {loaderState[banner.banner_id]?.Loader && <CircularProgress size={30} />}
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                      >
                        <PublicIcon sx={{ fontSize: "1rem", color: "#666" }} />
                        <Typography
                          sx={{ color: "#666", fontSize: "0.875rem" }}
                        >
                          {banner.target_type}
                        </Typography>
                      </Box>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                      >
                        <CancelIcon
                          sx={{ fontSize: "1rem", color: "#d32f2f" }}
                        />
                        <Chip
                          label="Inactive"
                          sx={{
                            backgroundColor: "#f5f5f5",
                            color: "#666",
                            fontWeight: 500,
                            fontSize: "0.75rem",
                            height: "24px",
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>

                ))}
            </Card>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BannerDesigner;
