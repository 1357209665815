import React, { useState } from 'react';
import {
  Dialog,
  Typography,
  Box,
  Switch,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ButtonCommon from "../../../CommonComponent/ButtonCommon";
import { BannerPrefrenceLogo} from "../../../assets/svg";
// Styled components remain the same

const ManagePreferencesModel = ({ isOpen, onClose, data}) => {
  // ... state management and cookieCategories remain the same ...
  const { preferenceSettings, themeSettings ,bannerSettings} = data;
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [expandedSubCategory, setExpandedSubCategory] = useState(null);
  const [preferences, setPreferences] = useState({});
  const isDarkTheme = themeSettings.darkMode === 'dark';
  
  const CustomAccordion = styled(Accordion)(({ theme }) => ({
    '&:before': {
      display: 'none',
    },
    borderRadius: '8px !important',
    border: `1px solid ${isDarkTheme ? '#374151' : theme.palette.divider}`,
    marginBottom: theme.spacing(2),
    boxShadow: 'none',
    backgroundColor: isDarkTheme ? '#1F2937' : theme.palette.background.paper,
  }));
  
  const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    backgroundColor: isDarkTheme ? '#374151' : theme.palette.grey[50],
    borderRadius: '8px',
    '& .MuiAccordionSummary-content': {
      margin: '12px 0',
      color: isDarkTheme ? '#FFFFFF' : theme.palette.text.primary,
    },
  }));
  
  const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: isDarkTheme ? '#FFFFFF' : '#10B981',
      '&:hover': {
        backgroundColor: isDarkTheme 
          ? 'rgba(255, 255, 255, 0.08)'
          : 'rgba(16, 185, 129, 0.08)',
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: isDarkTheme ? '#FFFFFF' : '#10B981',
    },
  }));
  
  
  const cookieCategories = [
    {
      id: 'essential',
      title: 'Essential',
      description: 'Essential cookies are necessary for features that are essential to your use of our site or services, such as account login, authentication and site security.',
      subCategories: [
        {
          id: 'recaptcha',
          title: 'Google reCAPTCHA',
          description: 'We use Google reCAPTCHA to protect our site from span and abuse',
          cookies: [
            { name: '_GRECAPTCHA', duration: '30 minutes' },
            { name: '_grecaptcha', duration: '30 minutes' },
          ]
        },
        {
          id: 'cloudflare',
          title: 'Cloudflare',
          description: 'We use Cloudflare to optimize site performance, enhance security, and protect against DDoS attacks. Cloudflare may set cookies on your device to improve user experience.',
          cookies: [
            { name: '_cf_bm', duration: '30 minutes' },
            { name: '_cf_test', duration: '1 day' },
            { name: '_cflb', duration: '24 hours' },
          ]
        },
        {
          id: 'wordpress',
          title: 'WordPress',
          description: 'We use WordPress to manage  our website conetent. Various cookies are used to manage to user session, preferences and authentication.',
          cookies: [
            { name: 'Wordpress-*', duration: 'session' },
            { name: 'comment_author_*', duration: '1 year' },
            { name: 'wordpress_logged_in_*', duration: 'session' },
            { name: 'Wordpress_sec_*', duration: 'session' },
            { name: 'Wordpress_test_cookies', duration: 'session' },
          ]
        },
      ]
    },
    {
      id: 'marketing',
      title: 'Marketing',
      description: 'Marketing cookies help us understand your preferences.',
      subCategories: [
        {
          id: 'facebook',
          title: 'Facebook',
          description: 'We use Facebook to personalize your experience on our site.',
          cookies: [
            { name: '_fbp', duration: '3 months' },
            { name: 'fr', duration: '3 months' },
          ]
        },
        {
          id: 'google',
          title: 'Google Ads/Adsense',
          description: 'We use Google Ads/Adsense to display personalize ads based on browsing behavior and preferences.',
          cookies: [
            { name: 'IDE', duration: '13 months' },
            { name: 'NID', duration: '6 months' },
            { name: 'SAPISID', duration: '2 years' },
            { name: '_gac', duration: '90 days' },
          ]
        }
      ]
    },{
      id: 'Analytics',
      title: 'Analytics',
      description: 'Analytics cookies allow us to analyse your visits and actions so we can make our service better',
      subCategories: [
        {
          id: 'googleanalytics',
          title: 'Google Analytics',
          description: 'We use Google Analytics to collect data on how visitors interact wih our site, which help uus to improve user experience and site performance',
          cookies: [
            { name: '_ga', duration: '2 years' },
            { name: '_gat', duration: '1 minute' },
            { name: '_ga_*', duration: '2 years' },
            { name: '_gid', duration: '24 hours' },
          ]
        },
        {
          id: 'segment',
          title: 'Segment',
          description: 'We use Segment to collect, transorm,and send data to various analytics tools for unified analysis',
          cookies: [
            { name: 'ajs_anonymouus_id', duration: '1 years' },
            { name: 'ajs_group_id', duration: '1 years' },
            { name: 'aks_user_id', duration: '1 years' },
          ]
        }
      ]
    }
  ];
  
  // Layout style based on preferences
  const getLayoutStyle = () => {
    if (preferenceSettings.layout === "Box") {
      return {
        borderRadius: 2,
        height: '80vh',
        maxHeight: '700px',
        width: '600px',
        margin: '20px',
        bgcolor: isDarkTheme ? '#000000' : themeSettings.background || '#FFFFFF',
        color: isDarkTheme ? '#FFFFFF' : themeSettings.text || '#000000',
      };
    } else if (preferenceSettings.layout === "Bar") {
      return {
        height: '100vh',
        width: '30%',
        position: 'fixed',
        top: 0,
        bottom:0,
        [preferenceSettings.modalAlignment.toLowerCase()]: 0,
        margin: 0,
        borderRadius: 0,
        bgcolor: isDarkTheme ? '#000000' : themeSettings.background || '#FFFFFF',
        color: isDarkTheme ? '#FFFFFF' : themeSettings.text || '#000000',
      };
    }
  };

  // Function to determine if nested content should be shown
  const shouldShowNestedContent = (category) => {
    if (preferenceSettings.controlLevel === "Category & Services") {
      return true;
    }
    return preferenceSettings.controlLevel === "Category Only" && 
           preferenceSettings.displayCookies;
  };

  const isBarLayout = preferenceSettings.layout === "Bar";
  
  const renderButtons = () => {
    // Different sizes for primary and secondary buttons in box layout
    const buttonDimensions = isBarLayout 
      ? {
          primary: { width: '250px', height: '48px' },
          secondary: { width: '250px', height: '48px' }
        }
      : {
          primary: { width: '120px', height: '40px' },     // Smaller size for Accept/Reject
          secondary: { width: '200px', height: '40px' }    // Larger width for Save Preferences
        };
  
    const getButtonOverrides = (type) => ({
      '& .MuiButton-root': {
        width: `${buttonDimensions[type].width} !important`,
        height: `${buttonDimensions[type].height} !important`,
        minWidth: `${buttonDimensions[type].width} !important`,
        minHeight: `${buttonDimensions[type].height} !important`,
        maxWidth: `${buttonDimensions[type].width} !important`,
        maxHeight: `${buttonDimensions[type].height} !important`,
        padding: '12px 20px !important',
      },
      '&.MuiButtonBase-root': {
        width: `${buttonDimensions[type].width} !important`,
        height: `${buttonDimensions[type].height} !important`,
        minWidth: `${buttonDimensions[type].width} !important`,
        minHeight: `${buttonDimensions[type].height} !important`,
        maxWidth: `${buttonDimensions[type].width} !important`,
        maxHeight: `${buttonDimensions[type].height} !important`,
        padding: '12px 20px !important',
      }
    });
     // Determine button order based on privacy trigger position
     const shouldReverse = preferenceSettings.privacyTriggerPosition === 'Bottom Right'; 
    const primaryButtonStyle = {
      ...getButtonOverrides('primary'),
      '&.MuiButtonBase-root': {
        border: `2px solid ${isDarkTheme ? '#FFFFFF' : themeSettings.primaryButtonBg || '#FFFFFF'} !important`,
        backgroundColor: isDarkTheme ? 'transparent !important' : 'transparent !important',
        color: `${isDarkTheme ? '#FFFFFF' : (themeSettings.primaryButtonText || '#FFFFFF')} !important`,
        // border: 'none !important',
        '&:hover': {
          backgroundColor: isDarkTheme ? '#34495E !important' : '#717171 !important',
          opacity: 0.9,

        }
      }
    };
    const ButtonWrapper = styled(Box)({
      '& .MuiButton-root': {
        border: `2px solid ${isDarkTheme ? '#FFFFFF' : themeSettings.primaryButtonBg || '#FFFFFF'} !important`,
        backgroundColor: `${isDarkTheme ? 'transparent' : 'transparent'} !important`,
        borderRadius: `${themeSettings.buttonBorderRadius || '0.4'}rem !important`,
        color: isDarkTheme
          ? '#ECF0F1 !important'
          : `${themeSettings.primaryButtonText || '#FFFFFF'} !important`,
        width: '100% !important',
        height: '100% !important',
        '&:hover': {
          backgroundColor: isDarkTheme 
            ? '#34495E !important'
            : `${themeSettings.primaryButtonBg || '#717171'} !important`,
          color: isDarkTheme
            ? '#ECF0F1 !important'
            : `${themeSettings.primaryButtonText || '#FFFFFF'} !important`,
          opacity: '0.9 !important'
        }
      }
    });
  
    return (
      <Box sx={{
        p: 2,
        borderTop: 1,
        borderColor: isDarkTheme ? 'rgba(255, 255, 255, 0.12)' : 'divider',
        display: 'flex',
        flexDirection: isBarLayout ? 'column' : 'row',
        gap: isBarLayout ? 2 : 8, // Increased gap in box layout
        mt: 'auto',
        alignItems: isBarLayout ? 'center' : 'center',
        // justifyContent: isBarLayout ? 'center' : 'space-between',
        justifyContent: isBarLayout
        ? 'center' 
        : shouldReverse ? 'row-reverse' : 'row',
  backgroundColor: isDarkTheme ? '#1a1a1a' : 'inherit',
      }}>
        {/* Left side buttons - Accept All and Reject */}
        <Box sx={{
          display: 'flex',
          flexDirection: isBarLayout ? 'column' : 'row',
          gap: 2,
          alignItems: 'center',
          order: isBarLayout ? 0 : shouldReverse ? 1 : 2,
        }}>
          {preferenceSettings.acceptAll && (
            <ButtonWrapper sx={{ 
              width: buttonDimensions.primary.width, 
              height: buttonDimensions.primary.height,
              '& > button': {
                width: '100% !important',
                height: '100% !important'
              }
            }}>
              <ButtonCommon
                onClick={() => onClose()}
                sx={primaryButtonStyle}
              >
                {preferenceSettings.acceptAllText || "Accept All"}
              </ButtonCommon>
            </ButtonWrapper>
          )}
          {preferenceSettings.acceptNecessary && (
            <ButtonWrapper sx={{ 
              width: buttonDimensions.primary.width, 
              height: buttonDimensions.primary.height,
              
              '& > button': {
                width: '100% !important',
                height: '100% !important'
              }
            }}>
              <ButtonCommon
                onClick={() => onClose()}
                sx={primaryButtonStyle}
              >
                {preferenceSettings.acceptNecessaryText || "Reject"}
              </ButtonCommon>
            </ButtonWrapper>
          )}
        </Box>
  
        {/* Right side - Save Preferences button */}
        {preferenceSettings.savePreferences && (
          <ButtonWrapper sx={{ 
            width: buttonDimensions.secondary.width, 
            height: buttonDimensions.secondary.height,
            order: isBarLayout ? 0 : shouldReverse ? 2 : 1,
            marginLeft: isBarLayout ? 0 : 'auto',  // Push to right in box layout
            '& > button': {
              width: '100% !important',
              height: '100% !important'
            }
          }}>
            <ButtonCommon
              onClick={() => onClose()}
              sx={primaryButtonStyle}
            >
              {preferenceSettings.savePreferencesText || "Save Preferences"}
            </ButtonCommon>
          </ButtonWrapper>
        )}
      </Box>
    );
  };


  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth={false}
      // fullWidth
      fullScreen={isBarLayout}
      PaperProps={{
        sx: {
          ...getLayoutStyle(),
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          bgcolor: isDarkTheme 
        ? '#121212 !important'
        : `${themeSettings.background} !important`,
      m: isBarLayout ? 0 : 2,
      boxShadow: isDarkTheme
        ? '0px 0px 20px rgba(0,0,0,0.5)'
        : undefined,
      border: isDarkTheme
        ? '1px solid rgba(255,255,255,0.1)'
        : 'none',
        borderRadius: `${themeSettings.modalBorderRadius || '0.5'}rem !important`,
        }
      }}
    >
      {/* Close Button */}
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 16,
          top: 16,
          color: themeSettings.text,
          zIndex: 1,
          '&:hover': {
        backgroundColor: 'rgba(255,255,255,0.1)' // Better hover effect for dark mode
      }
        }}
      >
        <CloseIcon />
      </IconButton>

      {/* Header */}
      <Box sx={{ mb: 2 }}>
        <Box sx={{ textAlign: 'center' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <BannerPrefrenceLogo
              style={{
                height: preferenceSettings.layout === "Bar" ? "100px" : "200px",
                width: "auto",
                filter: isDarkTheme ? 'brightness(0.9)' : 'none'
              }}
            />
          </Box>
          <Typography 
            variant="h5" 
            sx={{ 
              fontWeight: 600, 
              mb: 1,
              color: themeSettings.heading,
               textShadow: isDarkTheme ? '0px 0px 1px rgba(255,255,255,0.1)' : 'none'
            }}
          >
            {preferenceSettings.title || "User experience"}
          </Typography>
          <Typography sx={{ color: themeSettings.text,
            opacity: isDarkTheme ? 0.87 : 1 // Better readability in dark mode
          }}>
            {preferenceSettings.description || "Our mission is to help you have the most delightful experience while browsing our pages."}
          </Typography>
        </Box>
      </Box>

      {/* Scrollable Content */}
      <Box sx={{
        flex: 1,
        overflowY: 'auto',
        px: 3,
        pb: isBarLayout ? 20 : 2,
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme => 
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)',
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-track': {
      backgroundColor: theme => 
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.05)',
    }
      }}>
        {cookieCategories.map((category) => (
          <Box key={category.id} sx={{ mb: 3 }}>
            <CustomAccordion
              expanded={expandedCategory === category.id}
              onChange={(event, isExpanded) => setExpandedCategory(isExpanded ? category.id : null)}
            >
              <CustomAccordionSummary 
                expandIcon={<ExpandMoreIcon sx={{ color: themeSettings.text }} />}
              >
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  alignItems: 'center', 
                  width: '100%' 
                }}>
                  <Typography 
                    variant="subtitle1" 
                    sx={{ 
                      fontWeight: 600,
                      color: themeSettings.heading 
                    }}
                  >
                    {category.title}
                  </Typography>
                  <CustomSwitch 
                    checked={preferences[category.id]}
                    onChange={() => {
                      setPreferences(prev => ({
                        ...prev,
                        [category.id]: !prev[category.id]
                      }));
                    }}
                    disabled={category.isRequired}
                  />
                </Box>
              </CustomAccordionSummary>
              
              <AccordionDetails>
                <Typography sx={{ color: themeSettings.text, mb: 3 }}>
                  {category.description}
                </Typography>

                {shouldShowNestedContent(category) && category.subCategories?.map((subCategory) => (
                  <CustomAccordion
                    key={subCategory.id}
                    expanded={expandedSubCategory === subCategory.id}
                    onChange={(event, isExpanded) => 
                      setExpandedSubCategory(isExpanded ? subCategory.id : null)
                    }
                  >
                    <CustomAccordionSummary 
                      expandIcon={<ExpandMoreIcon sx={{ color: themeSettings.text }} />}
                    >
                      <Typography 
                        variant="subtitle1" 
                        sx={{ 
                          fontWeight: 600,
                          color: themeSettings.heading 
                        }}
                      >
                        {subCategory.title}
                      </Typography>
                    </CustomAccordionSummary>
                    
                    <AccordionDetails>
                      {preferenceSettings.displayCookies && (
                        <Paper 
                          sx={{ 
                            bgcolor: 'rgba(0,0,0,0.02)',
                            color: themeSettings.text 
                          }} 
                          elevation={0}
                        >
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ color: themeSettings.heading }}>Name</TableCell>
                                <TableCell sx={{ color: themeSettings.heading }}>Duration</TableCell>
                                <TableCell align="right"></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {subCategory.cookies?.map((cookie, index) => (
                                <TableRow key={index}>
                                  <TableCell sx={{ color: themeSettings.text }}>{cookie.name}</TableCell>
                                  <TableCell sx={{ color: themeSettings.text }}>{cookie.duration}</TableCell>
                                  <TableCell align="right">
                                    <IconButton size="small">
                                      <InfoOutlinedIcon sx={{ color: themeSettings.text }} />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Paper>
                      )}
                    </AccordionDetails>
                  </CustomAccordion>
                ))}
              </AccordionDetails>
            </CustomAccordion>
          </Box>
        ))}

        {/* More Information Section */}
         <Box sx={{ mt: 4, mb: 3 }}>
           <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2,color: themeSettings.heading  }}>
           {preferenceSettings.moreInfoTitle|| "More Information"}
      
           </Typography>
           <Typography color="text.secondary" sx={{ mb: 2 ,color: themeSettings.text }}>
           {preferenceSettings.moreInfoContent || "For any queries in relation to our policy on cookies and your choices, please contact us."}
           </Typography>
          <Typography color="text.secondary" sx={{ mb: 2,color: themeSettings.text  }}>
             Your Consent ID: <br />
             3e594f4e-b6d6-11ef-b535-b5cfaa07b6af
           </Typography>
         </Box>

         {/* Powered By Logo */}
         <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // gap: 3,
            py: 2,
          }}
        >
          {/* <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1.2rem",
              color:themeSettings.darkmode === 'dark' 
              ? '#FFFFFF' 
              : (themeSettings.text || "#555555"),
            }}
          >
            Consents certified by
          </Typography> */}
          <Typography
                            sx={{
                              fontWeight: "bold",
                              color: themeSettings.darkmode === 'dark' 
                              ? '#FFFFFF' 
                              : (themeSettings.text || "#555555"),
                              fontSize: "1.2rem",
                            }}>
                            {bannerSettings.footerContent}
                            </Typography>
        </Box>
      </Box>

      {/* Footer with action buttons */}
      <Box sx={{ 
      p: 2, 
      borderTop: theme => `1px solid ${
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'
      }`,
      display: 'flex',
      flexDirection: isBarLayout ? 'column' : 'row',
      gap: 2,
      mt: 'auto',
      bgcolor: isDarkTheme ? 'rgba(0,0,0,0.2)' : 'transparent'
    }}>
      {renderButtons()}
      </Box>
    </Dialog>
  );
};

export default ManagePreferencesModel;