import React from "react";
import {
  Box,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  styled,
  tableCellClasses,
  Typography,
} from "@mui/material";

const GrantedConsent = ({ acceptedServices }) => {
  // Styled components for table
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F9FAFB",
      color: "#000000",
      fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const renderCookiesTable = (service) => {
    return (
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{ borderRadius: "12px" }}
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell>Duration</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {service.cookies && service.cookies.length > 0 ? (
              (Array?.isArray(service.cookies) ? service.cookies : []).map(
                (cookie, index) => (
                  <TableRow key={index} hover sx={{ cursor: "pointer" }}>
                    <StyledTableCell>{cookie.name}</StyledTableCell>
                    <StyledTableCell>
                      {cookie.description || "No description"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {cookie.duration || "No duration"}
                    </StyledTableCell>
                  </TableRow>
                )
              )
            ) : (
              <TableRow>
                <StyledTableCell colSpan={3} align="center">
                  No cookies are available.
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box>
      {Object.keys(acceptedServices || {}).map((category) => {
        const serviceCategory = acceptedServices[category];

        return (
          <Box key={category} sx={{ mb: 4 }}>
            {(Array.isArray(serviceCategory) ? serviceCategory : []).map(
              (service) => (
                <Box key={service.id} sx={{ mb: 4 }}>
                  <Typography variant="h6" fontWeight="bold" sx={{ mb: 1 }}>
                    {service.title}
                  </Typography>
                  {renderCookiesTable(service)}
                </Box>
              )
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default GrantedConsent;
