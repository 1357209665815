import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Typography,
  Grid,
  Select,
  LinearProgress,
  MenuItem,
  Divider,
} from "@mui/material";
import {
  RemoveRedEyeOutlined,
  PersonOutlineOutlined,
  CheckCircleOutline,
  TrendingUpOutlined,
  TrendingDownOutlined,
  AutorenewRounded,
} from "@mui/icons-material";
import {
  LineChart,
  Line,
  PieChart,
  Pie,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
  Legend,
} from "recharts";
import CommonHeader from "../../CommonComponent/CommonHeader";
import {
  getDashboardConsents,
  getDashboardCountires,
  getDashboardDevices,
  getDashboardStats,
  getDashboardVisitors,
} from "../../api/dashboard";
import Loader from "../../CommonComponent/Loader";
import OnboardingProgress from "./OnboardingProgress";
import { fetchUserAttributes } from "@aws-amplify/auth";
import Flag from "react-world-flags";

const Dashboard = () => {
  const [countries, setCountries] = useState([]);
  const [stats, setStats] = useState({});
  const [consents, setConsents] = useState([]);
  const [visitors, setVisitors] = useState([]);
  const [device, setDevice] = useState([]);
  const [selectedDays, setSelectedDays] = useState(30); // Default to 30 days

  const [loading, setLoading] = useState(true);
  const [isOnboardingRequired, setIsOnboardingRequired] = useState(false);

  const fetchAllCountries = async () => {
    try {
      setLoading(true);
      const countries = await getDashboardCountires(selectedDays);
      if (!countries?.data || countries.data.length === 0) {
        setIsOnboardingRequired(true);
      }
      setCountries(countries?.data);
    } catch (error) {
      setIsOnboardingRequired(true); // Show onboarding if there's an error
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllStats = async () => {
    try {
      setLoading(true);
      const countries = await getDashboardStats(selectedDays);
      if (!countries?.data || Object.keys(countries.data).length === 0) {
        setIsOnboardingRequired(true);
      }
      setStats(countries?.data);
    } catch (error) {
      setIsOnboardingRequired(true); // Show onboarding if there's an error
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllDevices = async () => {
    try {
      setLoading(true);
      const response = await getDashboardDevices(selectedDays);
      if (!response?.data || response.data.length === 0) {
        setIsOnboardingRequired(true);
      }
      const transformedData = Array.isArray(response?.data)
        ? response?.data
        : []?.map((item) => ({
            name: item.name,
            value: item.value,
          }));

      setDevice(transformedData);
    } catch (error) {
      setIsOnboardingRequired(true);
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllConsent = async () => {
    try {
      setLoading(true);
      const response = await getDashboardConsents(selectedDays);
      if (!response?.data || response.data.length === 0) {
        setIsOnboardingRequired(true);
      }

      const transformedData = Array.isArray(response?.data)
        ? response?.data
        : []?.map((item) => ({
            date: item.date,
            fullConsents: parseInt(item.full_consent),
            customConsents: parseInt(item.custom_consent),
            onlyNecessary: parseInt(item.only_necessary),
            noDecision: parseInt(item.no_decision),
          }));
      setConsents(transformedData);
    } catch (error) {
      setIsOnboardingRequired(true);
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  console.log(consents,"consents");
  

  const fetchAllVisitors = async () => {
    try {
      setLoading(true);
      const response = await getDashboardVisitors(selectedDays);
      if (!response?.data || response.data.length === 0) {
        setIsOnboardingRequired(true);
      }
      const transformedData = (
        Array.isArray(response?.data) ? response?.data : []
      )?.map((item) => ({
        date: item.date,
        pageViews: parseInt(item.pageviews),
        uniqueVisitors: parseInt(item.unique_visitors),
      }));
      setVisitors(transformedData);
    } catch (error) {
      setIsOnboardingRequired(true);
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchWebsiteById = async () => {
    try {
      setLoading(true);
      const user = await fetchUserAttributes();
      const websiteId = user?.website;
      localStorage.setItem("website_id", websiteId);
    } catch (error) {
      console.error("Error fetching websites:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchWebsiteById(); // Wait for fetchWebsiteById to complete
      fetchAllCountries();
      fetchAllStats();
      fetchAllDevices();
      fetchAllConsent();
      fetchAllVisitors();
    };

    fetchData();
    // eslint-disable-next-line
  }, [selectedDays]);

  const refreshData = () => {
    fetchAllCountries();
    fetchAllStats();
    fetchAllDevices();
    fetchAllConsent();
    fetchAllVisitors();
  };

  console.log(countries, stats, device, consents, visitors, "====data");

  const StatCard = ({ icon, title, value, growth }) => (
    <Card
      variant="outlined"
      sx={{
        p: 3,
        height: "100%",
        borderRadius: 2,
        bgcolor: "white",
        pb: 0,
        pl: 0,
        pr: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
          pl: 3,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box
            sx={{
              width: 40,
              height: 40,
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "rgba(0, 200, 150, 0.1)",
              mr: 2,
            }}
          >
            {icon}
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{ fontWeight: "400", color: "#989898" }}
            >
              {title}
            </Typography>
            <Typography
              variant="h6"
              sx={{ mb: 1, fontWeight: "bold", color: "#525455" }}
            >
              {value?.toLocaleString()}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            background:
              growth > 0 ? "#F0FFFC" : growth < 0 ? "#FFEFF2" : "#E0E0E0", // Grey background for growth = 0
            padding: "4px 8px",
            borderRadius: 2,
            mr: 2,
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color:
                growth > 0 ? "#00B894" : growth < 0 ? "#FF2E57" : "#9E9E9E", // Grey color for growth = 0
              fontWeight: "bold",
            }}
          >
            {growth > 0 ? (
              <TrendingUpOutlined fontSize="8px" />
            ) : growth < 0 ? (
              <TrendingDownOutlined fontSize="8px" />
            ) : (
              <TrendingUpOutlined fontSize="8px" /> // You can use any icon here for growth = 0 or leave it empty
            )}
            {growth !== 0 && (growth > 0 ? "+" : "")}
            {growth}%
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background: "#F9FAFB",
          pl: 3,
        }}
      >
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ fontSize: "0.875rem" }}
        >
          {`last ${selectedDays} days`}
        </Typography>
      </Box>
    </Card>
  );

  if (loading) {
    <Loader />;
  }

  const getLast30DaysRange = () => {
    const currentDate = new Date();
    const pastDate = new Date(currentDate);
    pastDate.setDate(currentDate.getDate() - selectedDays);

    const startDate = pastDate?.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "UTC",
    });
    const endDate = currentDate?.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "UTC",
    });

    return `${startDate} - ${endDate}`;
  };

  const handleTimePeriodChange = (event) => {
    const newPeriod = event.target.value;
    setSelectedDays(newPeriod); // Update the selected days
  };

  console.log(isOnboardingRequired, "isOnboardingRequired");

  return (
    <Box sx={{ display: "flex" }}>
      <Box component="main" sx={{ flexGrow: 1 }}>
        {isOnboardingRequired ? (
          <OnboardingProgress />
        ) : (
          <>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}
            >
              <CommonHeader
                title="Dashboard"
                subTitle={
                  <>
                    {getLast30DaysRange()}{" "}
                    <AutorenewRounded
                      onClick={refreshData}
                      sx={{ cursor: "pointer" }}
                    />{" "}
                  </>
                }
              />
              <Box sx={{ display: "flex" }}>
                <Select
                  value={selectedDays}
                  onChange={handleTimePeriodChange} // Add this handler
                  displayEmpty
                  inputProps={{ "aria-label": "Select time period" }}
                  sx={{
                    textTransform: "capitalize",
                    color: "black",
                    minWidth: 120,
                    height: 40,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "green",
                      },
                      "&:focus fieldset": {
                        borderColor: "green",
                      },
                    },
                  }}
                >
                  <MenuItem value={30}>Last 30 Days</MenuItem>
                  <Divider />
                  <MenuItem value={60}>Last 60 Days</MenuItem>
                  <Divider />
                  <MenuItem value={90}>Last 90 Days</MenuItem>
                </Select>
              </Box>
            </Box>

            {loading ? (
              <Loader />
            ) : (
              <>
                <Grid container spacing={3} sx={{ mb: 3 }}>
                  <Grid item xs={12} md={4}>
                    <StatCard
                      icon={<RemoveRedEyeOutlined sx={{ color: "#00B894" }} />}
                      title="Page Views"
                      // value={stats?.pageviews}
                      value="Coming Soon..."
                      growth={stats?.pageviews_difference}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <StatCard
                      icon={<PersonOutlineOutlined sx={{ color: "#00B894" }} />}
                      title="Unique Visitors"
                      value={stats?.unique_visitors}
                      growth={stats?.unique_visitors_difference}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <StatCard
                      icon={<CheckCircleOutline sx={{ color: "#00B894" }} />}
                      title="Consents"
                      value={stats?.consents}
                      growth={stats?.consents_difference}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={8}>
                    <Card
                      variant="outlined"
                      sx={{
                        p: 3,
                        height: "100%",
                        borderRadius: 2,
                        border: "1px solid #D7D7D7",
                      }}
                    >
                      <Typography
                        variant="h6"
                        gutterBottom
                        fontWeight="bold"
                        sx={{ color: "#525455", mb: 2 }}
                      >
                        Visitors
                      </Typography>
                      <LineChart
                        width={700}
                        height={250}
                        data={visitors}
                        margin={{
                          top: 8,
                          right: 30,
                          left: 20,
                          bottom: 5,
                          color: "#525455",
                        }}
                      >
                        <XAxis
                          dataKey="date"
                          stroke="#999"
                          tick={{
                            fill: "#525455",
                            fontSize: 12,
                            fontWeight: 400,
                          }}
                        />
                        <YAxis
                          stroke="#999"
                          tick={{
                            fill: "#525455",
                            fontSize: 12,
                            fontWeight: 400,
                          }}
                        />
                        <Tooltip />
                        {/* <Line
                          type="monotone"
                          dataKey="pageViews"
                          stroke="#00B894"
                          strokeWidth={1}
                          dot={true}
                        /> */}
                        <Line
                          type="monotone"
                          dataKey="uniqueVisitors"
                          stroke="#3CC3DF"
                          strokeWidth={1}
                          dot={false}
                          strokeDasharray="5 5"
                        />
                        <Legend
                          layout="horizontal"
                          verticalAlign="top"
                          align="right"
                        />
                      </LineChart>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Card
                      variant="outlined"
                      sx={{
                        p: 3,
                        height: "100%",
                        borderRadius: 2,
                        border: "1px solid #D7D7D7",
                      }}
                    >
                      <Typography
                        variant="h6"
                        gutterBottom
                        fontWeight="bold"
                        sx={{ color: "#525455", mb: 2 }}
                      >
                        Countries
                      </Typography>
                      {(Array.isArray(countries) ? countries : []).map(
                        (country) => {
                          const percentage =
                            typeof country.percentage === "string"
                              ? parseFloat(country.percentage.replace("%", ""))
                              : country.percentage;
                          return (
                            <Box
                              key={country.name}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: 2,
                                gap: 2,
                              }}
                            >
                              {/* Left Section */}
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  flex: 1,
                                  gap: 1,
                                }}
                              >
                                <Box
                                  sx={{
                                    width: 35,
                                    height: 35,
                                    borderRadius: "50%",
                                    border: "1px solid #DADADA",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Flag
                                    code={country.iso_code}
                                    alt={`${country.iso_code} flag`}
                                    style={{ width: 16, height: 16 }}
                                  />
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                  <Typography
                                    variant="body2"
                                    color="#525455"
                                    sx={{ fontWeight: 400, marginBottom: 0.5 }}
                                  >
                                    {country.name}
                                  </Typography>
                                  <LinearProgress
                                    variant="determinate"
                                    value={percentage}
                                    sx={{
                                      height: 4,
                                      borderRadius: 3,
                                      backgroundColor: "#F0F0F0",
                                      "& .MuiLinearProgress-bar": {
                                        backgroundColor: "#00B894",
                                      },
                                    }}
                                  />
                                </Box>
                              </Box>

                              {/* Right Section */}
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    background:
                                      country?.percentage > 0
                                        ? "#F0FFFC"
                                        : country?.percentage < 0
                                        ? "#FFEFF2"
                                        : "#E0E0E0", // Grey background for growth = 0
                                    padding: "4px 8px",
                                    borderRadius: 2,
                                    mr: 2,
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color:
                                        country?.percentage > 0
                                          ? "#00B894"
                                          : country?.percentage < 0
                                          ? "#FF2E57"
                                          : "#9E9E9E", // Grey color for growth = 0
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {country?.percentage > 0 ? (
                                      <TrendingUpOutlined fontSize="8px" />
                                    ) : country?.percentage < 0 ? (
                                      <TrendingDownOutlined fontSize="8px" />
                                    ) : (
                                      <TrendingUpOutlined fontSize="8px" /> // You can use any icon here for growth = 0 or leave it empty
                                    )}
                                    {country?.percentage !== 0 &&
                                      (country?.percentage > 0 ? "+" : "")}
                                    {country?.percentage}%
                                  </Typography>
                                </Box>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontWeight: 500,
                                    color: "#525455",
                                  }}
                                >
                                  {country?.count}
                                </Typography>
                              </Box>
                            </Box>
                          );
                        }
                      )}
                    </Card>
                  </Grid>

                  {/* Consents Chart */}
                  <Grid item xs={12} md={8}>
                    <Card
                      variant="outlined"
                      sx={{
                        p: 3,
                        height: "100%",
                        borderRadius: 2,
                        border: "1px solid #D7D7D7",
                      }}
                    >
                      <Typography
                        variant="h6"
                        gutterBottom
                        fontWeight="bold"
                        sx={{ color: "#525455", mb: 2 }}
                      >
                        Consents
                      </Typography>
                      <LineChart
                        width={700}
                        height={300}
                        data={consents}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                      >
                        <XAxis
                          dataKey="date"
                          stroke="#999"
                          tick={{
                            fill: "#525455",
                            fontSize: 12,
                            fontWeight: 400,
                          }}
                        />
                        <YAxis
                          stroke="#999"
                          tick={{
                            fill: "#525455",
                            fontSize: 12,
                            fontWeight: 400,
                          }}
                        />
                        <Tooltip />
                        <Line
                          type="monotone"
                          dataKey="full_consent"
                          stroke="#6366f1"
                          strokeWidth={1}
                          dot={false}
                        />
                        <Line
                          type="monotone"
                          dataKey="custom_consent"
                          stroke="#f59e0b"
                          strokeWidth={1}
                          dot={false}
                        />
                        <Line
                          type="monotone"
                          dataKey="only_necessary"
                          stroke="#10b981"
                          strokeWidth={1}
                          dot={false}
                        />
                        <Line
                          type="monotone"
                          dataKey="no_decision"
                          stroke="#ef4444"
                          strokeWidth={1}
                          dot={false}
                        />
                        <Legend
                          layout="horizontal"
                          verticalAlign="top"
                          align="right"
                        />
                      </LineChart>
                    </Card>
                  </Grid>

                  {/* Devices Chart */}
                  <Grid item xs={12} md={4}>
                    <Card
                      variant="outlined"
                      sx={{
                        p: 3,
                        height: "100%",
                        borderRadius: 2,
                        border: "1px solid #D7D7D7",
                      }}
                    >
                      <Typography
                        variant="h6"
                        gutterBottom
                        fontWeight="bold"
                        sx={{ color: "#525455", mb: 2 }}
                      >
                        Devices
                      </Typography>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <PieChart width={400} height={300}>
                          {/* Main Pie Chart */}
                          <Pie
                            data={device}
                            cx="50%"
                            cy="50%"
                            innerRadius={80}
                            outerRadius={120}
                            fill="#8884d8"
                            dataKey="value"
                            nameKey="name"
                            label={false}
                          >
                            {(Array.isArray(device) ? device : []).map(
                              (_, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={
                                    [
                                      "#10b981",
                                      "#6366f1",
                                      "#1E90FF",
                                      "#FFA502",
                                      "#D7D7D7",
                                    ][index]
                                  }
                                />
                              )
                            )}
                          </Pie>
                          <Tooltip />
                          <Legend
                            verticalAlign="top"
                            align="left"
                            iconType="circle"
                            formatter={(value) => (
                              <span
                                style={{ color: "#525455", fontSize: "14px" }}
                              >
                                {value}
                              </span>
                            )}
                            wrapperStyle={{
                              display: "flex",
                              flexWrap: "wrap",
                              paddingLeft: "10px",
                              justifyContent: "flex-start",
                              gap: "10px",
                            }}
                          />
                        </PieChart>
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default Dashboard;
