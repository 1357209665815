import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  InputLabel,
  Paper,
  CircularProgress,
} from "@mui/material";
import myIcon from "../assets/svg/logo.svg";
import { useNavigate } from "react-router-dom";
import {
  fetchUserAttributes,
  getCurrentUser,
  updateUserAttributes,
} from "@aws-amplify/auth";
import { createWebsite, startScan } from "../api/websiteService";
import { createBanner } from "../api/bannerService";
import { useDispatch } from "react-redux";
import { setScanData } from "../store/scanSlice";
import { showErrorToast, showSuccessToast } from "../toastUtils";

const AddWebAndScanPage = () => {
  const [url, setUrl] = useState("");
  // const [editMode, setEditMode] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [addWebsiteData, setAddWebsiteData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isLoadingScan, setIsLoadingScan] = useState(false);

  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");

  const dispatch = useDispatch();

  const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;

  const validateUrl = (input) => {
    if (!input) {
      return "Please enter a website URL.";
    }
    if (!urlRegex.test(input)) {
      return "URL must start with http:// or https://";
    }
    return null;
  };

  const fetchCurrentUser = async () => {
    const data = await getCurrentUser();
    const currentUser = await fetchUserAttributes();

    localStorage.setItem("organization_id", parseInt(currentUser?.profile));
    localStorage.setItem("user_id", data.userId);
  };

  useEffect(() => {
    fetchCurrentUser();
    // eslint-disable-next-line
  }, []);

  // const handleAddWebsite = async () => {
  //   try {
  //     setLoading(true);
  //     const validationError = validateUrl(url);
  //     if (validationError) {
  //       setError(validationError);
  //       return;
  //     }
  //     const res = await createWebsite(url, userId);
  //     console.log(res, "===add website");

  //     setAddWebsiteData(res?.data);

  //     if (res?.status_code === 201) {
  //       setIsSubmitted(true);
  //     } else if (res?.status_code === 400) {
  //       setError(res.message);
  //     }
  //   } catch (error) {
  //     setError(error.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const handleAddWebsite = async () => {
    try {
      setLoading(true);
      const validationError = validateUrl(url);
      if (validationError) {
        setError(validationError);
        return;
      }

      // First create website
      const websiteRes = await createWebsite(url, userId);

      setAddWebsiteData(websiteRes?.data);

      if (websiteRes?.status_code === 201) {
        // After successful website creation, create default banner
        try {
          // Get the new website ID from the response
          const newWebsiteId = websiteRes.data.website_id;
          const organizationId = websiteRes.data.organization_id;

          // Store these IDs in localStorage for the banner creation
          localStorage.setItem("website_id", newWebsiteId);
          localStorage.setItem("organization_id_str", organizationId);

          // Prepare banner payload
          const bannerPayload = {
            banner_html_data: {
              name: "Default Banner",
              enabled: true,
              acceptAll: true,
              dark_mode: "light",
              target_type: "WorldWide",
              swap_buttons: false,
              button_colors: {
                bg: "#FFFFFF",
                link_color: "#FFFFFF",
                text_color: "#717171",
                heading_color: "#333333",
                btn_primary_bg: "#717171",
                dark_mode_link: "#FFFFFF",
                dark_mode_text: "#717171",
                btn_primary_color: "#333333",
                dark_mode_heading: "#333333",
                secondary_button_bg: "#717171",
                dark_mode_background: "#FFFFFF",
                secondary_button_text: "#FFFFFF",
                dark_mode_primary_button_bg: "#FFFFFF",
                dark_mode_primary_button_text: "#333333",
                dark_mode_secondary_button_bg: "#717171",
                dark_mode_secondary_button_text: "#FFFFFF",
              },
              consent_title: "We use cookies",
              force_consent: true,
              logo_url_dark: "",
              show_services: true,
              consent_layout: "Cloud",
              forced_consent: false,
              logo_url_light: "",
              settings_title: "Cookie Usage",
              acceptNecessary: true,
              savePreferences: true,
              settings_layout: "Box",
              consent_position: "BottomCenter",
              default_language: "en",
              btn_border_radius: "2.0",
              settings_more_info:
                "For any queries in relation to our policy on cookies and your choices, please contact us.",
              consent_description:
                "We respect your privacy and aim for the best website experience in compliance with the data privacy laws. We use cookies to personalize content and ads, and to analyze our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you have provided to them or that they have collected from your use of their services. While allowing cookies enables a tailored experience, disabling them may reduce personalization. Feel free to update your preferences anytime.",
              language_autodetect: "document",
              modal_border_radius: "1.5",
              settings_position_x: "Left",
              equal_weight_buttons: true,
              settings_description:
                "We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose to accept cookies or opt-out whenever you want. For more details, read the full privacy policy.",
              consent_duration_days: "182",
              show_banner_close_btn: true,
              banner_accept_btn_text: "Accept All",
              show_banner_accept_btn: true,
              consent_button_placement: "Stacked",
              settings_more_info_title: "More Information",
              preferences_control_level: "Category & Services",
              save_preferences_btn_text: "Save Preference",
              show_save_preferences_btn: true,
              banner_preferences_btn_text: "Manage Preference",
              preferences_accept_btn_text: "Accept All",
              show_banner_preferences_btn: true,
              show_preferences_accept_btn: true,
              consent_additional_description:
                "Concent Management Powered By Data Privacy Cloud",
              show_floating_preferences_icon: true,
              banner_accept_necessary_btn_text: "Reject All",
              show_banner_accept_necessary_btn: true,
              floating_preferences_icon_position: "Bottom Left",
              preferences_accept_necessary_btn_text: "Reject All",
              show_preferences_accept_necessary_btn: true,
            },
          };

          // Create banner
          const bannerRes = await createBanner(bannerPayload);
          console.log("Default banner created:", bannerRes);
        } catch (bannerError) {
          console.error("Error creating default banner:", bannerError);
          // Optionally show a toast message about banner creation failure
          // but don't block the website creation flow
        }

        setIsSubmitted(true);
      } else if (websiteRes?.status_code === 400) {
        setError(websiteRes.message);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleScanWebsite = async () => {
    setIsLoadingScan(true);
    if (url) {
      try {
        const res = await startScan(addWebsiteData?.website_id, url);

        dispatch(setScanData(res?.data));
        if (res?.data && res.data.website_id !== undefined) {
          try {
            const websiteId = res.data.website_id.toString();
            await updateUserAttributes({
              userAttributes: {
                website: websiteId,
              },
            });
            showSuccessToast("Website scan successfully.");
          } catch (error) {
            showErrorToast("Error updating user attributes:", error);
          }
        } else {
          console.error(
            "website_id is undefined or missing in the response data."
          );
        }
        setIsLoadingScan(false);
        setLoading(false);
        navigate("/website-scan");
      } catch (err) {
        console.error("Error starting scan:", err);
        setError("An error occurred while scanning. Please try again.");
        setIsLoadingScan(false);
      }
    } else {
      setError("Please add a website before scanning.");
      setLoading(false);
    }
  };

  // const handleCancelEdit = () => {
  //   setEditMode(true);
  //   setIsSubmitted(false);
  // };

  const handleUrlChange = (e) => {
    const input = e.target.value;
    setUrl(input);
    const validationError = validateUrl(input);
    setError(validationError);
  };

  return (
    <Container
      component="main"
      maxWidth={false}
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#F8F8F8",
        padding: 3,
      }}
    >
      {/* Logo at the top */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 4,
        }}
      >
        <img src={myIcon} alt="logo" width="250" height="50" />
      </Box>

      {/* Form Section */}
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: 450,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* Text above the form */}
          <Typography
            variant="h5"
            sx={{
              mb: 2,
              color: "#5F5F5F",
              fontWeight: 600,
              fontFamily: "DM Sans",
            }}
          >
            {isSubmitted ? "Scan website" : "Add a new website"}
          </Typography>

          <Typography
            variant="subtitle1"
            sx={{
              mb: 4,
              color: "#989898",
              fontSize: "14px",
              fontFamily: "DM Sans",
              p: "0px 40px",
              textAlign: "center",
            }}
          >
            {isSubmitted
              ? "We are scanning your website for cookies and other details."
              : "Let’s get your website set up with us. We will scan for cookies and automatically configure the banner and categories for your review and approval."}
          </Typography>
          <Paper
            elevation={2}
            sx={{
              width: "90%",
              p: 4,
              borderRadius: "12px",
              backgroundColor: "white",
              border: "none",
            }}
            variant="outlined"
          >
            <Box component="form">
              <InputLabel shrink htmlFor="websitedomain">
                Website Domain{" "}
              </InputLabel>

              <TextField
                margin="none" // Remove default margin
                size="small"
                required
                fullWidth
                value={url} // Controlled component
                onChange={handleUrlChange}
                name="websitedomain"
                type="text"
                disabled={isSubmitted}
                autoComplete="websitedomain"
                error={!!error} // Show error state
                helperText={error}
                sx={{
                  mb: 2, // Adjust bottom margin between the input and the next element
                  mt: 0, // No top margin
                }}
              />

              {!isSubmitted ? (
                <Button
                  size="small"
                  fullWidth
                  variant="outlined"
                  onClick={handleAddWebsite}
                  sx={{
                    mt: 2,
                    mb: 2,
                    "&:hover": {
                      backgroundColor: "#009B7D",
                      color: "white",
                    },
                    borderRadius: 1.5,
                    border: "2px solid #009B7D",
                    textTransform: "none",
                    fontSize: "1rem",
                    color: "#525455",
                    fontWeight: 700,
                  }}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={20} /> : "Add Website"}
                </Button>
              ) : (
                <Button
                  size="small"
                  fullWidth
                  variant="outlined"
                  onClick={handleScanWebsite}
                  sx={{
                    mt: 2,
                    mb: 2,
                    "&:hover": {
                      backgroundColor: "#009B7D",
                      color: "white",
                    },
                    borderRadius: 1.5,
                    border: "2px solid #009B7D",
                    textTransform: "none",
                    fontSize: "1rem",
                    color: "#525455",
                    fontWeight: 700,
                  }}
                  disabled={isLoadingScan}
                >
                  {isLoadingScan ? <CircularProgress size={20} /> : "Scan Now"}
                </Button>
              )}

              {/* <Button
                size="small"
                fullWidth
                onClick={handleCancelEdit}
                variant="underline"
                textTransform="capitalize"
              >
                Cancel
              </Button> */}
            </Box>
          </Paper>
        </Box>
      </Box>
    </Container>
  );
};

export default AddWebAndScanPage;
