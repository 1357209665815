import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import Sidebar from "../../CommonComponent/Sidebar";
import ButtonCommon from "../../CommonComponent/ButtonCommon";
import SearchIcon from "@mui/icons-material/Search";
import GeneralSettings from "./Sections/GeneralSettings";
import BannerSettings from "./Sections/BannerSettings";
import PreferenceModal from "./Sections/PreferenceModal";
import ThemeSettings from "./Sections/ThemeSettings";
import PreviewModal from "./Components/PreviewModal";
import {
  showSuccessToast,
  showErrorToast,
  showInfoToast,
} from "../../toastUtils";
import ManagePreferencesModel from "./Components/PreviewPreferenceModal";
import {
  createBanner,
  frontendToBackendMapper,
  getBannerById,
  updateBanner,
} from "../../api/bannerService";
const BannerPage = () => {
  const { bannerId } = useParams();
  const navigate = useNavigate();
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isManagePreferencesOpen, setIsManagePreferencesOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("General Settings");
  const [isLastActiveBanner, setIsLastActiveBanner] = useState(false);
  const [originalBannerData, setOriginalBannerData] = useState(null); //creat this for checking name is changed in updatebanner
  const [errors, setErrors] = useState({
    bannerName: false,
  });
  const [formData, setFormData] = useState({
    generalSettings: {
      bannerName: "",
      isActive: true,
      forcedConsent: true,
      consentDuration: "",
      targeting: "WorldWide",
    },
    bannerSettings: {
      title: "We use cookies",
      description:
        "We respect your privacy and aim for the best website experience in compliance with the data privacy laws. We use cookies to personalize content and ads, and to analyze our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you have provided to them or that they have collected from your use of their services. While allowing cookies enables a tailored experience, disabling them may reduce personalization. Feel free to update your preferences anytime.",
      footerContent: "Concent Management Powered By Data Privacy Cloud",
      layout: "Cloud",
      buttonPlacement: "Stacked",
      position: "BottomCenter",
      equalWeight: true,
      swapButtons: false,
      acceptAll: true,
      acceptNecessary: true,
      managePreferences: true,
      acceptAllText: "Accept All",
      acceptNecessaryText: "Reject All",
      managePreferencesText: "Manage Preference",
    },
    preferenceSettings: {
      enablePrivacyTrigger: true,
      privacyTriggerPosition: "Bottom Left",
      displayCookies: true,
      controlLevel: "Category & Services",
      title: "Cookie Usage",
      description:
        "We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose to accept cookies or opt-out whenever you want. For more details, read the full privacy policy.",
      moreInfoTitle: "More Information",
      moreInfoContent:
        "For any queries in relation to our policy on cookies and your choices, please contact us.",
      layout: "Box",
      modalAlignment: "Left",
      acceptAll: true,
      acceptNecessary: true,
      savePreferences: true,
      acceptAllText: "Accept All",
      acceptNecessaryText: "Reject All",
      savePreferencesText: "Save Preference",
    },
    themeSettings: {
      darkMode: "auto",
      background: "#FFFFFF",
      heading: "#333333",
      text: "#717171",
      link: "#FFFFFF",
      primaryButtonBg: "#717171",
      primaryButtonText: "#333333",
      secondaryButtonBg: "#717171",
      secondaryButtonText: "#FFFFFF",
      darkModeBackground: "#FFFFFF",
      darkModeHeading: "#333333",
      darkModeText: "#717171",
      darkModeLink: "#FFFFFF",
      darkModePrimaryButtonBg: "#FFFFFF",
      darkModePrimaryButtonText: "#333333",
      darkModeSecondaryButtonBg: "#717171",
      darkModeSecondaryButtonText: "#FFFFFF",
      modalBorderRadius: "1.5",
      buttonBorderRadius: "2.0",
      logoUrl: "",
      darkModeLogoUrl: "",
    },
  });

  // Save form data to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("bannerFormData", JSON.stringify(formData));
    console.log("Form Data Updated:", formData); // Debug log
  }, [formData]);

  const validateForm = () => {
    const newErrors = {
      bannerName:
        !formData.generalSettings?.bannerName ||
        formData.generalSettings.bannerName.trim() === "",
    };

    setErrors(newErrors);
    // Show toast message if banner name is empty
    if (newErrors.bannerName) {
      showErrorToast("Please enter a banner name");
    }
    return !Object.values(newErrors).some((error) => error);
  };

  const handleInputChange = (section, newData) => {
    console.log("Updating section:", section);
    console.log("New data:", newData);
    console.log("Current formData:", formData);
    if (section === "generalSettings" && "bannerName" in newData) {
      setErrors((prev) => ({ ...prev, bannerName: false }));
    }
    // If changing active status and this is the last active banner
    if (
      section === "generalSettings" &&
      "isActive" in newData &&
      isLastActiveBanner &&
      !newData.isActive
    ) {
      showInfoToast(
        "Cannot deactivate the last active banner. Please activate another banner first."
      );
      return;
    }

    // if (section === "generalSettings" && 'bannerName' in newData) {
    //   setErrors(prev => ({...prev, bannerName: false}));
    // }

    setFormData((prev) => {
      const updated = {
        ...prev,
        [section]: {
          ...prev[section],
          ...newData,
        },
      };
      console.log("Updated formData:", updated);
      return updated;
    });
  };

  const handlePreview = () => {
    setIsPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setIsPreviewOpen(false);
  };

  const handleOpenManagePreferences = () => {
    console.log("handleOpenManagePreferences called"); // Debug log
    setIsPreviewOpen(false);
    setIsManagePreferencesOpen(true);
  };

  const handleCloseManagePreferences = () => {
    setIsManagePreferencesOpen(false);
  };

  // Add loading state
  const [isSaving, setIsSaving] = useState(false);
  const handleSave = async () => {
    const isValid = validateForm();
    if (!isValid) {
      return;
    }
    // Check if trying to deactivate the last active banner
    if (isLastActiveBanner && !formData.generalSettings.isActive) {
      showInfoToast(
        "Cannot deactivate the last active banner. Please activate another banner first."
      );
      return;
    }
    setIsSaving(true);
    try {
      console.log("Saving banner with data:", formData);

      const mappedData = frontendToBackendMapper(formData);
      console.log("Mapped data:", mappedData);

      const payload = {
        banner_html_data: mappedData,
      };
      console.log("Final payload for update:", payload);
      let savedBannerData;
      if (bannerId) {
        // Update existing banner
        const updateData = { ...mappedData };

        // Check if name is unchanged
        if (updateData.name === originalBannerData?.banner_html_data?.name) {
          // Remove name from banner_html_data
          delete updateData.name;
        }

        const finalPayload = {
          banner_html_data: updateData,
        };

        // console.log("Update payload (before API call):", finalPayload);
        // console.log("Final payload after name removal:", finalPayload);
        savedBannerData = await Promise.race([
          updateBanner(bannerId, finalPayload), // You'll need to create this function
          new Promise((_, reject) =>
            setTimeout(
              () => reject(new Error("Request timed out after 30 seconds")),
              30000
            )
          ),
        ]);
      } else {
        // Create new banner
        savedBannerData = await Promise.race([
          createBanner(payload),
          new Promise((_, reject) =>
            setTimeout(
              () => reject(new Error("Request timed out after 30 seconds")),
              30000
            )
          ),
        ]);
      }


      // Update local state and storage
      setFormData(savedBannerData.data);
      localStorage.setItem("bannerFormData", JSON.stringify(savedBannerData));
      showSuccessToast(
        bannerId
          ? "Banner updated successfully!"
          : "Banner settings saved successfully!"
      );
      navigate("/banner");
    } catch (error) {
      console.error("Error saving banner:", {
        message: error.message,
        status: error.status_code || error.response?.status,
        success: error.success,
        timestamp: new Date().toISOString(),
      });

      // Show the exact error message without additional prefix if it's a 403
      if (error.status_code === 403 || error.success === false) {
        showErrorToast(error.message);
      } else {
        // For other errors, show with prefix
        const errorMessage = error.response?.data?.message || error.message;
        showErrorToast(`Failed to save banner settings: ${errorMessage}`);
      }
      return false;
    } finally {
      setIsSaving(false);
    }
  };

  /// fatching data from get banner by id
  useEffect(() => {
    console.log("useEffect triggered");
    console.log("Current bannerId:", bannerId);

    const fetchBannerData = async () => {
      if (bannerId) {
        try {
          const storedData = JSON.parse(localStorage.getItem("editBannerData"));
          setIsLastActiveBanner(storedData?.isLastActiveBanner || false);
          const response = await getBannerById(bannerId);

          console.log("API Response in banner page:", response); // Check raw API response

          if (response?.data?.banner_html_data) {
            setOriginalBannerData(response.data); // Store original data
            const banner_html_data = response.data.banner_html_data;
            console.log("Banner HTML Data:", banner_html_data); // Check extracted data
            // Map the received data to our form structure
            const mappedData = {
              generalSettings: {
                bannerName: banner_html_data.name || "",
                isActive: banner_html_data.enabled || false,
                forcedConsent: banner_html_data.forced_consent || true,
                consentDuration:
                  banner_html_data.consent_duration_days?.toString() || "",
                targeting: banner_html_data.target_type || "WorldWide",
              },
              bannerSettings: {
                title: banner_html_data.consent_title || "",
                description: banner_html_data.consent_description || "",
                footerContent:
                  banner_html_data.consent_additional_description || "",
                layout: banner_html_data.consent_layout,
                buttonPlacement:
                  banner_html_data.consent_button_placement || "Inline",
                position: banner_html_data.consent_position,
                equalWeight: banner_html_data.equal_weight_buttons || true,
                swapButtons: banner_html_data.swap_buttons || false,
                acceptAll: banner_html_data.show_banner_accept_btn || true,
                acceptNecessary: banner_html_data.accept_necessary || true,
                managePreferences: banner_html_data.manage_preferences || true,
                managePreferencesText:
                  banner_html_data.banner_preferences_btn_text ||
                  "Manage Preference",
                acceptAllText:
                  banner_html_data.banner_accept_btn_text || "Accept All",
                acceptNecessaryText:
                  banner_html_data.banner_accept_necessary_btn_text ||
                  "Reject All",
              },
              preferenceSettings: {
                enablePrivacyTrigger:
                  banner_html_data.enable_privacy_trigger || false,
                privacyTriggerPosition:
                  banner_html_data.privacy_trigger_position,
                displayCookies: banner_html_data.show_services || true,
                controlLevel:
                  banner_html_data.preferences_control_level,
                title: banner_html_data.settings_title || "",
                description: banner_html_data.settings_description || "",
                moreInfoTitle: banner_html_data.settings_more_info_title || "",
                moreInfoContent: banner_html_data.settings_more_info || "",
                layout: banner_html_data.settings_layout,
                modalAlignment: banner_html_data.settings_position_x,
                acceptAll: banner_html_data.preference_accept_all || true,
                acceptNecessary:
                  banner_html_data.preference_accept_necessary || true,
                savePreferences: banner_html_data.save_preferences || true,
                acceptAllText: banner_html_data.preferences_accept_btn_text,
                acceptNecessaryText:
                  banner_html_data.preferences_accept_necessary_btn_text,
                savePreferencesText: banner_html_data.save_preferences_btn_text,
              },
              themeSettings: {
                darkMode: banner_html_data.dark_mode,
                background: banner_html_data.button_colors?.bg || "#333333",
                heading:
                  banner_html_data.button_colors?.heading_color || "#000000",
                text: banner_html_data.button_colors?.text_color || "#333333",
                link: banner_html_data.button_colors?.link_color || "#FFFFFF",
                primaryButtonBg:
                  banner_html_data.button_colors?.btn_primary_bg || "#717171",
                primaryButtonText:
                  banner_html_data.button_colors?.btn_primary_color ||
                  "#ffffff",
                secondaryButtonBg:
                  banner_html_data.button_colors?.btn_secondary_bg || "#717171",
                secondaryButtonText:
                  banner_html_data.button_colors?.btn_secondary_color ||
                  "#FFFFFF",
                darkModeBackground:
                  banner_html_data.button_colors?.dark_bg || "#FFFFFF",
                darkModeHeading:
                  banner_html_data.button_colors?.dark_heading_color ||
                  "#333333",
                darkModeText:
                  banner_html_data.button_colors?.dark_text_color || "#717171",
                darkModeLink:
                  banner_html_data.button_colors?.dark_link_color || "#FFFFFF",
                darkModePrimaryButtonBg:
                  banner_html_data.button_colors?.dark_btn_primary_bg ||
                  "#FFFFFF",
                darkModePrimaryButtonText:
                  banner_html_data.button_colors?.dark_btn_primary_color ||
                  "#333333",
                darkModeSecondaryButtonBg:
                  banner_html_data.button_colors?.dark_btn_secondary_bg ||
                  "#717171",
                darkModeSecondaryButtonText:
                  banner_html_data.button_colors?.dark_btn_secondary_color ||
                  "#FFFFFF",
                modalBorderRadius:
                  banner_html_data.modal_border_radius?.toString() || "0.5",
                buttonBorderRadius: banner_html_data.btn_border_radius || "0.4",
                logoUrl: banner_html_data.logo_url || "",
                darkModeLogoUrl: banner_html_data.dark_mode_logo_url || "",
              },
            };
            console.log("Mapped Data:", mappedData); // Check mapped data
            setFormData(mappedData);
          }
        } catch (error) {
          console.error("Error fetching banner:", error);
        }
      }
    };

    fetchBannerData();
  }, [bannerId]);

  const sidebarTabs = [
    "General Settings",
    "Banner",
    "Manage Preference",
    "Theme",
    // "Language",
  ];

  const renderContent = () => {
    switch (activeTab) {
      case "General Settings":
        return (
          <GeneralSettings
            data={formData.generalSettings}
            onUpdate={(data) => handleInputChange("generalSettings", data)}
            errors={errors}
            isLastActiveBanner={isLastActiveBanner} // Add this line
          />
        );
      case "Banner":
        return (
          <BannerSettings
            data={formData.bannerSettings}
            onUpdate={(data) => handleInputChange("bannerSettings", data)}
          />
        );
      case "Manage Preference":
        return (
          <PreferenceModal
            data={formData.preferenceSettings}
            onUpdate={(data) => handleInputChange("preferenceSettings", data)}
          />
        );
      case "Theme":
        return (
          <ThemeSettings
            data={formData.themeSettings}
            onUpdate={(data) => handleInputChange("themeSettings", data)}
          />
        );
      // case "Language":
      //   return (
      //     <LanguageSettings
      //       data={formData.languageSettings}
      //       onUpdate={(data) => handleInputChange('languageSettings', data)}
      //     />
      //   );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        height: "calc(100vh - 80px)",
        position: "relative",
        overflow: "hidden",
        maxWidth: "100%",
      }}
    >
      <Box
        sx={{
          bgcolor: "white",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
          // borderBottom: "1px solid #e0e0e0",
          height: "64px",
          position: "sticky",
          top: 0,
          zIndex: 1000,
          flexShrink: 0,
        }}
      >
        <Typography>
          {/* {formData.generalSettings.bannerName || "New Banner"} */}
          <Typography variant="h5" component="h4">
            Setup Banner
          </Typography>
          <Typography variant="body2" sx={{ color: "#717171" }}>
            Customize your banner to match your website’s look and feel or make
            it multilingual as per your requirement.
          </Typography>
        </Typography>
        <Box sx={{ display: "flex", gap: 2 }}>
          <ButtonCommon onClick={handlePreview} icon={<SearchIcon />}>
            Preview
          </ButtonCommon>
          <ButtonCommon onClick={handleSave} disabled={isSaving}>
            {isSaving
              ? bannerId
                ? "Updating..."
                : "Saving..."
              : bannerId
              ? "Update"
              : "Save"}
          </ButtonCommon>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          height: "calc(100% - 64px)",
          position: "relative",
          "@media (max-width: 768px)": {
            // For mobile devices
            flexDirection: "column",
          },
          mt: 3,
        }}
      >
        <Box
          sx={{
            width: "250px",
            borderRight: "1px solid #e0e0e0",
            bgcolor: "white",
            flexShrink: 0,
            // overflow: "hidden",
            "@media (max-width: 768px)": {
              width: "100%",
              borderRight: "none",
              borderBottom: "1px solid #e0e0e0",
            },
          }}
        >
          <Sidebar
            tabs={sidebarTabs}
            activeTab={activeTab}
            onTabChange={setActiveTab}
          />
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            p: 3,
            overflowY: "auto",
            maxWidth: "800px", // Maximum width for content
            margin: "0 auto", // Center content on larger screens
            width: "100%",
            "@media (max-width: 768px)": {
              maxWidth: "100%",
              padding: 2,
            },
          }}
        >
          {renderContent()}
        </Box>
      </Box>

      {isPreviewOpen && (
        <PreviewModal
          onClose={handleClosePreview}
          isOpen={isPreviewOpen}
          data={formData}
          onManagePreferences={handleOpenManagePreferences}
        />
      )}

      {isManagePreferencesOpen && (
        <ManagePreferencesModel
          isOpen={isManagePreferencesOpen}
          onClose={handleCloseManagePreferences}
          data={formData}
        />
      )}
    </Box>
  );
};

export default BannerPage;
