// src/api/apiClient.js
import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});


apiClient.interceptors.request.use(
  (config) => {
    const organizationId = localStorage.getItem("organization_id_str");
    const userId = localStorage.getItem("user_id");
    const accessToken = localStorage.getItem(`CognitoIdentityServiceProvider.${process.env.REACT_APP_USER_POOL_CLIENT_ID}.${userId}.accessToken`);

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    if (organizationId) {
      config.headers["organization_id"] = organizationId;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default apiClient;