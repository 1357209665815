import React from "react";
import { Box } from "@mui/material";
import SidebarTab from "./SidebarTab";

const Sidebar = ({
  tabs,
  activeTab,
  onTabChange,
  headerHeight = 64,
  width = 250,
}) => {
  return (
    <Box
      sx={{
        width: width,
        borderRight: "1px solid #e0e0e0",
        bgcolor: "white",
        position: "sticky",
        top: headerHeight,
        alignSelf: "flex-start",
        height: `calc(100vh - ${headerHeight}px)`,
        overflowY: "hidden",
        pr: 0,
        flexShrink: 0, // Prevents sidebar from shrinking
      }}
    >
      {tabs.map((tab) => (
        <SidebarTab
          key={tab}
          label={tab}
          isActive={activeTab === tab}
          onClick={() => onTabChange(tab)}
        />
      ))}
    </Box>
  );
};

export default Sidebar;
