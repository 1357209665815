import React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { EastOutlined } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Styled components
const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  background: "#F9FAFB",
  padding: "8px 16px",
  borderRadius: "8px",
  border: "1px solid #EFEFEF",
  "& .MuiBreadcrumbs-separator": {
    color: "#6B7280",
    marginLeft: "8px",
    marginRight: "8px",
  },
  "& .MuiBreadcrumbs-ol": {
    flexWrap: "nowrap",
  },
  // Custom separator styling to match the screenshot
  "& .MuiBreadcrumbs-separator .MuiSvgIcon-root": {
    fontSize: "18px",
    color: "#9CA3AF", // Lighter gray color for the arrow
  },
  width: "100%",
}));

const BackButton = styled(Link)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: "#10B981",
  textDecoration: "none",
  padding: "8px",
  borderRadius: "8px",
  border: "1px solid #10B981",
  marginRight: "16px",
  fontSize: "14px",
  "&:hover": {
    backgroundColor: "rgba(16, 185, 129, 0.04)",
  },
  "& .MuiSvgIcon-root": {
    fontSize: "20px",
    marginRight: "4px",
  },
}));

const BreadcrumbLink = styled(Link)(({ theme }) => ({
  color: "#6D6D6D",
  textDecoration: "none",
  fontSize: "14px",
  fontWeight: 400,
  "&:hover": {
    color: "#374151",
    textDecoration: "none",
  },
}));

const ActiveText = styled(Typography)(({ theme }) => ({
  color: "#10B981",
  fontSize: "14px",
  fontWeight: 500,
}));

// Custom separator component for more precise styling
const CustomSeparator = () => (
  <EastOutlined
    sx={{
      fontSize: "18px",
      color: "#6D6D6D",
      marginTop: "2px", // Fine-tune vertical alignment
    }}
  />
);

const CustomBreadcrumb = ({ items, onBackClick }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", padding: "8px 0" }}>
      <BackButton href="#" onClick={onBackClick}>
        <ArrowBackIcon />
        Back
      </BackButton>

      <StyledBreadcrumbs separator={<CustomSeparator />}>
        {items.map((item, index) => {
          const isLast = index === items.length - 1;

          return isLast ? (
            <ActiveText key={item.text}>{item.text}</ActiveText>
          ) : (
            <BreadcrumbLink
              key={item.text}
              href={item.href}
              onClick={(e) => {
                e.preventDefault();
                item.onClick && item.onClick();
              }}
            >
              {item.text}
            </BreadcrumbLink>
          );
        })}
      </StyledBreadcrumbs>
    </div>
  );
};

export default CustomBreadcrumb;
