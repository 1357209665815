import { Box, Typography } from "@mui/material";
import CommonHeader from "../CommonComponent/CommonHeader";
import { SupportImg } from "../assets/svg";

const HelpSupport = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <CommonHeader
        title="Help & Support"
        subTitle="Please send your questions or issues on this email address."
      />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          mt: "10%",
        }}
      >
        <SupportImg sx={{ width: "auto", height: "150px" }} />{" "}
        <Typography variant="body2" align="center" mt={2} color="#989898">
          If you are facing any issue or if you have any questions, kindly send
          an email to{" "}
        </Typography>
        <Typography
          variant="body2"
          align="center"
          fontWeight="bold"
          color="#00B894"
          component="a" 
          href="mailto:support@datavizz.in" 
          sx={{ textDecoration: "none" }} 
        >
          support@thedataprivacy.cloud
        </Typography>
      </Box>
    </Box>
  );
};

export default HelpSupport;
